import auth from '../../../helpers/auth'
import REACT_APP_API from "../../../config/environment";
import headerType from '../../../helpers/headerType';
import { checkSubscription } from '../../home/modules/HomeModule';
export const SET_UNBLOCK_SPINNER_STATUS = "SET_UNBLOCK_SPINNER_STATUS";
export const GET_USER_CERTIFICATE_SPINNER_STATUS = "GET_USER_CERTIFICATE_SPINNER_STATUS";
export const GET_USER_PRIVACY_SPINNER_STATUS = "GET_USER_PRIVACY_SPINNER_STATUS";
export const GET_CHANGE_PASSWORD_SPINNER_STATUS = "GET_CHANGE_PASSWORD_SPINNER_STATUS";
export const UPDATE_USER_CERTIFICATE_SPINNER_STATUS = "UPDATE_USER_CERTIFICATE_SPINNER_STATUS";
export const UPDATE_USER_PRIVACY_SPINNER_STATUS = "UPDATE_USER_PRIVACY_SPINNER_STATUS";
export const GET_USER_SETTING_DATA = "GET_USER_SETTING_DATA";
export const GET_USER_PRIVACY_DATA = "GET_USER_PRIVACY_DATA";
export const GET_BLOCK_USER_DATA = "GET_BLOCK_USER_DATA";
export const GET__USER_FAQ = "GET__USER_FAQ";
export const GET_USER_SUBSCRIPTION="GET_USER_SUBSCRIPTION"
export const GET_USER_SUBSCRIPTION_PAYMENT_DATA="GET_USER_SUBSCRIPTION_PAYMENT_DATA"



export function getUserSettingSpinner(flag) {
    return {
        type: GET_USER_CERTIFICATE_SPINNER_STATUS,
        payload: flag,
    };
}

export function getUserPrivacySpinner(flag) {
    return {
        type: GET_USER_PRIVACY_SPINNER_STATUS,
        payload: flag,
    };
}

export function changePwdUserSpinner(flag) {
    return {
        type: GET_CHANGE_PASSWORD_SPINNER_STATUS,
        payload: flag,
    };
}

export function updateUserSettingSpinner(flag) {
    return {
        type: UPDATE_USER_CERTIFICATE_SPINNER_STATUS,
        payload: flag,
    };
}

export function updateUserPrivacySpinner(flag) {
    return {
        type: UPDATE_USER_PRIVACY_SPINNER_STATUS,
        payload: flag,
    };
}

export function unblockUserSpinner(flag) {
    return {
        type: SET_UNBLOCK_SPINNER_STATUS,
        payload: flag,
    };
}

export function getUserSettingData(data) {
    return {
        type: GET_USER_SETTING_DATA,
        payload: data,
    };
}

export function getSubscriptionData(data) {
    return {
        type: GET_USER_SUBSCRIPTION,
        payload: data,
    };
}

export function subscriptionPaymentData(data) {
 
    return {
        type: GET_USER_SUBSCRIPTION_PAYMENT_DATA,
        payload: data,
    };
}

export function getUserPrivacyData(data) {
    return {
        type: GET_USER_PRIVACY_DATA,
        payload: data,
    };
}

export function getBlockUserData(data) {
    return {
        type: GET_BLOCK_USER_DATA,
        payload: data,
    };
}
export function getFAQData(data) {
    return {
        type: GET__USER_FAQ,
        payload: data,
    };
}



export const unblockUserRequest = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        const token = await auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/unblock/user`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(fetchBlockUser())
                    return response
                }
            } catch (e) {
                console.log(e, 'error.');
            }
        } else {
            console.log('You need to login first');
        }
    };
};

export const fetchFAQ = (data) => {
    return async (dispatch, getState) => {
        await dispatch(unblockUserSpinner(true));
        const token = await auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/faq?role_id=${data.userRoleId}&page=${data.pageNo}`, {
                    method: "GET",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(unblockUserSpinner(false));
                    await dispatch(getFAQData(response));
                    return response
                } else {
                    await dispatch(unblockUserSpinner(false));
                    await dispatch(getFAQData(response));
                }
            } catch (e) {
                await dispatch(unblockUserSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(unblockUserSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const fetchBlockUser = (page) => {
    return async (dispatch, getState) => {
        await dispatch(unblockUserSpinner(true));
        const token = await auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/block/user/list?page=${page}`, {
                    method: "GET",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(unblockUserSpinner(false));
                    await dispatch(getBlockUserData(response));
                    return response
                } else {
                    await dispatch(unblockUserSpinner(false));
                    await dispatch(getBlockUserData(response))
                }
            } catch (e) {
                await dispatch(unblockUserSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(unblockUserSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const setChangePassword = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        await dispatch(changePwdUserSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/change/password`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(changePwdUserSpinner(false));
                    alert(response.message)
                    return response
                } else {
                    await dispatch(changePwdUserSpinner(false));
                    alert(response.errors)
                    return response
                }

            } catch (e) {
                await dispatch(changePwdUserSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(changePwdUserSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const updateUserSetting = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        await dispatch(updateUserSettingSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/user/settings`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(updateUserSettingSpinner(false));
                    await dispatch(getUserSetting());
                    return response
                } else return response;
            } catch (e) {
                await dispatch(updateUserSettingSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(updateUserSettingSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const getUserSetting = () => {
    return async (dispatch, getState) => {
       
        await dispatch(getUserSettingSpinner(true));
        const token = await auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/user/settings`, {
                    method: "GET",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
                if (response.success) {
                    await dispatch(getUserSettingSpinner(false));
                    await dispatch(getUserSettingData(response.data));
                    // return response
                }
            } catch (e) {
                await dispatch(getUserSettingSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(getUserSettingSpinner(false));
            console.log('You need to login first');
        }
    };
};



export const updateUserPrivacy = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        await dispatch(updateUserPrivacySpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/save/privacy`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(updateUserPrivacySpinner(false));
                    await dispatch(getUserPrivacy());
                    return response
                }else{
                    await dispatch(updateUserPrivacySpinner(false));  
                }
            } catch (e) {
                await dispatch(updateUserPrivacySpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(updateUserPrivacySpinner(false));
            console.log('You need to login first');
        }
    };
};

export const updateEmailPreferences = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        // await dispatch(updateUserSettingSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/save/email/preference`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    // await dispatch(updateUserSettingSpinner(false));
                    await dispatch(getUserSetting());
                    return response
                }
            } catch (e) {
                // await dispatch(updateUserSettingSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            // await dispatch(updateUserSettingSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const getUserPrivacy = () => {
    return async (dispatch, getState) => {
        await dispatch(getUserPrivacySpinner(true));
        const token = await auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/user/privacy`, {
                    method: "GET",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
                if (response.success) {
                    await dispatch(getUserPrivacySpinner(false));
                    await dispatch(getUserPrivacyData(response));
                    return response
                }
            } catch (e) {
                await dispatch(getUserPrivacySpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(getUserPrivacySpinner(false));
            console.log('You need to login first');
        }
    };
};

export const getSubscription= () => {
    
    return async (dispatch, getState) => {
        await dispatch(getUserSettingSpinner(true));
        const token = await auth.getAccessToken()
       
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/membership/subscription/plans`, {
                    method: "GET",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
               
                if (response.success) {
                    // console.log(response,)
                    // await dispatch(getUserSettingSpinner(false));
                    await dispatch(getSubscriptionData(response));
                    // return response
                }
            } catch (e) {
                await dispatch(getUserSettingSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(getUserSettingSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const subscriptionStatically=(formdata)=>{
   
    return async (dispatch, getState) => {
        await dispatch(getUserSettingSpinner(true));
        const token = await auth.getAccessToken()
       
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/membership/purchase/subscription/statically`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: formdata
                });
                let response = await result.json();
               
                if (response.success) {
                    // dispatch(checkSubscription())
                    await dispatch(getUserSettingSpinner(false));
                    // await dispatch(getSubscriptionData(response));
                    return response
                }
            } catch (e) {
                await dispatch(getUserSettingSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(getUserSettingSpinner(false));
            console.log('You need to login first');
        }
    };
    
}

export const getFreeSubscription= () => {
    
    return async (dispatch, getState) => {
        await dispatch(getUserSettingSpinner(true));
        const token = await auth.getAccessToken()
       
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/membership/upgrade/free/subscription`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
               
                if (response.success) {
                    dispatch(checkSubscription())
                    await dispatch(getUserSettingSpinner(false));
                    // await dispatch(getSubscriptionData(response));
                    // return response
                }
            } catch (e) {
                await dispatch(getUserSettingSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(getUserSettingSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const CancelSubscription= () => {
    
    return async (dispatch, getState) => {
        await dispatch(getUserSettingSpinner(true));
        const token = await auth.getAccessToken()
       
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/membership/cancel/subscription`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
               
                if (response.success) {
                    // dispatch(checkSubscription())
                    await dispatch(getUserSettingSpinner(false));
                    // await dispatch(getSubscriptionData(response));
                    // return response
                }
            } catch (e) {
                await dispatch(getUserSettingSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(getUserSettingSpinner(false));
            console.log('You need to login first');
        }
    };
};



export const subscriptionHandler = (session_id) => {
    var form_data = new FormData();
    
        form_data.append("session_id", session_id);
   
    // const requestBody = JSON.stringify({ session_id });
    
    return async (dispatch, getState) => {
        // await dispatch(updateUserSettingSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/membership/payment/status`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();

               
                if (response.success === true) {
           
                 
                    await dispatch(subscriptionPaymentData(response));
                    // return response
                }
            } catch (e) {
                // await dispatch(updateUserSettingSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            // await dispatch(updateUserSettingSpinner(false));
            console.log('You need to login first');
        }
    };
};

// initialState
export const initialState = {
    changePwdSpinner: false,
    blockUserSpinner: false,
    userPrivacySpinner: false,
    userSettingSpinner: false,
    updateUserSettingSpinner: false,
    updateUserPrivacySpinner: false,

    blockUserData: [],
    userSettngData: [],
    userPrivacyData: [],
    userFAQData: [],
    subscriptionData:[],
    subscriptionPaymentStatus:[]
};

const ACTION_HANDLERS = {

    [GET_USER_CERTIFICATE_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            userSettingSpinner: action.payload,
        };
    },

    [GET_USER_PRIVACY_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            userPrivacySpinner: action.payload,
        };
    },

    [GET_CHANGE_PASSWORD_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            changePwdSpinner: action.payload,
        };
    },

    [UPDATE_USER_CERTIFICATE_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            updateUserSettingSpinner: action.payload,
        };
    },

    [UPDATE_USER_PRIVACY_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            updateUserPrivacySpinner: action.payload,
        };
    },

    [SET_UNBLOCK_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            blockUserSpinner: action.payload,
        };
    },

    [GET_USER_SETTING_DATA]: (state, action) => {
        return {
            ...state,
            userSettngData: action.payload,
        };
    },

    [GET_USER_SUBSCRIPTION]: (state, action) => {
        return {
            // ...state,
            subscriptionData: action.payload,
        };
    },

    [GET_USER_SUBSCRIPTION_PAYMENT_DATA]: (state, action) => {
 
        return {
            ...state,
            subscriptionPaymentStatus: action.payload,
        };
    },

    [GET_USER_PRIVACY_DATA]: (state, action) => {
        return {
            ...state,
            userPrivacyData: action.payload,
        };
    },

    [GET_BLOCK_USER_DATA]: (state, action) => {
        return {
            ...state,
            blockUserData: action.payload,
        };
    },
    [GET__USER_FAQ]: (state, action) => {
        return {
            ...state,
            userFAQData: action.payload,
        };
    },
    
};

export default function userSettingReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
