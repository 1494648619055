import React from 'react'
import Banner from './component/Banner'
import Brands from '../LandingPage/component/Brands'
import OurServices from './component/OurServices'
import { serviceContent } from './utils'  
import ContentImg from './component/ContentImg'
import Testimonial from '../LandingPage/component/Testimonial'
import NewsLetter from './component/NewsLetter'
import Video from './component/Video'
import { useParams } from 'react-router-dom';
import Header from "../LandingPage/component/Header";
import Footer from "../LandingPage/component/Footer";
const Services = () => {
  const { slug } = useParams();
  const service = serviceContent.find(s => s.slug === slug);

  if (!service) {
    return <p>No service found for this category!</p>; 
  }
  return (
   
     
        <>
        <Header/>
          <Banner title={service.title} description={service.description} />
      <Brands />
      
      <OurServices title={service.ourServices.title} desc={service.ourServices.desc} cardContent={service.ourServices.cardContent} />
      
      {service.contentImg.map((content, idx) => (
        <ContentImg
          key={idx}
          heading={content.heading}
          subheading={content.subheading}
          desc={content.desc}
          badgeInfo={content.badgeInfo}
          img={content.img}
          direction={content.direction}
        />
      ))}
      
      <Testimonial />
     
      {service.videoUrl && <Video videoUrl={service.videoUrl} thumbnail={service.thumbnail} videoTitle={service.videoTitle} videoDesc={service.videoDesc } videoSub={ service.videoSub}/>}
      <NewsLetter joinLink={service.joinLink}/>
      <Footer />
        </>
    
   
  )
}

export default Services
