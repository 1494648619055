
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, } from 'react-router';
import '../../../assets/marketplacecss/sendinquiry-style.css'
import language from '../../../helpers/language';
import { getMarketplaceProductDetails, saveProductInquiry } from '../modules/marketplace';
import { Link } from "react-router-dom";
import constant from '../../../helpers/constant';
import CustomUseEffect from '../../../components/constants';


export default function SendInquiryForm() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [inQuiryMessage, sendInQuiryMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [lang] = useState(language.getLang());
  CustomUseEffect(async () => {
    await dispatch(getMarketplaceProductDetails(id));
    window.scrollTo(0, 0);
  }, [id]);

  const {
    marketplaceProduct,
    marketPlaceSpinner
  } = useSelector((state) => state.marketPlaceReducerData);

  const { profileData: { data } } = useSelector((state) => state.memberProfileData);

  const submitInquiryMessage = async () => {
    window.scrollTo(0, 0);
    let user = data && data.user_data && data.user_data;
    const user_name = data && data.user_data.company_name || data && user.restaurant_name;

    const inQuiryData = {
      product_id: id,
      name: user_name,
      email: data && data.contact_tab.email,
      phone: data && data.contact_tab.phone,
      message: inQuiryMessage,
    }
    if (inQuiryMessage === '') {
      setMessageError(lang?.enterMessageField)
    } else {
      const response = await dispatch(saveProductInquiry(inQuiryData));
      if (response.success === 200) {
        setSuccessMessage(response.message);
        history.goBack()
      } else {
        setSuccessMessage(response.errors.exception);
        setIsSuccess(true);
      }
    }
    setTimeout(() => {
      setMessageError('');
      setSuccessMessage('');
      setIsSuccess(false);
    }, 3000);
  }
  return (
    <React.Fragment>

      <div className='discover_page mb-4'>
        <div className='page_top_form'>
          <div className="step">
            <div className="cancel">
              <Link to="/networking" onClick={() => history.goBack()}>
                <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;
                {lang?.Go_back}
              </Link>
            </div>
            <div className="right__align_btn btn-white">
            </div>
          </div>
        </div>
      </div>
      <div className="marketplace add-product sendEnqury bg-gray">
        {/* <div className="flex justify-between items-center">
        <a href="javascript:void(0)" className="back__btn" onClick={() => history.goBack()}>
          <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;
          {lang?.Go_back}
        </a>
      </div> */}
        {/* Banner Section */}
        <div className="main__content">
          <div className="section__wrapper">
            <div className="enquiry-popup-bg">
              <div className="popup-header flex justify-between items-center">
                <h4 className="product-title black mb--0 mt--0">{lang?.Send_Inquiry}</h4>
                {/* <a href="javascript:void(0)"><i className="fa fa-times" aria-hidden="true" /></a> */}
              </div>
              <div className="reset text-center ">
                <h5 className="heading--w text-Red" style={{ color: isSuccess ? 'red' : 'green' }}>{successMessage}</h5>
              </div>
              <div className="flex justify-between items-center">
                <p className="font-20 mt--2 mb--2">{marketplaceProduct.title}</p>
                {(data?.user_data?.role_id === constant.rolesId.importer || data?.user_data?.role_id === constant.rolesId.distributer || data?.user_data?.role_id === constant.rolesId['importer&distributer']) &&
                  marketplaceProduct?.product_price > 0 && <h4 className="product-title black mt--2 mb--2">${marketplaceProduct.product_price}</h4> }
                
              </div>
              <hr className="mt-0 mb--2" />
              <p className="mt-5 mb-5">
                {lang?.Fast_response}
              </p>
              <div className="mrkt-place form">
                <div className="card-form">
                  <form className="configure-store-form">
                    <div className="flex flex-wrap main-row">
                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                        <div className="w-100">
                          <label>{lang?.Full_name} *</label>
                          <div className="form__item">
                            <input type="text" placeholder="Importer Name" id="store-name" defaultValue={data && data.user_data && data.user_data.company_name || data && data.user_data && data.user_data.restaurant_name} readOnly />
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                        <div className="w-100">
                          <label>{lang?.Email} *</label>
                          <div className="form__item">
                            <input type="text" placeholder="Importer Name" id="store-name" defaultValue={data && data.contact_tab && data.contact_tab.email} readOnly />
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                        <div className="w-100">
                          <label>{lang?.Phone_number} *</label>
                          <div className="form__item">
                            <input type="text" placeholder={`${lang?.Phone_number}`} id="store-name" defaultValue={data && data.contact_tab && `${data.contact_tab.country_code} ${data.contact_tab.phone}`} readOnly />
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding" />
                      <div className="w-full col-padding">
                        <div className="reset text-center ">
                          <h5 className="heading--w text-Red" style={{ color: 'red' }}>{messageError}</h5>
                        </div>

                        <div className="w-100">
                          <label>{lang?.Message} *</label>
                          <div className="form__item">
                            <textarea type="text" placeholder="Hi is this product available?" defaultValue={""} onChange={(event) => sendInQuiryMessage(event.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="w-full col-padding">
                        <div className="w-100">
                          <div className="inner-btn-box right-btn mb-5">
                            <button type="button" className="btn btn-blue" onClick={() => submitInquiryMessage()}>{lang?.Send_Inquiry}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
