import thumbnail1 from '../assets/landing-page/video-thumbnail/image00001.jpeg';
import thumbnail6 from '../assets/landing-page/video-thumbnail/image00006.jpeg';
import thumbnail18 from '../assets/landing-page/video-thumbnail/image00018.jpeg';
import thumbnail17 from '../assets/landing-page/video-thumbnail/image00017.jpeg';
import thumbnail11 from '../assets/landing-page/video-thumbnail/image00011.jpeg';

export default {
  
  getLang() {
    let lang = localStorage.getItem("lang");

    const EN = {
      lan: "en",
      sendQuiry_success:"Thank you for your inquiry.Your message has been successfully sent to the ​Producer, who will get in touch with you shortly. In the meantime, feel free to continue browsing and shopping​ without selecting shipping at this time.",
      Name: "Please enter first name.",
      subj:'Subj.',
      shipping_name:'Shipping',
      Other_name:'Other',
      Quantity_Range:'Quantity Range',
      Destination_name:'Destination',
      LastName: "Please enter last name.",
      MobNo: "Please enter mobile number.",
      Createdat:'Created at',
      NoResult404:'No result found',
      orderPending:"The order has not been yet accepted by the producer",
      orderProcessing:'The order has been accepted by the producer',
      orderHold:'The order has been put on hold by the producer',
      orderCancel:"The producer cancelled the order",
      orderTransit:"The order is on the way to the final destination",
      orderCompleted:"The order has been received by the importer",
      BuyerName:'Buyer Name',
      Timeleads:'Time',
      LastActivity:'Last Activity',
      allStatus:'All Status',
      sPending:'Pending',
      sResolved:'Resolved',
      orderPurchaseDate:'Order Purchase Date',
      EditAction:'EDIT',
      DeleteAction:"DELETE",
      ActiveAction:'Active',
      InactiveAction:'Inactive',
      new_Leads:'New Leads',
      Disputes:'Disputes',
      RequestPlaceholder:'Request ID',
      DisputesManagement:'Disputes Management',
    
      noInqueries404:'No Inqueries Found',
      connectWithStripe:'Connect with stripe',
      disconnectWithStripe:' Disconnect your Stripe account',
      whatsStripeConnect:' What is Stripe Connect?',
      stripeMsg:"To start accepting client payments through Stripe, click the button below to securely connect your account.",
      selected_date_to:'Select date to',
      selected_date_from:'Select date from',
      Please_Enter_Email: "Please enter email address. ",
      ValidEmail: "Please enter valid email address.",
      Password: "Please enter password.",
      ValidPass: "Please enter confirm password. ",
      NewPass: "Please enter new password.",
      Address: "Please enter address.",
      Please_Select_Country: "Please select country first",
      State: "Please select state first.",
      RecoveryEmail:
        "A recovery email has been sent to your registered email address",
      NewAndConfirmPassNotMatch:
        "New password and confirm password doesn't match.",
      ValidMobNo: "Please enter valid number.",
      Otp: "Please enter OTP.",
      LocationEnable: "Location enabled.",
      LocationNotEnable: "Location not enabled.",
      SomeThingsWrong: "Something went wrong. Please try again.",
      NetworkError: "Unable to connect to the Internet. Please try again. ",
      SessionExpire: "Your session has expired. Please login again.",
      LoctionNotEnable: "Location not enabled. Please turn on the location.",
      FillRequireInfo: "Please fill all the required Information. ",
      TermAndCondition: "Please agree to Terms&Conditions. ",
      NumberAddedSuccess: "Number Added successfully.",
      EmailAddedSuccess: "Email added successfully. ",
      OtpSendSuccessfull: "OTP sent successfully.",
      OptSendOnEmail: "OTP sent on your mail.",
      PasswordChangeSuccessfull: "Password changed successfully.",
      Available_For_Sample: "Please select Required Feild",
      SourceType: "Please choose a source type",
      TakePhoto: "Take Photo",
      FromLibrary: "Choose From Library",
      DeletePhoto: "Delete photo",
      RemovePhoto: "Remove photo",
      SixDigitOtp: "Enter 6-digit OTP.",
      FeatureNotAvailable: "This feature is not available.",
      ProfileUpdateSuccess: "Profile updated successfully.",
      UpLoadImage: "Please upload image.",
      ChooseRoleId: "Please choose the role.",
      ReasonToDecline: "Please enter reason to decline ",
      SelectCookingSkill: "Please Select Cooking Skill.",
      SelectCuisine: "Please select Cuisine. ",
      SelectMeal: "Please select Meal.",
      SelectCourse: "Please select Course.",
      SelectDiet: "Please select Diet.",
      SelectPrepationTime: "Please select Preparation Time.",
      CookManyPeople: "Please select for how much people you are cooking.",
      SelectFood: "Please select Food Intolerance.",
      SelectRegion: "Please select Region.",
      EnterIngredientName: "Please enter Ingredient Name.",
      SelectCategory: "Please select a Category.",
      Edit_desc: "Please enter Description.",
      Title: "Please enter Title.",
      AddIngrdient: "Please add Ingredient.",
      AddTool: "Please add at least one Utensils/Tools.",
      SelectUnit: "Please select Unit",
      EnterQuantity: "Please enter Quantity",
      CharacterNumber: "Please enter more than 3 character.",
      DescriptionOrUrl: "Please enter Description or Url. ",
      LocationServiceFeature:
        "We don't have access to location services on your device. Please go to settings and enable location services to use this feature.",
      PassWordValidation:
        "Password must be at least 8 characters and contain at least one numeric digit and a special character.",
      MarketPlacePowerfullEng:
        "The most powerful marketplace engine for the Made in Italy.",
      FdaIconText:
        "The owner, operator, or agent in charge of either a domestic or foreign facility that is engaged in manufacturing/processing, packing, or holding of food for human or animal consumption in the United States, must register with FDA",
      AlertMsg: "Alert Message.",
      WantToLogout: "Are you sure you want to logout?",
      Speciality: "Please select at least one speciality",
      EnterCity: "Please enter your city",
      SelectCity: "Please select the City",
      Zip_Postal: "Please enter the ZIP/POSTAL CODE",
      InvailidZip_Postal: "Invalid Zip/Postal code",
      Expertise: "Please select at least one expertise",
      SelectTitle: "Please select at least one title",
      DeliveryOption: "Please select pick and delivery option.",
      PickUpDiscount: "Please select pick up discount for alysei users.",
      DeliveryDiscount: "Please select delivery discount for alysei users",
      RestaurantType: "Please select Restaurant Type.",
      Horeca: "Please select the Horeca",
      PrivateLebel: "Please select the Private Label.",
      AlyseiBrandLebel: "Please select the Alysei Brand Label.",
      Term_Condition: "Please accept the terms and conditions.",
      // marketPlace hint -
      ImporterInquiry: "Inquiries",
      Alysei_Marketplace: "Alysei Marketplace",
      Recently_Added_Product: "Recently Added Product",
      Newly_Added_Stores: "Newly Added Stores",
      Quick_search_by_Italian_Regions: "Quick search by Italian Regions",
      Top_Rated_Products: "Top Rated Products",
      Top_Favourite_Products: "Top Favourite Products",
      Product_Title: "Title*",
      Product_Type: "Product Type",
      ProductType: "Product Type",
      Conservation_Method: "Conservation Method",
      Product_Properties: "Product Properties",
      Italian_Region: "Italian Region",
      Reset_Filter: "Clear Filters",
      FDA_Certification: "FDA Certification",
      Update_Review: "Update Review",
      Edit_review: "Edit review",
      Edit_your_review_and_ratings: "Edit your review and ratings",
      Related_Products: "Related Products",
      Sample_Available: "Sample Available",
      Dispatch_Instructions: "Dispatch Instructions",
      Handling_Instructions: "Handling Instructions",
      Minimum_Order_Quantity: "Minimum Order Quantity",
      Brand_Label: "Brand Label",
      Quantity_Available: "Quantity Available",
      About_Product: "About Product",
      Imagetype: "The Image type must be JPEG, JPG, PNG",
      ImageSize: "Image size should not be more than 2 MB",
      Go_back: "Back to Marketplace",
      Go_back_Dashboard: "Back to dashboard",
      ImageMaxLen:
        "Choose your stores main photo first. Add more photos of your store max 8 allowed*",
      ImageMaxLen5:
        "Choose your stores main photo first. Add more photos of your store max 5 allowed",
      Min_Order_Quantity: "Minimum Order Quantity",
      Dispatch_Instruction: "Dispatch Instruction",
      Available_For_Sample: "Available For Sample",
      Product_Price: "Product Price",
      Min_Char: "should be minimum char",
      is_required: "is required",
      LessThan: "should be less than",
      MinValue: "should be minimum value",
      LessThanOrEq: "should be less than or equal to",
      LogoImage: "Please select logo image",
      GalleryImage: "Please select gallery images",
      Crop_image: "Crop Image",
      Config_store: "Configure Your Store",
      NotAllowed: "You are not allowed to access this page",
      GoHome: "GO TO HOME PAGE",
      MyDashboard: "My Dashboard",
      AddReviewAndRatings: "Add reviews and ratings",
      EditReviewAndRatings: "Edit your review and ratings",
      Select_package: "Alysei Marketplace MemberShip",
      Alysei_B2B_Marketplace: "Alysei B2B Marketplace",
      Select_best_package: "Choose a plan that is right for you",
      Thank_you: "Thank you for submitting your information for admin review",
      will_resp: "We will respond you at earliest",
      View_profile: "View Profile",
      Our_gallery: "Our gallery",
      About_store: "About Store",
      Store_products: "Store Products",
      // Select_fda_Certificate: 'Select FDA Certificate',
      Select_fda_Certificate: "Select",
      Ratings_and_reviews: "Ratings & Reviews",
      Add_review: "Add Rating",
      Products: "Product Management",
      Producer_Store: "Producer Store",
      View_all: "View all",
      Search: "Search",
      Select: "Select",
      Categories: "Categories",
      Location: "Location",
      call: "call",
      Website: "Website",
      Direction: "Direction",
      Favourite: "Favourite",
      Send_Inquiry: "Send Inquiry",
      See_All: "See All",
      Accept: "Accept",
      Decline: "Decline",
      Decline_Request: "Decline Request",
      Reason_to_Decline: "Reason to Decline",
      Confirm: "Confirm",
      Store_Name: "Store Name",
      Phone_number: "Phone Number",
      PhoneNumber: "Phone Number",
      Email: "Email",
      Store_region: "Store Region",
      Photos: "Photos",
      Dashboard: "Dashboard",
      Inquiries: "Inquiries",
      Total_products: "Total Products",
      Total_category: "Total Categories",
      Total_reviews: "Total Ratings",
      Analytics: "Analytics",
      Yearly: "Yearly",
      Monthly: "Monthly",
      Weekly: "Weekly",
      Yesterday: "Yesterday",
      Today: "Today",
      Add_new_product: "Add New Product",
      No_product_found: "No Product found",
      Update: "Update",
      Add_gallery: "Add Gallery",
      Total_inquiries: "Total Inquiries",
      Add_photos: "Add Photos",
      only_title: "Title",
      Keywords: "Keywords",
      Product_category: "Product Category",
      Select_product_category: "Select Product Category",
      yes: "Yes",
      no: "No",
      Description: "Description",
      Edit_prod_desc: "Product Description",
      Select_brandLebel: "Select Brand Labels",
      New: "New",
      Open: "Open",
      opened: "Opened",
      Closed: "Closed",
      close: "Close",
      No_new_inquery: "No New Inquery",
      No_closed_inquery: "No Closed Inquery",
      only_product: "Product",
      Product_listing: "Product Listing",
      Sort_by: "Sort By",
      AtoZ: "A to Z",
      ZtoA: "Z to A",
      Most_Rated_Stores: "Most Rated Stores",
      Star_Stores: "Star Stores",
      Most_Searched: "Most Searched",
      No_review_found: "No reviews till now",
      No_data_found: "No Data found",
      Fast_response:
        " Fill the form just once to get faster responses. Store will contact you shortly",
      Full_name: "Full Name",
      Message: "Message",
      GO_TO_MARKETPLACE: "GO TO MARKETPLACE",
      Showing: "Showing",
      results: "results",
      Next: "Next",
      View: "View",
      Connect: "Connect",
      Connected: "Connected",
      Cancel: "Cancel",
      Requested: "Requested",
      Italian_F_and_B_producers: "Italian F&B Producer",
      Importer_And_Distributor: "Importer & Distributor",
      Importer_And_Distributor_Singular: "Importer & Distributor",
      Voice_Of_Experts: "Voice Of Expert",
      Voice_Of_Expert: "Voice Of Expert",
      B2b_Voice_Of_Experts: "Voice Of Experts",
      Travel_Agencies: "Travel Agency",
      Italian_Restaurants_in_US: "Italian Restaurant in US",
      Italian_F_and_B_producersB2B: "Italian F&B Producers",
      Importer_And_DistributorB2B: "Importers & Distributors",
      Travel_AgenciesB2B: "Travel Agencies",
      Italian_Restaurants_in_USB2B: "Italian Restaurants in US",
      Voyagers: "Voyagers",
      Hubs: "Hub",
      Private_Label: "Private Label",
      Label: "Label",
      Hotel_Restaurant_Café: "Hotel/Restaurant/Café",
      HORECA: "HORECA",
      Products_sold_under_another_companys_brand:
        "Products sold under another company's brand",
      Alysei_Brand_Label: "Alysei Brand Label",
      Provide_pick_up_and_or_Delivery: "Provide pick up and/or Delivery?",
      Pick_up: "Pick up",
      Delivery: "Delivery",
      United_State: "United States",
      Italy: "Italy",
      Country: "Country",
      Unsubscribe: "Unsubscribe",
      Subscribe: "Subscribe",
      Edit: "Edit",
      HouseAndFlatNo: "Enter your Address",
      Enter_email: "Enter a email",
      NoPlace_holder: "999-999-9999",
      Award: "Award",
      Save: "Save",
      Add: "Add",
      Image: "Image",
      Name_of_Competition_Event: "Name of Competition / Event",
      Name_of_winning_Product: "Prize",
      Name_of_Modal_place_recived: "Medal / place received",
      Select_Medal_Type: "Select-Medal-Type",
      Select_Medal: "Select Medal",
      Bronze: "Bronze",
      Silver: "Silver",
      Gold: "Gold",
      Please_select_BlogTitle: "Please Enter Blog Title",
      Please_block_Description: "Please Enter Blog Description.",
      Please_select_blog_status: "Please Select Blog Status.",
      Please_select_BlogImage: "Please Select Blog ImageId.",
      Blog: "Blog",
      Create: "Create",
      Status: "Status",
      Event: "Event",
      Event_View: "Event View",
      name: "Name",
      Date: "Date",
      Host: "Host",
      Time: "Time",
      Type: "Type",
      Free: "Free",
      Paid: "Paid",
      buy_invitation: "By Invitation",
      price: "Price",
      Event_Description: "Event Description",
      PriceType: "select-price-type",
      currencyType: "Select Currency",
      Duration: "Duration",
      intensity: "Intensity",
      only_country: "country",
      Registration: "Registration",
      Select_Event: "Select Event Type",
      Currency_type: "Currency type",
      Adventure_Type: "Adventure Type",
      Adventure: "Adventure",
      Select_reg_type: "Select Registration Type",
      Event_Registration_Type: "Event Registration Type",
      Public: "Public",
      public: "Public",
      Private: "Private",
      Trip_View: "Trip View",
      Trip: "Trip",
      days: "days",
      day: "day",
      Euro: "Euro",
      USD: "USD",
      USA: "USA",
      Filter: "Filter",
      Restaurant: "Restaurant",
      Restaurants: "Restaurants",
      Booking_Url: "Booking Url",
      Host_Name: "Host Name",
      Please_Enter_Event: "Please Enter Event",
      PleaseSelectEvent: "Please Select Event",
      PleaseEnterHostName: "Please Enter Host Name",
      Please_Enter_BookingUrl: "Please Enter booking Url",
      Please_Enter: "Please Enter",
      Please_Select: "Please Select",
      Trip_Name_Package_Name: "Trip Name / Package Name",
      Select_city_where_You_from_exported:
        "Selected City name where you from exported.",
      Below_Selected_state_where_You_from_exported:
        "Below are the selected state where you from exported City.",
      Selected_Hubs: "Select the HUB in",
      Below_are_the_selected_hubs: "Below are the selected hubs.",
      Travel_Agency: "Travel Agency",
      Select_Country: "Select Country",
      Discovery: "Discovery",
      Alysei: "Alysei",
      Specialization: "Specialization",
      Region: "Region",
      RegionFrom: "Region From",
      RegionTo: "Region ",
      Read_More: "Read More",
      Remove_all: "Remove all",
      Do_not_recognize_hub: "Don't recognize your Hub?",
      Click_Here: "Click Here",
      Where_you_want_to_export:
        "Select the HUB in USA where you plan to develop your business",
      Choose_Your_Country:
        "The Hub identifies a geographical area developed around a metropolitan district, the nerve center of business, network and development activites",
      Login: "Login",
      Do_not_have_account: " Don't have an account with us?",
      Verify_Otp: "Verify Otp",
      only_password: "Password",
      ValidatePass:
        "Please enter at least one special symbol and number in your password. ",
      Facebook: "Facebook ",
      Alysei_Membership_Progress: "Alysei Membership Progress",
      Alysei_Membership_is_important_to_guarantee:
        "Alysei Membership is important to guarantee professionalism, quality and visibility",
      Your_membership_progress_is_pending_review:
        "Your membership progress is pending review. You will be notified once the review of your account has begun.",
      Alysei_Certified:
        "Welcome to Alysei! Your request has been approved. In order to become a Certified Alysei Member and access Alysei, please complete your profile",
      Profile_100_percent: "Congratulations! You are now an Alysei Certified ",
      Certified_Alysei: "",
      Latest_Notifications: "Latest Notifications",
      Your_Progress: "Your Progress",
      only_address: "Address",
      NO_message: "There is no members.",
      only_Remove: "Remove",
      No_new_chat: "There is no new chat.",
      Image_with_text: "Add Photo",
      upload: "upload",
      Enter_your_text: "Enter your text here ...",
      Connections: "Connections",
      Remove_Connection: "Remove",
      No_connection_found_right: "You have no connections right now!",
      No_Invitations_found: "You have no invitations right now!",
      No_pending_request: "You have no pending invites right now!",
      Following: "Following",
      Followers: "Followers",
      only_Importer: "Importer",
      only_Distributor: "Distributor",
      Unfollow: "Unfollow",
      Follow: "Follow",
      Invitations: "Invitations",
      View_Request: "View Request",
      Network: "Network",
      Pending: "Pending",
      Block: "Block",
      UnBlock: "UnBlock",
      pieces: "No. of pieces",
      bottles: "no. of bottles",
      liters: "liters",
      kilograms: "kilograms",
      grams: "grams",
      milligrams: "milligrams",
      add_product: "Add Product",
      add_package: "Add Package",
      Whats_new: "What's New",
      only_me: "Only Me",
      only_Post: "Post",
      only_delete: "Delete",
      only_share: "Share",
      Report: "Report",
      Reply: "Reply",
      when_you_post_see_here: "When you post something it will appear here.",
      my_connections: "My Connections",
      Connect: "Connect",
      sure_for_delete: "Are you sure you want to delete this post ?",
      discard_post:
        "If you discard now, you'll lose any changes that you've made to this post.",
      discard: "Discard",
      continue_editing: "Continue editing",
      ok: "Ok",
      Report_Post: "Report Post",
      Share_Post: "Share Post",
      Remove_comment: "Remove comment",
      Comment: "Comment",
      write_feedback: "Write your feedback...",
      write_reply: "Write a reply...",
      not_post_yet: "No Post yet!",
      Winning_Product: "Award",
      Awards: "Awards",
      Trips: "Trips",
      NoAward: "There are no awards to show at this moment.",
      are_you_sure: "Are you sure ?",
      Blogs: "Blogs",
      View_blog: "View Blog",
      There_is_no_blogs_at_this_moment: "There are no blogs at this moment.",
      There_is_no_events_at_this_moment: "There are events at this moment.",
      There_is_no_trips_at_this_moment: "There are no trips at this moment.",
      More_info: "More Info..",
      Events: "Events",
      welcome_to_Alysei_world_you_will_be_notified_for_membership_status_update:
        " Welcome to the Alysei World! You will be notified for any membership status update.",
      Change_Banner: "Change Banner",
      Change_Profile_Photo: "Change Profile Photo",
      Posts: "Posts",
      User_Name: "User Name",
      User_Type: "User Type",
      see_more: "...See More",
      see_less: "See Less",
      only_about: "About",
      About_us: "About Us",
      Contact: "Contact",
      COMPLETE_YOUR_PROFILE_IN_ORDER_TO_START_POSTING:
        "Complete your profile in order to navigate on Alysei",
      Go_to_profile: "Go to profile",
      there_is_no_photo_at_this_moment: "There are no photos at this moment.",
      Add_Award: "Add Award",
      Add_Blogs: "Create Blog",
      Settings: "Settings",
      Add_Feature_Product: "Add Featured Product",
      Add_Featured: "Add Featured ",
      Nothing_added_yet: "Nothing added yet.",
      Update_Profile: "Update Profile",
      Trip_Name: "Trip Name",
      Trip_Price: "Trip Price",
      Cancel_Request: "Cancel Request",
      Respond: "Respond",
      Accept_Request: "Accept Request",
      Delete_Request: "Delete Request",
      Delete: "Delete",
      Connection_Request: "Connection Request",
      sending_request_with: "Sending a request to connect with",
      Reason_to_connect: "Reason to connect",
      Send_Request: "Send Request",
      IMAGE: "IMAGE",
      TITLE: "TITLE",
      URL: "URL",
      Required_Information: "*Required Information",
      Sign_up: "Sign up",
      already_account_with_us: "Already have an account with us?",
      Join__Alysei_Today: "Join Alysei Today",
      Become_an_Alyesi_member_by_signing_up_for_the_Free_Trial_Beta_version:
        "Become an Alyesi member by signing up for the Free Trial Beta version. Your access request will be subject to approval.",
      Learn_More: "Learn More",
      Blocking: "Blocking",
      Click_to_Unblock: "Click to Unblock",
      Company: "Company",
      VAT_No: "VAT No.",
      FDA_No: "FDA No.",
      Photo_of_Label: "Photo of Label",
      Upload_an_image_of_your_product_label:
        "Upload an image of your product label.",
      Upload: "Upload",
      Uploaded: "Uploaded",
      Fce_Sid_Certification: "FCE-SID Certification",
      Upload_an_image_of_your_FCE_SID_Certification:
        "Upload an image of your FCE-SID Certification.",
      Phytosanitary_Certificate: "Phytosanitary Certificate",
      Upload_an_image_of_your_Phytosanitary_Certificate:
        "Upload an image of your Phytosanitary Certificate.",
      Packaging_For_Usa: "Packaging For USA",
      Upload_an_image_or_PDF_of_your_Packaging_For_Usa:
        "Upload an image or PDF of your Packaging For USA.",
      Animal_Helath_Asl_Certificate: "Animal Health or ASL Certificate",
      Food_Safety_Plan: "Food Safety Plan",
      Upload_image_or_PDF_of_your_Food_Safety_Plan:
        "Upload an image or PDF of your Food Safety Plan.",
      Upload_image_or_PDF_of_your_your_Animal_Helath_or_ASL_Certificate:
        "Upload an image PDF of your Animal Health or ASL Certificate",
      Please_enter_the_Company_Name: "Please enter the Company Name",
      Please_enter_the_Restaurant_Name: "Please enter the Restaurant Name",
      Please_enter_the_VAT_no: "Please enter the VAT no.",
      It_consists_of_11_numerical_digit: "It consists of 11 numerical digits.",
      Please_select_FDA_certified_field: "Please Select FDA CERTIFIED Field.",
      Please_select_product_type: "Please select the product type.",
      Please_select_at_least_one_conservation_method:
        "Please select at least one conservation method.",
      Please_select_at_least_one_Properties:
        "Please select at least one Properties.",
      Please_select_at_least_one_speciality:
        "Please select at least one speciality",
      OTP_has_been_send_successfully: "OTP has been send successfully.",
      Alysei: "Alysei",
      We_have_sent_you_a_6_digit_verification_code_OTP:
        "We have sent you a 6 digit verification code(OTP) to",
      Verify_OTP: "Verify OTP",
      Didnot_receive_the_OTP: "Didn't receive the OTP?",
      Resend_OTP: "Resend OTP",
      Privacy: "Privacy",
      Membership: "Membership",
      Subscription: "Subscription",
      Billing: "Billing",
      Your_Data: "Your Data",
      FAQ: "FAQ",
      Privacy_Policy: "Privacy Policy",
      Terms_and_Condition: "Terms and Conditions",
      Hub_select: "Hub select",
      Changing_your_password_will_log_you_off_any_other_devices:
        "Changing your password will log you off of any other devices.",
      Current_Password: "Current Password",
      New_Password: "New Password",
      validation_of_password:
        " Password must be at least 8 characters and contain at least one numeric digit and a special character.",
      Save_Changes: "Save",
      WHO_CAN_SEND_YOU_A_PRIVATE_MESSAGE: "WHO CAN SEND YOU A PRIVATE MESSAGE?",
      WHO_CAN_SEND_YOUR_PROFILE: "WHO CAN VIEW YOUR PROFILE?",
      WHO_CAN_CONNECT_WITH_YOU: "WHO CAN CONNECT WITH YOU?",
      Anyone: "Anyone",
      Just_me: "Just me",
      Email_Preferences: "Email Preferences",
      Private_messages: "Private messages",
      When_someone_request_to_follow: "When someone request to follow",
      Weekly_updates: "Weekly updates",
      General_Account_Settings: "General Account Settings",
      Resaurant: "Resaurant",
      only_Name: "Name",
      Username: "User Name",
      Language: "Language",
      English: "English (US)",
      Italian: "Italian (IT)",
      Select_option: "Select option",
      Billing_Detail_comming_soon: "Billing Detail coming soon",
      Billing_Details: "Billing Details",
      Billing: "Billing",
      Orders: "Orders",
      Orders_comming_soon: "Orders coming soon",
      Account_Data_Download: "Account Data Download",
      Nothing_requested_yet: "Nothing requested yet.",
      Request_Data: "Request Data",
      All: "All",
      People: "Users",
      Nothing_to_show: "There is nothing to show as of now",
      NoPeople: "No People found",
      Enter_your_reason: "Reason Field is require, Please enter your reason.",
      Connect_Request: "Connect Request",
      Sending_a_request_to: "Sending a request to",
      connect_with: "connect with",
      Reason_to: "Reason to",
      Send_Reason: "Send Reason",
      Select_Product_Type: "Select Product Type",
      SelectProductType:'Select Product Type',
      Continue: "Continue",
      Copyright: "© Copyright 2024 Alysei Corp |  All rights reserved  |",
      Contact_us: "Contact us",
      Terms_of_Use: "Terms of Use",
      Instagram: "Instagram",
      Youtube: "Youtube",
      Linkedin: "Linkedin",
      Forget_password: "Forget password?",
      Enter_your_email_recovery:
        "Enter your registered email to recover your password",
      only_home: "Home",
      Reset_Password: "Reset Password",
      Confirm_Password: "Confirm Password",
      only_Submit: "Submit",
      only_profile: "Profile",
      Logout: "Logout",
      B2B: "B2B",
      Recipes: "Recipes",
      Recipe: "Recipe",
      only_Marketplace: "Marketplace",
      Notifications: "Notifications",
      Clear_All: "Clear All",
      no_notifications: "There is no notification.",
      Minimun_8: "Minimun 8",
      digit_1: "1 digit",
      special_1: "1 special",
      search_components: "search components",
      Loading: "Loading...",
      // recipes...
      General_Information: "General Information",
      only_select: "Select",
      meal: "meal",
      Cooking_Skills: "Cooking Skills",
      Cuisine: "Cuisine",
      Diets: "Diets",
      Food_Introlerance: "Food Intolerance",
      Prepration_Time: "Preparation Time",
      Hours: "Hours",
      Minutes: "Minutes",
      Serving: "Serving",
      For_how_much_people_you_are_cooking:
        " For how much people you are cooking.",
      Select_Region: "Select Region",
      Change_Photo: "Change Photo",
      Upload_Recipe_Image: "Upload Recipe Image",
      click_here_to_upload_recipe_image: "click here to upload recipe image",
      IMAGES: "IMAGE",
      PNG_JPG: "PNG,JPG",
      Resolution: "Resolution",
      Upto_1600_1600: "Upto 1600x1600",
      Back: "Back",
      Add_Ingredients_used_in_Recipe: "Add Ingredients used in Recipe",
      Save_in_draft: "Save in draft",
      Page_Reload_message: "Changes you made may not be saved.",
      Quantity: "Quantity",
      Unit: "Unit",
      kg: "kg",
      litre: "litre",
      only_pieces: "pieces",
      dozen: "dozen",
      gm: "gm",
      ml: "ml",
      spoon: "Teaspoon",
      drops: "drops",
      Envelope: "Envelope",
      clove: "clove",
      tablespoon: "tablespoon",
      pinch: "pinch",
      shot: "shot",
      asNeeded: "as needed",
      glass: "glass",
      cup: "cup",
      Ingredients_List: "Ingredients List",
      Ingredients: "Ingredients",
      Used: "Used",
      Add_Ingredients_used_in_Recipe: "Add Ingredients used in Recipe",
      Step: "Step",
      Steps: "Steps",
      Tools_used_in_Step: "Tools used in Step",
      please_Write_Reviews: "please Write Reviews",
      please_Write_Rating: "Please add rating",
      only_Rating: "Ratings",
      Networking: "Networking",
      Reviews: "Ratings",
      Likes: "Likes",
      Like: "Like",
      There_are_no_ingredients: "There are no ingredients.",
      There_are_no_tools: "There are no selected tools.",
      Please_select_quantity_or_ingredients:
        "Please select quantity or ingredients.",
      Please_select_quantity_or_unit: "Please select unit and quantity",
      please_write: "Please write",
      title_required: "Title is required.",
      subject_required: "Subject is required.",
      message_required: "Message is required.",
      Please_Add_Recipe_Steps: "Please Add Recipe Steps",
      Add_Recipe_Steps: "Add Recipe Steps",
      Recipe_Steps: " Recipe Steps",
      RecipeSteps: " Recipe Steps",
      please_select_at_one_cooking_tool:
        "Please Select at least one cooking Tool",
      Add_Utencils_Tools_used_in_recipe: " Add Utensils/Tools used in recipe",
      Utencils: "Utensils",
      Save_in_draft: "Save in draft",
      Finish: "Finish",
      Tools_Utencils_Used: "Tools & Utensils Used",
      Mins: "Mins",
      Hr: "Hr",
      Likes: "Likes",
      Quick_search_by: "Quick search by",
      Trending_Now: "Trending Now",
      Quick_Easy: "Quick and Easy",
      Update_preferences: "Update preferences",
      Add_More: "Add More",
      Published: "Published",
      Draft: "Draft",
      Recipe: "Recipe",
      What_are_you_craving: "What are you craving?",
      Personalize_your_experience: "Personalize your experience",
      what_is_your_favorite_cuisines: "What is your favourite Cuisines?",
      Do_you_have_any_food_allergies: "Do you have any food allergies?",
      Do_you_follow_these_diets: "Which diet do you follow?",
      Do_not_want_to_see_ingredients: "Don't want to see ingredients?",
      The_Alysei_Recipe_Tool_is_a_world_of_good:
        "The Alysei Recipe Tool is a world of good, healthy Italian Recipes that you can explore, create, and share in an easy and fun way. Enjoy Italy and its flavors!",
      Explore: "Explore",
      MyRecipes: "My Recipes",
      MyPreferences: "My Preferences",
      You_may_also_like: "You may also like",
      Recipeby: "Recipe by",
      Start_Cooking: "Start Cooking",
      Suggested: "Suggested",
      MyFavouriteRecipe: "My Favourite Recipe",
      Meals: "Meals",
      Please_fill_Url_or_Description_field:
        "Please fill at least  Url or Description field",
      image_field_required: "Image is required",
      Please_fill_title: "Please fill title field",
      Create_Recipe: "Create Recipe",
      Edit_Recipe: "Edit Recipe",
      Leave_a_comment: "Write a comment...",
      ShareRecipe: "Share Recipe",
      Go_To_Store: "Go To My Store",
      Create_You_Store: "Create Your Store",
      Please_Enter_email_and_pass: "Please Enter Your username and password",
      Please_select_atleast_one_ingredients:
        "Please select at least one ingredients",
      Please_select_atleast_one_tool: "Please select at least one tool",
      Skip: "Skip",
      Suggested_Connections: "Suggested Connections",
      No_Connection_Found: "No Connection Found",
      Please_Select_At_Least_one_field: "Please select at least one field.",
      Create_Your_Own_modern:
        "Create your own modern, professional online Store",
      Create_Your_Product_List: "Create Your Product listing",
      Display_Your_Unique_Products_Listing:
        "Display your unique products listing",
      Respond_To_Buyers_Inquiry: "Respond to buyers inquiry",
      Interact_With_Your_Future_Buyers: "Interact with your future buyers",
      Favorite_and_Ratings: "Favorite and Ratings",
      Being_Able_To_Be_Reviewed_And_Rated_By_Buryers:
        "Being able to be reviewed and rated by buyers",
      Comming_Soon: "Coming Soon",
      Boost_your_listing: "Boost your listing",
      Boost_your_listing_to_expand_you_and_reach:
        "Boost your listing to expand you reach and increase buyers engagement",
      Sell_online: "Sell online",
      Sell_Your_Product_online_To_Your_Buyers:
        "Sell your product online to your buyers",
      B2C_Grand_Opening: "B2C Grand Opening",
      Alysei_Full_Opening_To_Market_Buyers_And_Consumers:
        "Alysei full opening to Market, Buyers and Consumers",
      Free_MemberShip: "Free MemberShip",
      Restaurant_SearchBar_Type: "Restaurant Type",
      Event_Description: "Event description",
      Give_Your_FeedBack: "Give us your feedback",
      Send_Your_FeedBack_Here: "Send your feedback here...",
      Rating_Is_Require: "Rating is required",
      Review_Is_Require: "Review is required",
      Review_Must_Be_Greater_Than_Three_Character:
        "Review must be greater than 3 Character",
      Thank_You_For_Your_Review: "Thank you for your review",
      What_Is_Your_View: "what is your view?",
      product_keywords: "Product Name",
      MarketPlaceMainHeading:
        "Explore the most powerful Italian Food and Beverage Marketplace to search for the carefully selected top high-quality products from our endless cuisine. Italian Producers can create their unique Store, upload their product portfolio, explore, search, and reply to inquiries from other Alysei Members. Voyagers can access to explore and learn more about the history, traditions, and quality of the Italian cuisine.",
      Search_for_recipes: "Search for recipes",
      write_comments: "write your comment...",
      Winner: "Winner",
      search_your_address: "Search Your Address",
      Apply: "Apply",
      write_message: "Write a message...",
      selectCities: "Select Cities",
      selectedHubs: "Selected Hubs",
      selectHubs: "Select Hubs",
      selectState: "Select the states from United States where you",
      noHubsFound: "No Hubs Found",
      onlyOneCity: "You can select only one City",
      onlyOneState: "You can select only one State",
      IngredientsUsed: "Ingredients used in Step ",
      helps_user_to_find_product: "Helps user to find product",
      quanity_available:
        "How many products are you currently available to deliver?",
      Minimum_Order_Quantity_tooltip:
        "Which is the minimum order quantity you are able to accept?",

      deletedSharePostHeading: "This content isn't available right now",
      deletedSharePostMessage:
        "when this happens,it's usually because the owner only shared it with a small group of people, changed who can see it or it's been deleted.",
      chooseHubOrStates: "It is necessary to choose either hubs or states",
      enterPhoneNo: "Please enter phone no",
      numericalDigit: "It consists of min 8 or max 10 numerical digits",
      enterAddress: "Please enter address",
      enterWebsiteLink: "Please enter website link",
      enterEvent: "Please Enter Competition / Event",
      enterWinningProduct: "Please Enter winning Product",
      enterMedalName: "Please Select Name of Medal",
      enterImage: "Please Select image",
      imageType: "The Image type must be JPEG, JPG, PNG",
      selectImageSize: "Image size should not be more than 2 MB",
      disputeImageSize: "Image size should not be more than 1 MB",
      selectImageSizeLessThan10: "The Image size must be less than 5 MB",
      selectImageSizeLessThan5: "Images should be less than 5",
      selectBannerImage: "Please select banner image",
      selectLogoImage: "Please select logo image",
      selectGalleryImage: "Please select gallery images",
      selectLogoBannerGalleryToChange:
        "Please select logo, Banner or Gallery to change",
      addPhotoMax10:
        " Photos 0/10 Choose your stores main photo first. Add more photos of your store max 10 allowed.",
      enterMessageField: "Message Field is Require Please Enter Your Message",
      enterTextBeforePost: "Please Add Some Text Before Post",
      enterMaxCharacter: "Enter maximum character 300",
      enterRecipeName: "Please enter recipe name",
      selectRecipeMeal: "Please select recipe meal.",
      selectRecipeCourse: "Please select recipe course.",
      selectRecipeCooking: "Please select recipe cooking skills.",
      selectRecipeCuisine: "Please select recipe cuisine.",
      selectRecipeServing: "Please select recipe prepration serving.",
      selectRecipeRegion: "Please select recipe region.",
      selectRecipeImage: "Please select recipe image.",
      selectOneState: "You can select only one state",
      provideSampleOfProduct:
        "Are you able to provide samples of your products upon request",
      productSellingPrice:
        "Indicate your product selling price to possible buyers",
      detailAboutSpecificDispatch:
        "Provide details about your specific dispatch instructions",
      invalidEmail: "Invalid email ID",
      invalidOTP: "Invalid OTP Number",
      review: "Rating",
      reviews: "Ratings",
      remobeAllHubsMessageTop:
        "This action will remove all the hubs, you have selected.",
      remobeAllHubsMessageMiddle: "Are you sure want to remove all the hubs?",
      Expertise: "Expertise",
      Title: "Title",
      SelectState: "Select State",
      Speciality: "Speciality",
      KeywordSearch: "Keyword search",
      EditPost: "Edit Post",
      select: "Select",
      search_messages: "Search Messages",
      properties: "Properties",
      add_menu: "Add Menu",
      product_title: "My store product",
      product_price: "Product Price",
      my_store: "Store Settings",
      closed_inquery: "Close Inquiry",
      how_choose_plan: "Choose how you plan to sell your products",
      Provide_details_about_your_safe_handling_instructions:
        "Provide details about your safe handling instructions",
      Enter_Title_for_Step: "Enter Title for Step",
      Your_recipe_direction_text_here: "Your recipe direction text here...",
      Search_Ingredients: "Search Ingredients",
      Search_Tools: "Search Tools",
      Producer: "Producer",
      No_Recipe_Found: "No recipe found",
      recipe_tool: "Recipe Tool",
      Store_Image_Heading: "Add Store images",
      gallery_images: "Gallery Images",
      add_a: "is required",
      coverImageSuccessMessage: "Cover image uploaded successfully",
      profileImageSuccessMessage: "Profile image uploaded successfully",
      Report_Problem: "Please select a problem with the post",
      Nudity: "Nudity",
      Violence: "Violence",
      Harassment: "Harassment",
      Suicide: "Suicide or Self Injury",
      False_Info: "False Information",
      Others: "Others",
      Write_Reason: "write a reason",
      One_Reason_Choose: "Please choose atleast one reason",
      Recommend_Size_900_900: "Recommended size 900 X 900",
      Singular_Hub: "Hub",
      coverImageWidthInvalidHead: "Please choose a different cover photo",
      coverImageWidthInvalidMessage:
        "This cover photo is too small. Please choose a cover photo with larger width.",
      pallet: "Pallets",
      visibility: "Visibility",
      select_all: "Select All",
      unselect_all: "Unselect All",
      change_password_text: "Please enter your current password to continue.",
      change_email: "Change Email",
      enter_new_email_text: "Enter your new email",
      confirm_your_email: "Confirm your email",
      confirm_email_mismatched: "Confirm email is mismatched",
      change_email_text:
        "Please enter your new email to associate with this account.",
      EMAIL_VISIBILITY: "Email Visibility",
      PHONE_NUMBER_VISIBILITY: "Phone Number Visibility",
      ADDRESS_VISIBILITY: "Address Visibility",
      Save_Proceed: "Save & Proceed",
      Please_select_brand_label: "Please select brand label",
      BLOCK_USERS: "Would you also like to block this user?",
      YOU_WANT_TO_BLOCK_USERS: "Are you sure you want to block this user?",
      BLOCK_USERS_HINT: "By blocking this user, you won't be longer connected.",
      PLEASE_SELECT: "Please select",
      TO_BLOCK_USER: "to block and submit",
      BLOCK_USERS_HINT_AND_MESSAGES:
        "By blocking this user, you won't be longer connected with him and not be able to see any activity",
      SPAM_FOLDER: "OTP can be in the spam folder",
      REALY_WANT_TO_UNBLOCK_USER: " Are you sure want to unBlock this user",
      SUPPORT_ACTION: "Support Actions",
      // new dashboard for producer...
      Recent_orders: "Recent Orders",
      Order_id: "Order ID",
      Customer_Name: "Customer Name",
      Total: "Total",
      Date: "Starting Date",
      Action: "Action",
      View: "view",
      Search_product_by_name: "search product  by name or its ID",
      Select_category: "select category",
      Select_stock: "select stock",
      Active: "active",
      Inactive: "Inactive",
      Image: "Image",
      Order_management: "Order Management",
      On_hold: "On_hold",
      New_orders: "New Orders",
      Ongoing: "Ongoing",
      Past_orders: "Past Orders ",
      Invoice: "invoice ",
      Tax_management: "Tax Management ",
      Tax_classes: "Tax Class",
      Tax_class: "Tax Class ",
      Tax_class_data_tip:'Select the tax class to apply. For more info go to the Tax Management section on your dashboard.',
      Tax_rate: "Tax Rate ",
      Tax_class_title: "Tax Class Name",
      Edit_tax_class: "Edit tax class",
      Confirm_Delete: "Confirm Delete ",
      Tax_class_notes:
        "Please note: This tax class has been used in 5 product.Are you sure you want to delete this tax class?",
      Taxes_type: "Tax Type",
      Tax_name: "Tax Name",
      Select_tax_type: "Select Tax Type",
      Select_tax_Class: "Select tax class",
      Download_invoice: "Download invoice",
      Print: "Print",
      Order_details: "Order details",
      My_order_view: "My order view",
      Order_on: "Order on",
      Billing_address: "Billing Address",
      Shipping_address: "Shipping Address",
      payment_method: "Payment Method",
      Order_summary: "Order summary",
      Grand_total: "Grand total",
      Shipping_charge: "Shipping Charge",
      Tax: "Tax",
      Unit_price: "Unit Price",
      Items_subtotal: "items subtotal ",
      Sr_no: "Serial No.",
      Customer_name: "customer name ",
      Cancellation: "cancellation",
      In_transit: "in transit",
      Completed: "Completed",
      completed: "completed",
      Processing: "Processing",
      Pending: "Pending",
      On_hold: "On hold",
      Upload_invoice: "upload invoice",
      Transaction_management: "Transaction Management",
      Date_to: "Date to",
      Generate_report: "Generate report",
      Transaction_id: "Transaction ID",
      Amount: "Total Amount",
      Payment_status: "Payment Status",
      Order_status: "Order Status",
      Date_from: "Date from",
      Succeeded: "Succeeded",
      Request_for_payment: "Request for payment",
      Requires_payment_method: "Requires payment method",
      Generate_report: "Generate report",
      Date_and_time: "date and time",
      Transaction_details: "transaction details",
      Order_completed: "Order Completed",
      last_order_date: "Last Order Date",
      Customer_management: "Customer Management",
      Offer_management: "Offer Management",
      Ending_date: "Ending Date",
      Importer_name: "Importer Name",
      Offer_title: "Offer Title",
      Offfer_id: "Offfer ID",
      Send_offer: "Send offer",
      Search_for_importer: "Search for importer",
      Add_more: "Add More",
      Includes_shipping_charges: "Includes shipping charges",
      Shipping_price: "Shipping price",
      Important_notes: "Important Notes",
      Incoterms: "Incoterms",
      Payment: "Payment",
      Mode_of_Payment: "Mode of Payment",
      general: "General",
      Paypal: "Paypal",
      Bank_wire_transfer: "Bank wire transfer",
      Account_Holder_Name: "Account Holder Name",
      Swift_Code: "Swift Code",
      Bank_Name: "Bank Name",
      Account_number: "Account Number",
      Recommended_retail_price: "Recommended Retail Price",
      Other: "Other",
      Stock: "Stock ",
      Sort_by_A: "Sort by A-Z ",
      Alysei_marketplace: "Alysei marketplace",
      // new dashboard for importer...
      My_orders: "My orders",
      Invoice_not_ready_to_download: "invoice not ready to download",
      Shipping_address: "Shipping Address",
      Billing_address: "Billing Address",
      Add_billing_address: "add billing address",
      Delete_address_msg: "Are you sure you want to delete this address?",
      Notes_Address_delete:
        "Please note: Deleting this address will not delete any pending orders being shipped to this address. To ensure uninterrupted fullfillment of future orders, please update any wishlists, subscribe and save setting and periodical subscriptions using this Address.",
      Add_shipping_details: "Add shipping details",
      Select_an_address: "Select an address",
      Add_billing_details: "Add billing details",
      More_details: "More details",
      add_to_cart: "Add To Cart",
      go_to_cart: "Go To Cart",
      My_wishlist: "My wishlist ",
      View_offers: "View Offers",
      Note: "Note",
      My_offers: "My Offers",
      Empty_cart: "Your cart is empty!",
      Empty_cart_text:
        " Looks like you haven’t added anything to your cart yet",
      Go_to_shop: "Go to shop",
      Select_Payment_Method: "Select Payment Method",
      Checkout: "Checkout",
      Expiry: "expiry",
      Order_total: "Order total",
      View_cart: "view cart",
      Continue_to_pay: "Continue to pay",
      Payment_success: "Payment Successful!",
      Payment_msg: "Your order has been successfully processed.",
      Page_refresh: "Please do not refresh page",
      Payment_is_in_progress: "payment is in progress.",
      Cart_msg:
        "At a time you can add either a product including offer or a product without offer.Do you want to add this product. Are you sure?",
      Clear_cart: "Clear cart",
      Continue_shopping: "Continue shopping",
      Offer_exp: "Offer has been Expired",
      Offer_userd: "You have already used this offer.",
      Different_store_product:
        "Currently you have items in your cart from another store.Would you like to clear the cart and add product of",
      Fields_req: "Please fill this field",
      Select_imp: "Select Importer",
      Saving: "Saving",
      Send: "Send",
      Select_a_date: "Select a date",
      Select_incoterms: "Select Incoterms",
      Incoterm_text: "Place of Destination",
      Select_product: "Select Product",
      Per_unit_rice: "Per Unit Price",
      Go_back: "Go back",
      N_A: "N/A",
      Offer_name: "Offer Name",
      Online: "Online",
      Online: "Online",
      Item: "Item",
      Vat: "Vat",
      Emails: "Email",
      Subtotal: "Subtotal",
      Customer: "Customer",
      Only_address: "Address",
      Shipment_info: "Shipment Info",
      Shipping_name: "Shipping Name",
      Shipment_id: "Shipment ID",
      Shipment_url: "Shipment URL",
      Taxes_rate: "Tax Rate",
      Taxes_rate: "Tax Rate",
      Please_note: "Please note",
      Confirm_msg: "Are you sure you want to delete this",
      onlyName: "Name",
      Reset_filter: "Reset Filter",
      Requesting: "Requesting",
      Requested: "Requested",
      Not_requested: "Not Requested",
      Pennding: "Pending",
      Category: "Category",
      Search_Product_By_Id: "Search Product by name or its ID",
      RRP: "PVC",
      Date_time: "Date & Time",
      In_stock: "In Stock",
      out_stock: "Out of Stock",
      Cancelled: "Cancelled",
      Select_hubs_for_restrict_connection:
        "WHO CAN NOT SEND YOU CONNECTION REQUEST BY HUB'S.",
      Enter_location: "Enter location",
      Tel: "Tel",
      My_Address: "My Address",
      Company_name: "Company Name",
      Total_price: "Total Price",
      Set_payment_option: "Set this as default payment option to get payment",
      Paypal_id: "Paypal Id",
      Option_require: "This field is require.",
      Shopping_cart: "Shopping Cart",
      minimum_product_qty: "Minimum order quantity of this product is",
      less_or_equal_product_qty: "Product should be less than or equal",
      num_of_qty: "no of quantity",
      Add_reflected_in_the_bill: "The details that you want to be reflected on the invoice",
      Clear: "Clear",
      Confirm_remove: "Confirm Remove",
      Confirm_clear: "Confirm Clear",
      Are_you_sure_to_do: "Are you sure you want to remove this",
      Are_you_sure_to_clear_cart: "Are you sure you want to clear cart",
      Address_delete_msg:
        "Deleting this address will not delete any pending orders being shipped to this address.",

      Set_shipping_details: "Set shipping details same as billing details.",
      New_address_added: "Add New Address",
      Payment_warning: "Please do not refresh page payment is in progress.",
      Billing_address_warning: "Please add billing details.",
      Shipping_address_warning: "Please add shipping details.",
      Order_errors: "Something went wrong with this order. Please try later.",
      Tax_class_create_successfully: "Tax class has been deleted successfully.",
      Tax_create_successfully: "Tax has been deleted successfully.",
      Offer_id: "Offer ID",
      Starting_date: "Starting Date",
      No_offer: "Offer not found",
      Offer: "Offer",
      Fixed: "Fixed",
      Percentage: "Percentage",
      Customer_details: "Customer Details",
      Customer_contact: "Customer Contact",
      Customer_email: "Customer Email",
      Customer_address: "Customer Address",
      Joining_date: "Joining Date",
      Total_number_of_order_completed: "Total Number of Orders Completed",
      Total_number_of_order_canceled: "Total Number of Orders Cancelled",
      Sort_By_Z_A: "Sort By Z-A",
      Email_id: "Email",
      Contact_No:'Contact',
      Support_24: "On-Line Support",
      Continuous_support: "Technical support through Alysei Live Chat",
      Manage_your_customers: "Manage your Customers",
      Customize_and_manage_your_customers:
        "Customize and manage your customers.",
      Online_payment_integration:
        "Online payment integration for smooth checkouts.",
      Alysei_will_charge:
        "Alysei will retain 10% commission for each transaction.",
      Alysei_commission_info:
        "Please note that Alysei 10% commission does not include any third party fees",
      Active_user: "Active",
      Inactive_user: "Inactive",
      charge_10_percentage:
        "Alysei will retain a 10% commission on your listed price",
      //new added key....
      You_earned: "Amount You Get",
      You_earned_info:
        "This amount will include Alysei platform commission and Stripe platform fee.",
      RRP_info:
        "The recommended retail price (PVC) is the price suggested by the manufacturer that the product should be sold at.",
      Tax_Class_info:
        "A tax class is a collection of tax rates. Tax classes determine the tax rates that apply.",
      Tax_Rate_info:
        "A tax rate is the percentage at which taxes are paid to a government.",
      Order_management_info: "",
      badge_Text_For_Stripe:
        "To sell your products in Alysei and get payments, please connect your Stripe account. For more info,",
      Tax_Class_Product:
        "Select the tax class to apply. For more info go to the Tax Management section on your dashboard.",
      // new landing
      Welcome: "Welcome to Alysei",
      A_Bright_future : "A Bright Future for the Italian Gastronomy Export",
      Alysei_is_the_premier_B2B: "Alysei is the premier B2B and B2C Platform designed to support the international expansion of Italian Food and Beverage companies, helping them grow their brands and businesses in overseas markets",
      About_Alysei: "About Alysei",
      Set_your_course: "Set your course by the stars, not by the lights of every passing ship",
      Alysei_was_conceived_L: "Alysei was conceived in a small coffee shop in Lake Geneva, Wisconsin, driven by a shared passion and a desire to create new market opportunities. This vision brought us together and turned an idea into a thriving reality.",
      Alysei_is_the_first_B2B: "Alysei is the first B2B and B2C portal dedicated to showcasing high-quality Italian products in the Food and Beverage sector. Developed as a social platform, it caters to an audience passionate about Made in Italy Food and Wine, offering a unique space for connection and discovery.",
      Our_platform_aims: "Our platform aims to reach the entire American Market, specifically those who are passionate about Italian culinary culture and tradition.",
      Qualified_and_Certified: "Qualified and Certified Italian Producers who join Alysei will have access to a range of tools designed to accelerate their growth in the U.S. Market. These include the ability to connect with vetted Importers and Distributors, create personalized B2B Marketplace Stores, strengthen brand presence, promote products, and launch targeted marketing and commercial campaigns,all tailored to enhance visibility and drive success in the American Market.",
      In_addition_to_Italian_Producers: "In addition to Italian Producers, the Alysei ecosystem will also include Certified Members such as Italian Restaurants, Importers and Distributors, Industry Experts, Chefs, and specialized Travel Agencies. These key players will contribute to enriching the Alysei community, creating a dynamic network centered around Italian excellence.",
      Our_Mission: "Our Mission",
      You_can_never_cross: "You can never cross the ocean until you have the courage to lose sight of the shore",
      Alysei_bridges_the_gap: "Alysei bridges the gap between tradition and modernity, past and future, by offering Italian high-quality product manufacturers the opportunity to expand their business operations in the U.S. market.",
      While_embracing_growth :
        "While embracing growth and innovation, these Producers can preserve and showcase their centuries-old traditions and unique identities.",
      From_Farm: "From Farm to Fork",
      The_Premier_B2B: "The Premier B2B Marketplace for Italian Food and Wine",
      B2B_Connections: "B2B Connections Engine",
      Elevate_Your_Brand: "Elevate Your Brand, Expand Your Business",
      Growth: "Growth",
      Market_Expansion_Blueprint: "Market Expansion Blueprint",
      Numerous_companies: "Numerous companies in Italy are seeking to diversify and expand their operations overseas by exploring new business development opportunities in the United States.",
      A_recent_study: "A recent study revealed that between 50% and 70% of U.S. consumers consider a product's origin to be a critical factor in their purchasing decisions, often willing to pay a premium for assured and certified quality.",
      It_is_important: "It is important to note that the United States boasts the world’s largest economy, with a Gross Domestic Product exceeding $29 trillion. Additionally, over 17 million Italians reside in a population of more than 330 million, highlighting a significant market potential for Italian products.",
      Origin_significantly: "Origin significantly influences consumer choice",
      Strategic_marketing: "Strategic marketing for growth",
      Expand_international:"Expand international operations",
      Consumers_are_willing:"Consumers are willing to pay for certified quality",
      Investment:"Investment",
      Major_Opportunities :"Major Opportunities Demand Meaningful Investment",
      With_over :"With over 70,000 companies in the food and wine sector alone, the heart of our Made in Italy tradition is rooted in quality products. This presents substantial development opportunities, including strategies for brand recognition, effective marketing policies, compliance with necessary certifications, identification of optimal import and distribution channels, connections with buyers, customer base development, and the establishment of strategic collaborations.",
      Alysei_provides:"Alysei provides a straightforward, cost-effective, and innovative B2B/B2C solution designed to accelerate your time to market.",
      Assess_US:"Assess U.S. market risks and opportunities",
      Identify_your_target:"Identify your target market",
      Find_suitable:"Find suitable importers",
      Connect_directly:"Connect directly with customers",
     Lets_Start :"Let's Start!",
     Where_to_Begin :"Where to Begin?",
     The_first_step :"The first step is to log into the Alysei platform, where a thorough verification and approval process ensures quality and safety. As an Alysei Certified Member, you can complete your Company Profile, which will serve as your market showcase.",
     Here_you_can :"Here, you can share your story, highlight your flagship products, and create posts in a social environment that allows you to connect directly with customers. You will also gain access to the Alysei B2B Marketplace, where you can build and promote your online store, presenting your products to potential import channels, buyers, and beyond.",
     Alysei_is_a_platform_designed :"Alysei is a platform designed for those passionate about Italian food and wine. It serves as a business hub for fostering collaboration and growth, providing a window into a landscape rich with development opportunities.",
     Complimentary_Registration :"Complimentary Registration",
     Thorough_Verification :"Thorough Verification and Approval Process",
     Achieve_Alysei :"Achieve Alysei Certified Member Status",
      Showcase_Your_Brand: "Showcase Your Brand, Products, and Story",
      // Members Section
  Members_title: "Alysei Member's",
  Members_quote: "“In the long history of humankind those who learned to collaborate and improvise most effectively have prevailed”",
  Italian_FB_Producers: "Italian F&B Producers",
  Italian_FB_Producers_Description_m: "Explore, find and connect with certified Importers and Distributors in the USA, build up and consolidate your brand, promote your products, reach your consumers.",
  Importers_Distributors: "Importers & Distributors",
  Importers_Distributors_Description_m: "Explore, find and connect with local Italian Producers to strengthen your product portfolio, enhance your competitiveness, expand your brand and market access.",
  Italian_Restaurants: "Italian Restaurants in US",
  Italian_Restaurants_Description_m: "Strengthen collaboration with producers, Importers, promote your cuisine and special events, bring more clients to the table by exponentially expanding your reach.",
  Voice_of_Experts: "Voice Of Experts",
  Voice_of_Experts_Description_m: "Chefs, Cooking Schools, and all Italian Food and Beverage specialists will leverage on the Alysei platform to promote their name, brand, offering, events, blogs.",
  Travel_Agencies: "Travel Agencies",
  Travel_Agencies_Description_m: "Strengthen connection with certified Italian Producers, Importers, and Distributors in the USA, grow your visibility, and reach your target customers.",
  Voyagers: "Voyagers",
  Voyagers_Description_m: "Enjoy the magic world of Italian cuisine through products, restaurants, events, trips, tasting tours, cooking classes, recipes, blogs, and more.",
  Buyers: "Buyers",
  Buyers_Description_m: "Search for the most authentic Regional Italian Products, cut sourcing times and buy at zero commission directly from the Producer at your own Terms.",
  How_can_we_help: "How can we help?",
  Schedule_Demo: "Schedule a Demo",
      Schedule_Demo_Description: "Book a demo today to discover everything you need to know about Alysei. We will assist you in creating your profile and in promoting and expanding your business. Alysei... Sailing to Success!",
      MarketPlace_title: "MarketPlace",
      MarketPlace_subtitle: "The B2B Marketplace for Italian Food & Wine",
      MarketPlace_description: "Alysei is the premier B2B marketplace that showcases the excellence of Italian food and wine to a global audience.",
      MarketPlace_sections: {
        signUp: {
          title: "Sign up on Alysei",
          content: "Complete the registration in few easy steps and enjoy the journey"
        },
        createStore: {
          title: "Create your Store",
          content: "Showcase your product in a simple, clean and professional way"
        },
        productList: {
          title: "Create your Product List",
          content: "Upload your product portfolio with accurate and complete information and photos in high quality"
        },
        enquiry: {
          title: "Respond to Buyers Inquiry",
          content: "Being responsive will help you to build trust with Buyers"
        },
        sell: {
          title: "Sell Online",
          content: "Sell online directly to Importers"
        },
        support: {
          title: "Online Support",
          content: "Let us know if something does not feel right"
        }
      },
      Buyers_title: "Buyers",
  Buyers_subtitle: "Alysei B2B Platform for Buyers",
  Buyers_description: "Buyers can significantly reduce supplier sourcing time when searching for high-quality Italian Food and beverage products for their shelves, all while connecting with the right producers at no cost.",
  Buyers_sections: {
    signUp: {
      title: "Sign up on Alysei",
      description: "Complete the registration in few easy steps and enjoy the journey"
    },
    createStore: {
      title: "Discover Products",
      description: "Search for the most authentic Regional Products that fit your needs"
    },
    productList: {
      title: "Create your List",
      description: "Select and create your Product list based on your specific criteria"
    },
    enquiry: {
      title: "Send your Request",
      description: "Create, send and manage your request connecting directly with the Producer"
    },
    sell: {
      title: "Buy at Zero Commission",
      description: "Buy at zero commission directly from the Producer at your own Terms"
    },
    support: {
      title: "Get support from Alysei",
      description: "Don't find what you are looking for? Ask Alysei, we will be happy to help!"
    }
      },
      Videos_title: "ALYSEI ACADEMY",
      Videos_subtitle: "How to Use Alysei: Tutorial Videos",
      videoList: [
        { id: 1, title: "Introduction to Alysei World", videoUrl: "https://www.youtube.com/embed/71r0QV7OeXg?autoplay=1",image : thumbnail1 },
        { id: 2, title: "Alysei Producers Registration Process", videoUrl: "https://www.youtube.com/embed/i0D0R0WMjRc?autoplay=1", image : thumbnail6 },
        { id: 3, title: "Profile Creation for Italian Producers", videoUrl: "https://www.youtube.com/embed/iU4_UJDpeHU?autoplay=1", image : thumbnail18 },
        { id: 4, title: "The Alysei Members", videoUrl: "https://www.youtube.com/embed/ZdBHcAIbpqo?autoplay=1", image : thumbnail17 },
        { id: 5, title: "B2B Connection Engine", videoUrl: "https://www.youtube.com/embed/3_bOpWuAUu4?autoplay=1", image : thumbnail11 },
      ],
      Testimonials_title: "Testimonials",
      Testimonials_subtitle: "What Alysei Members Are Saying!",
      testimonials: [
        {
          id: 1,
          text: "Alysei is the opportunity that many Italian manufacturers have been looking for a long time. The real challenge that is encountered in a market is not only to find Italian products but also and above all to research and find those that meet the highest standards of high quality. Alysei is designed to help Italian producers eager to export their brand and authentic taste overseas. I am proud to be Ambassador of Alysei for Italian gastronomy and help select and bring the best gastronomy to an increasingly attentive and demanding clientele!",
          name: "Carlo Raspollini",
          position: "RAI Author/Television director/Screenwriter/Radio host"
        },
        {
          id: 2,
          text: "Alysei represents the solution that the market has long sought: a collaborative network designed for high-quality product producers like us to explore effective strategies for expanding our business in the U.S. market. This platform allows us to limit our investment in market research while maximizing efficiency and reducing time to market.",
          name: "Giovanni Melis",
          position: "Sales Director, Cantina Su'entu"
        },
        {
          id: 3,
          text: "Alysei is the solution that many producers like us have been seeking for a long time: a platform that facilitates easy search and connection with potential partners, providing straightforward access to a rapidly expanding market.",
          name: "Stefano Alderighi",
          position: "Sales Manager, Alps Coffee"
        },
        {
          id: 4,
          text: "Alysei is the platform for expanding market reach through access to a broader network of potential international buyers, including retailers, wholesalers, and distributors, while reducing marketing expenses by leveraging Alysei’s built-in tools and resources to reach potential customers.",
          name: "Enrico Lepori",
          position: "Founder, Sardo Sole"
        },
        {
          id: 5,
          text: "One of Love to Italy's primary challenges is finding Italian producers who uphold high-quality standards. With Alysei we can simplify the sourcing process by easily finding suppliers that meet our specific quality and product requirements, connecting us directly with a curated network of high-quality Italian producers, ensuring that we source authentic and premium products.",
          name: "Alessandro Sofroni",
          position: "Founder & CEO, Love to Italy"
        },
        {
          id: 6,
          text: "Alysei serves as a platform to protect and promote authentic Italian products through resources and information that educate consumers about the importance of choosing authentic Italian products, including details about quality standards and certifications.",
          name: "Robert Campana",
          position: "Founder, Stop Italian Sounding"
        },
        {
          id: 7,
          text: "Italy is a territory blessed by incredible resources and thousands of small and mid-size companies that produce high-quality products in the food and beverage space that are second to none. Quality, flavors, and variety are the core values of our products and the most important assets we all have.",
          name: "Alberto Servadei",
          position: "President and CEO, Servadei Specialità Biologiche"
        },
        {
          id: 8,
          text: "One of our major challenges at Isola Imports is bringing together Italian producers with high-quality products and standards that are associated with our passion as an Importer/Distributor of Italian Specialty Food.",
          name: "Michael Nitti",
          position: "Operations Manager, Isola Imports"
        },
        {
          id: 9,
          text: "Alysei reflects the interest, passion, and willingness of all good Italian restaurants that have spent their lives bringing traditional Italian cuisine flavor to your table. The opportunity to be part of such a powerful initiative aimed at connecting people and gaining visibility is tremendous.",
          name: "Benny Siddu",
          position: "Owner & Founder, Volare Ristorante"
        },
        {
          id: 10,
          text: "Alysei specializes in connecting the Italian food and beverage industry with key players across the globe. The platform offers a network of restaurateurs, distributors, chefs, buyers, and food lovers who are specifically interested in Italian cuisine, ensuring that the connections are highly relevant.",
          name: "Marco Baccichetto",
          position: "Owner, Casa Baccichetto"
        },
        {
          id: 11,
          text: "Alysei facilitates connections with suppliers, distributors, and other industry professionals. A pizzeria like ours can benefit from new partnerships, access to high-quality ingredients, and potential collaborations with Italian product suppliers.",
          name: "Elio Bartolotta",
          position: "Owner & Founder, Elio Pizza on Fire"
        },
        {
          id: 12,
          text: "We truly believe that the strategic partnership between Ormesani srl and Alysei could provide complementary expertise to Ormesani's operations, creating synergies that enhance both companies' growth potential. This would allow Ormesani to focus on its core business while benefiting from Alysei’s strategic leverage to speed up this ambitious internationalization journey.",
          name: "Martino Giuseppe Ormesani",
          position: "President of the Ormesani America Corp."
        }
      ],
      About_Alysei: "About Alysei",
    Set_your_course: `"Set your course by the stars, not by the lights of every passing ship"`,
    Alysei_was_conceived: "Alysei was born in a small coffee shop in Lake Geneva, Wisconsin, inspired by a shared passion and a commitment to creating new market opportunities. It was this vision and determination that brought us together and turned our idea into reality",
    Team_Alysei: "ALYSEI TEAM",
    Founders: "Founders",
    Andrea: "Andrea",
    Andrea_Description: "With over 30 years of experience in International Marketing and Business Development, he has had the privilege of traveling the globe, exploring diverse cultures and regions. His ambitious projects are fueled by a deep love and passion for his homeland, Italy. He takes great pride in sharing the vast richness of Italy's history and traditions, engaging in conversations that reflect his deep connection to its cultural heritage.",
    Simona: "Simona",
      Simona_Description: "Her background in the medical field has instilled in her the art of caring for others and raising awareness about the importance of a healthy diet. She brings this same passion and dedication to the Alysei journey, driven by her dream of enriching people’s experience of Italian culture and cuisine through Alysei.",
      Our_Presence: "OUR PRESENCE",
      Alysei_Around_The_World: "Alysei around the world",
      Join_Alysei: "Join Alysei Today",
    Sign_Up_Description: "Join Alysei by signing up for free. Please note that access requests are subject to approval.",
      Join_Now: "Join Now",
      Contact_Us: "CONTACT US",
      Lets_Get_In_Touch: "Let’s get in touch!",
      Complete_The_Form: "Complete the form, and our team will get back to you as quickly as possible.",
      First_Name: "First Name",
      Last_Name: "Last Name",
      Company_Name: "Company Name",
      Email: "Email",
      Message: "Message",
      Write_Here: "Write here...",
      Send_Message: "Send Message",
      //benefits
      Italian_FB_Producers_Title: "ITALIAN F&B PRODUCERS",
        Italian_FB_Producers_Description: "Explore, discover, and connect with certified Importers and Distributors across the USA. Build and strengthen your brand, promote your products, and engage directly with your target consumers",
        Italian_FB_Producers_Investment_Heading: "Investment",
        Italian_FB_Producers_Investment_Subheading: "Significant opportunities require substantial investment",
        Italian_FB_Producers_Investment_Desc: "An estimated 70,000 companies operate within the Food and Wine sector alone, representing the heart of our Made in Italy tradition and commitment to quality products. This presents significant development opportunities, including strategies for brand recognition and growth, effective marketing policies, compliance with necessary certifications, identifying the right import and distribution channels, connecting with buyers, building a customer base, and developing strategic collaborations. Alysei offers a simple, cost-effective, and innovative B2B/B2C solution designed to accelerate your time to market",
        Italian_FB_Producers_Investment_BadgeInfo: [
            "Gain insight into the risks and opportunities",
            "Identifying the right importers",
            "Learn how to identify your target market",
            "Learn how to engage directly with customers"
        ],
        Italian_FB_Producers_Growth_Heading: "Growth",
        Italian_FB_Producers_Growth_Subheading: "Market Expansion Strategy",
        Italian_FB_Producers_Growth_Desc: "Many companies in Italy are seeking to diversify and expand their overseas operations by exploring new business development opportunities in the United States. A recent study revealed that between 50% and 70% of U.S. consumers consider the product's origin a key factor in their purchasing decisions, and they are willing to pay a premium for assured and certified quality. It's important to note that the United States is the world's largest economy, with a Gross Domestic Product exceeding $23 trillion. Additionally, there are over 17 million Italians residing in the U.S. out of a total population of more than 330 million.",
        Italian_FB_Producers_Growth_BadgeInfo: [
            "The product's origin is a crucial factor",
            "Diversify and Expand Overseas Operations",
            "Marketing Growth Strategy",
            "Willing to pay a premium for assured and certified quality"
        ],
        Italian_FB_Producers_Future_Heading: "LOOKING AHEAD",
        Italian_FB_Producers_Future_Subheading: "Relying solely on an importer is not sufficient for effectively developing a market",
        Italian_FB_Producers_Future_Desc: "Over 10,000 Italian companies are exporting food and beverage products to the U.S., with the goal of solidifying their brands, expanding their audience, and increasing both sales and market share. However, a significant challenge lies in the substantial investment required to obtain necessary certifications, build market awareness, identify the right Importers and Distributors, pinpoint the appropriate markets, develop their brands, and effectively position their product offerings to the right consumers.",
        Italian_FB_Producers_Future_BadgeInfo: [
            "Strengthen their brand",
            "Establish market awareness",
            "Expand their audience reach",
            "Boost their sales"
      ],
      Italian_FB_Producers_Services_Title: "EXPLORE OUR FEATURES",
      Italian_FB_Producers_Services_Desc: "You can never cross the ocean until you have the courage to lose sight of the shore",
      Italian_FB_Producers_Services_Content1: "Complete your company profile to enhance your visibility",
      Italian_FB_Producers_Services_Content2: "Promote your brand and product offerings",
      Italian_FB_Producers_Services_Content3: "Create your store in the Alysei B2B Marketplace",
      Italian_FB_Producers_Services_Content4: "Find and connect with U.S. Importers and Distributors",
      Italian_FB_Producers_Services_Content5: "Find and connect with Italian Restaurants in the U.S.",
      Italian_FB_Producers_Services_Content6: "Connect with Buyers worldwide",
      Italian_FB_videoTitle : "OUR RESOURCES",
      Italian_FB_videoDesc : "Introductory Video for Producers",
      Italian_FB_videoSub : "In this video, you will learn about Alysei's mission and the benefits it offers to Producers. We will also guide you through a few simple steps to access Alysei and become part of this exciting journey. ",
      Importers_Distributors_Title: "IMPORTERS & DISTRIBUTORS",
      Importers_Distributors_Description: "Importers and distributors are continuously seeking new, high-quality products to diversify their offerings and enhance their competitiveness",
      Importers_Distributors_ContentImg_Heading: "B2B CONNECTION",
      Importers_Distributors_ContentImg_Subheading: "Expand your network to enhance your reach",
      Importers_Distributors_ContentImg_Desc: "Alysei is specifically designed to facilitate connections between the right companies based on their specific needs and requirements. This approach significantly shortens the costly and time-consuming process of searching for and connecting with qualified partners at the right time. By saving both time and money, Alysei helps businesses identify the optimal path for expansion in their respective markets.",
      Importers_Distributors_ContentImg_BadgeInfo: [
        "Save both time and money",
        "Diversify their offerings",
        "Introduce high-quality products",
        "Enhance competitiveness"
      ],
      Importers_Distributors_Services_Desc: "In the long history of humankind, those who learned to collaborate and improvise most effectively have prevailed",
      Importers_Distributors_Services_Content1: "Complete your company profile to enhance your visibility",
      Importers_Distributors_Services_Content2: "Promote your brand and product offerings",
      Importers_Distributors_Services_Content3: "Access to Alysei B2B Marketplace",
      Importers_Distributors_Services_Content4: "Find and connect with Italian Food & Wine Producers",
      Importers_Distributors_Services_Content5: "Find and connect with all other Alysei Members",
      Importers_Distributors_Services_Content6: "Launch promotional campaigns for your events to strengthen your business",
      Importers_Distributors_videoDesc: "Introductory Video for Importers and Distributors",
      Importers_Distributors_videoSub : "In this video, you will learn about Alysei's mission and the benefits it offers to Importers and Distributors. We will also guide you through a few simple steps to access Alysei and become part of this exciting journey.",
      Italian_Restaurants_Title: "ITALIAN RESTAURANTS IN US",
      Italian_Restaurants_Description: "Each city in the U.S. boasts a significant number of Italian restaurants, all of which are potential Alysei members",
      Italian_Restaurants_ContentImg_Heading: "STAY COMPETITIVE",
      Italian_Restaurants_ContentImg_Subheading: "Increase your customer base",
      Italian_Restaurants_ContentImg_Desc: "Restaurants must continually invest to remain competitive in the market, maintain and potentially increase their customer base, and surprise patrons with new ideas. It's essential to ensure that people are aware of what you offer and your future plans. The power of word-of-mouth—what people say about your business, how you operate, how you care for your clients, and aspects like quality, pricing, and location—can either make or break your success.",
      Italian_Restaurants_ContentImg_BadgeInfo: [
        "How to stay in business",
        "Increase your client base",
        "Delight customers",
        "Innovative ideas for diverse customer segments"
      ],
      Italian_Restaurants_Services_Desc: "In the long history of humankind, those who learned to collaborate and improvise most effectively have prevailed",
      Italian_Restaurants_Services_Content1: "Complete your company profile to enhance your visibility",
      Italian_Restaurants_Services_Content2: "Access Alysei Social to promote your restaurant and showcase your cuisine",
      Italian_Restaurants_Services_Content3: "Explore Alysei B2B Marketplace and seek out high-quality products",
      Italian_Restaurants_Services_Content4: "Find and connect with Italian Food & Wine Producers",
      Italian_Restaurants_Services_Content5: "Find and connect with Importers and Distributors in the U.S.",
      Italian_Restaurants_Services_Content6: "Promote events to attract more clients and expand your reach",
      Voice_of_Experts_Title: "VOICE of EXPERTS",
      Voice_of_Experts_Description: "The extensive network of subject matter experts in this field is highly qualified and eager to enhance their visibility in this rapidly growing market",
      Voice_of_Experts_ContentImg_Heading: "EXPERTISE",
      Voice_of_Experts_ContentImg_Subheading: "Voice of Experts Community",
      Voice_of_Experts_ContentImg_Desc: "The voice of experts includes chefs, cooking schools, top restaurant chefs, culinary bloggers, chefs' associations, Italian food and beverage specialists, television shows, magazines, and authors. Alysei Certified Voices of Experts can leverage the Alysei platform to promote their names, brands, initiatives, events, blogs, and books.",
      Voice_of_Experts_ContentImg_BadgeInfo: [
        "Promote your name",
        "Enogastronomy subject matter experts",
        "Enhance and grow your exposure",
        "Engage a wider audience"
      ],
      Voice_of_Experts_Services_Desc: "In the long history of humankind those who learned to collaborate and improvise most effectively have prevailed",
      Voice_of_Experts_Services_Content1: "Complete your profile to enhance your visibility",
      Voice_of_Experts_Services_Content2: "Access to Alysei Social to promote your knowledge and expertise",
      Voice_of_Experts_Services_Content3: "Explore Alysei B2B Marketplace and seek out high-quality products",
      Voice_of_Experts_Services_Content4: "Find and connect with Italian Food & Wine Producers",
      Voice_of_Experts_Services_Content5: "Find and connect with Importers & Distributors in the U.S.",
      Voice_of_Experts_Services_Content6: "Create and promote your enogastronomy event, blogs, classes to engage a wider audience",
      Traveler_Agencies_Title: "TRAVEL AGENCIES",
    Traveler_Agencies_Description: "The best way to explore Italy is by immersing yourself in its richly diverse regions",
    Traveler_Agencies_ContentImg_Heading: "MARKET OPPORTUNITIES",
    Traveler_Agencies_ContentImg_Subheading: "Enhance Collaboration with Italian Producers",
    Traveler_Agencies_ContentImg_Desc: "Specialized travel agencies are increasingly exploring B2B opportunities to establish connections with Italian food and beverage producers. By tailoring travel itineraries, they aim to provide their clients with unforgettable and unique experiences.",
    Traveler_Agencies_ContentImg_BadgeInfo: [
      "Build up connections",
      "Unforgettable and unique experience",
      "Target customers",
      "Food and Wine tourism"
    ],
    Traveler_Agencies_Services_Desc: "In the long history of humankind, those who learned to collaborate and improvise most effectively have prevailed",
    Traveler_Agencies_Services_Content1: "Complete your profile to enhance your visibility",
    Traveler_Agencies_Services_Content2: "Access Alysei Social to promote your services",
    Traveler_Agencies_Services_Content3: "Explore Alysei B2B to enhance your collaborations",
    Traveler_Agencies_Services_Content4: "Find and connect with Italian Food & Wine Producers",
    Traveler_Agencies_Services_Content5: "Find and connect with Importers and Distributors in the U.S.",
    Traveler_Agencies_Services_Content6: "Promote your unique Food and Wine itineraries",

    Voyagers_Title: "VOYAGERS",
    Voyagers_Description: "The user community is vast and diverse, encompassing a large population across the USA that shares a deep passion for Italian culinary culture and traditions",
    Voyagers_ContentImg_Heading: "Why Alyesi",
    Voyagers_ContentImg_Subheading: "REASON TO JOIN ALYSEI",
    Voyagers_ContentImg_Desc: "Joining a social media platform focused on Italian gastronomy offers a rich and flavorful experience. Discover authentic Italian recipes, cooking tips, and creative twists on classic dishes, all while immersing yourself in Italy’s culinary heritage. Connect with fellow food lovers, share your experiences, and exchange ideas in a vibrant community. Enjoy stunning food photography that inspires your cooking and presentation, stay updated on Italian food festivals and global culinary trends, and gain valuable insights from chefs and food experts. Whether seeking culinary inspiration or planning a gastronomic adventure to Italy, this platform is your gateway to a world of Italian cuisine.",
    Voyagers_ContentImg_BadgeInfo: [
      "Authentic Culinary Experience",
      "Community and Connection",
      "Visual Inspiration",
      "Updates and Insights"
    ],
    Voyagers_Services_Desc: "If you’re looking for the next big thing, and you’re looking where everyone else is, you’re looking in the wrong place",
    Voyagers_Services_Content1: "Create your profile on Alysei to showcase your passions and access personalized recommendations",
    Voyagers_Services_Content2: "Search for products, restaurants, events, cooking classes, blogs, and trips to Italy",
    Voyagers_Services_Content3: "Search for recipes, create your own, share them, and receive reviews",
    Voyagers_Services_Content4: "Social interaction, information sharing, product reviews, restaurant reviews & more",
    Voyagers_Services_Content5: "Discover the Alysei Marketplace, where you can explore products and access exclusive content",
    Voyagers_Services_Content6: "Enhance your membership and unlock exclusive benefits",

    Buyers_Title: "BUYERS",
    Buyers_Description: "Discover the most authentic regional Italian products, reduce sourcing times, and purchase directly from producers at zero commissions on your own terms",
    Buyers_ContentImg_Heading: "MARKET OPPORTUNITIES",
    Buyers_ContentImg_Subheading: "High-Quality Products on Your Shelves Faster and at Lower Costs",
    Buyers_ContentImg_Desc: "The demand for Italian products in the global market is continually growing. Made in Italy food and beverage items have strong appeal among consumers, with supermarkets being the preferred purchasing channel for food specialties (accounting for 69% of total sales). Alysei is specifically designed to facilitate connections between global buyers and the right companies based on their unique needs and requirements. This approach dramatically shortens the costly and time-consuming process of searching for and connecting with qualified Italian producers at the right time. By saving both time and money, Alysei helps you identify the optimal path for business expansion in your territory.",
    Buyers_ContentImg_BadgeInfo: [
      "Sourcing time reduced by 60%",
      "Save time and money",
      "Improve your margins",
      "Purchase at zero commissions"
    ],
    Buyers_Services_Desc: "Learning and innovation go hand in hand. The arrogance of success is to think that what you did yesterday will be sufficient for tomorrow",
    Buyers_Services_Content1: "Sourcing times reduced by 60%. Get new products on shelves in record time",
    Buyers_Services_Content2: "Search, select, send product requests, and engage with certified Producers",
    Buyers_Services_Content3: "Shortlist and engage with active, verified Alysei Producers for targeted cost savings",
    Buyers_Services_Content4: "Purchase directly from the Producer at zero commission and on your own terms",
    Buyers_Services_Content5: "Select and create your product list based on your specific criteria",
    Buyers_Services_Content6: "Create, send, and manage your requests while connecting directly with Producers",
    Download_Title: "Download the Alysei App",
    Download_Description: "Download the Alysei App to explore a world of Italian gastronomy, connecting consumers with authentic Italian products, fostering collaboration and expanding your market—where local flavors meet international palates!",
    Newsletter_Title: "Join Our Newsletter",
    Newsletter_Description: "To receive the latest updates and promotions",
    Newsletter_Placeholder: "Enter your email",
    Newsletter_Button: "Subscribe",
      faq: "Frequently Asked Questions",
      faqItems: [
        {
          title: "What is Alysei?",
          description: "Alysei is a specialized B2B/B2C Platform aimed at facilitating the internationalization of premium Italian Food and Beverage products.",
        },
        {
          title: "What are the key benefits of joining Alysei?",
          description: "Alysei serves as a strategic connector among Italian Food and Beverage Producers, U.S. Importers and Distributors, Italian Restaurants in the U.S., Industry Experts, and global Buyers. This platform facilitates partnerships and collaborations, enabling Italian Producers to access new markets, increase visibility among potential Buyers, and enhance their global presence.",
        },
        {
          title: "In what ways can Alysei assist Italian Producers?",
          description: "The platform provides a range of tools and resources to help businesses navigate the complexities of international trade, including logistics and regulatory compliance. Additionally, Alysei enhances brand visibility through targeted marketing initiatives and participation in industry events.",
        },
        {
          title: "What is the B2B section?",
          description: "The B2B (Business to Business) section of Alysei is focused on fostering collaboration and the development of commercial relationships among its members, aimed at creating growth and business opportunities.",
        },
        {
          title: "What does B2C refer to?",
          description: "The B2C (Business to Customer) section of Alysei is designed to enable producers to connect with their prospective consumers.",
        },
      ],
      see_more : "See More",
      see_less: "See Less",
      faqItems_Page: [
        {
          title: "What is Alysei?",
          description: "Alysei is a specialized B2B/B2C Platform aimed at facilitating the internationalization of premium Italian Food and Beverage products."
        },
        {
          title: "What are the key benefits of joining Alysei?",
          description: "Alysei serves as a strategic connector among Italian Food and Beverage Producers, U.S. Importers and Distributors, Italian Restaurants in the U.S., Industry Experts, and global Buyers. The platform facilitates partnerships and collaborations, enabling Italian Producers to access new markets, increase visibility among potential Buyers, and enhance their global presence."
        },
        {
          title: "In what ways can Alysei assist Italian Producers?",
          description: "The platform provides a range of tools and resources to help businesses navigate the complexities of international trade, including logistics and regulatory compliance. Additionally, Alysei enhances brand visibility through targeted marketing initiatives and participation in industry events."
        },
        {
          title: "What is the B2B section?",
          description: "The B2B (Business to Business) section of Alysei is focused on fostering collaboration and the development of commercial relationships among its members, aimed at creating growth and business opportunities."
        },
        {
          title: "What does B2C refer to?",
          description: "The B2C (Business to Customer) section of Alysei is designed to enable producers to connect with their prospective consumers."
        },
        {
          title: "What is the Alysei B2B Marketplace?",
          description: "The Marketplace for Italian Producers serves as a business hub where you can establish your online store, showcase your products, and connect with potential Importers, Restaurants, and Industry Experts. Transactions for the sale of your products to Importers are facilitated within this environment. In the future, the Marketplace will expand to include consumers, allowing for direct sales of your products to end consumers (B2C)."
        },
        {
          title: "Who are the Global Buyers?",
          description: "By 'Global Buyers,' we refer to FMCG buyers who are responsible for managing the procurement of goods for retailers and wholesalers. FMCG Buyers are professionals involved in the procurement of fast-moving consumer goods (FMCG), which are products that sell quickly at relatively low costs. These buyers are typically responsible for sourcing these goods for retailers and wholesalers, ensuring that their inventory meets consumer demand."
        },
        {
          title: "Is there a fee to register with Alysei?",
          description: "Registration with Alysei is entirely free of charge."
        },
        {
          title: "Are there various membership options available for Italian producers?",
          description: "Yes, there are four distinct membership plans available, including a Free Membership option. Each plan offers a range of benefits and advantages."
        },
        {
          title: "What is the process for registering my Company with Alysei?",
          description: 'You should click on "Join Now" and select your role.'
        },
        {
          title: "Who are the Voyagers?",
          description: "The Voyagers are Alysei users, individuals who engage with the platform to explore the world of Italian food and wine, as well as authentic, high-quality Made in Italy products. They have the opportunity to experience the essence of Italy, immersing themselves in a unique journey."
        },
        {
          title: "What is a Hub?",
          description: "The Hub refers to a geographical area centered around a metropolitan district, serving as the focal point for business, networking, and development activities."
        },
        {
          title: "What does it mean to be a member of a Hub?  ",
          description: "The Hub provides visibility within the Alysei Platform, which is crucial for fostering business collaborations and ensuring easy accessibility to your target market."
        },
        {
          title: "Who are the Alysei members in the B2B?",
          description: "Italian Food and Beverage Producers, U.S. Importers and Distributors, Italian Restaurants in the U.S., Industry Experts, Travel Agencies, and Global Buyers."
        },
      ],
      login_img_title : "A Bright Future for the Italian Gastronomy Export",
      login_img_subtitle: "Alysei is the premier B2B and B2C Platform designed to support the international expansion of Italian Food and Beverage companies, helping them grow their brands and businesses in overseas markets.",
      Log_InAlysei: "Log in to Alysei",
      enter_details : "Enter your details to login",
      new_Account: "Don't have an account?",
      create_Now: "Create Now",
      Sign_Up_to_Alysei: "Sign Up to Alysei",
      Become_an_Alysei_member: " Become an Alysei member by signing up for the Free Trial Beta version. Your access request will be subject to approval.",
      thank_you: "Thank you for Contacting Us",
      thakyou_subscribe: "Thank you for subscribing!",
      Full_name_required : "Il nome è obbligatorio",
      Last_name_required : "Il cognome è obbligatorio",
      Email_required : "L'email è obbligatoria",
       Enter_your_message : "Scrivi il tuo messaggio",
      valid_email_address: "Inserisci un indirizzo email valido",
      unsubscribe_We_sorry: "We're sorry to see you go!",
      unsubscribe: "You have successfully unsubscribed from our emails. We hope to see you again soon.",
      unsubscribe_more: "If you change your mind, you can always subscribe again anytime.",
      shipping_selection_No:`You have selected "NO" for shipping, which means no shipping services will be provided for this order. For further information or assistance, please contact the Producer directly.`,
      Pending_name:'Pending',
      Processing_name:'Processing',
      Hold_name:'Hold',
      Cancel_name:'Cancel',
      InTransit_name:'In Transit',
      Completed_name:'Completed'
    };

    const IT = {
      lan: "it",
      Name: " Nome",
      sendQuiry_success:"Thank you for your inquiry.Your message has been successfully sent to the ​Producer, who will get in touch with you shortly. In the meantime, feel free to continue browsing and shopping​ without selecting shipping at this time.",
     
     allStatus:'Tutti',
      subj:'Og.',
      Title: "Titolo",
      shipping_name:'Spedizione',
      Quantity_Range:'Quantità (min/max)',
      Destination_name:'Destinazione',
      Other_name:'Altro',
      Select_tax_Class: "Seleziona Classe Fiscale",
      orderPending:"L'ordine non è stato ancora accettato dal produttore",
      orderProcessing:"L'ordine è stato accettato dal produttore",
      orderHold:" L'ordine è stato messo 'in attesa' dal produttore",
      orderCancel:"Il produttore ha annullato l'ordine ",
      orderTransit:"L'ordine è in viaggio verso la destinazione finale",
      orderCompleted:"L'ordine è stato ricevuto dall'importatore",
      ProductType:'Prodotto',
      Timeleads:'Data',
      Createdat:'Creato il',
      BuyerName:'Nome Buyer',
      NoResult404:'Nessun dato trovato',    
      sPending:'In Sospeso',
      sResolved:'Risolti',
      orderPurchaseDate:'Data Acquisto',
      LastActivity:'Ultima Attività',
      Producer: "Produttore",
      EditAction:'Modifica',
      Contact_No:"Contatto",
      DeleteAction:'Elimina',
      ActiveAction:'Attivo',
      Order_id:'Ordine ID',
      InactiveAction:'In attivo',
      LastName: "Inserisci il cognome",
      MobNo: "Inserisci il numero di cellulare",
      Please_Enter_Email: "Inserisci l'indirizzo email.",
      ValidEmail: "Inserisci un indirizzo email valido",
      Password: "Inserisci la password.",
      ValidPass: "Conferma la password.",
      NewPass: "Inserisci una nuova password.",
      Address: "Inserisci l'indirizzo",
      Please_Select_Country: "Seleziona prima il Paese",
      State: "Seleziona prima lo Stato.",
      RecoveryEmail:
        "Un'email di recupero è stata inviata al tuo indirizzo email registrato",
      NewAndConfirmPassNotMatch:
        "La nuova password e la conferma password non corrispondono.",
      ValidMobNo: "Inserisci un numero valido.",
      Otp: "Inserisci l'OTP.",
      LocationEnable: "Posizione abilitata.",
      LocationNotEnable: "Posizione non abilitata.",
      SomeThingsWrong: "Qualcosa è andato storto. Si prega di riprovare.",
      NetworkError:
        "Impossibile connettersi ad Internet. Si prega di riprovare.",
      SessionExpire:
        "La tua sessione è scaduta. Per favore esegui nuovamente l'accesso.",
      LoctionNotEnable:
        "Posizione non abilitata. Si prega di attivare la posizione.",
      FillRequireInfo:
        "Si prega di compilare tutte le informazioni richieste. ",
      TermAndCondition: "Si prega di accettare Termini e Condizioni d'uso.",
      NumberAddedSuccess: "Numero aggiunto con successo.",
      EmailAddedSuccess: "Email aggiunta con successo. ",
      OtpSendSuccessfull: "OTP inviato con successo.",
      OptSendOnEmail: "OTP inviato alla tua email.",
      PasswordChangeSuccessfull: "Password cambiata con successo.",
      SourceType: "Scegli la fonte",
      TakePhoto: "Scatta una foto",
      FromLibrary: "Scegli dalla galleria",
      DeletePhoto: "Elimina foto",
      RemovePhoto: "Rimuovi foto",
      SixDigitOtp: "Inserisci l'OTP a 6 cifre.",
      FeatureNotAvailable: "Questa funzione non è disponibile.",
      ProfileUpdateSuccess: "Profilo aggiornato con successo.",
      UpLoadImage: "Carica l'immagine.",
      ChooseRoleId: "Scegli il ruolo.",
      ReasonToDecline: "Inserisci il motivo del rifiuto",
      SelectCookingSkill: "Seleziona l'abilità in cucina.",
      SelectCuisine: "Seleziona la Cucina. ",
      SelectMeal: "Seleziona il Pasto.",
      SelectCourse: "Seleziona la Portata.",
      SelectDiet: "Seleziona la Dieta.",
      SelectPrepationTime: "Seleziona il tempo di preparazione.",
      CookManyPeople: "Seleziona per quante persone stai cucinando.",
      SelectFood: "Seleziona intolleranza alimentare.",
      SelectRegion: "Seleziona la Regione.",
      EnterIngredientName: "Inserisci l'ingrediente.",
      SelectCategory: "Seleziona una categoria",
      Edit_desc: "Inserisci la descrizione.",
      Title: "Inserisci il titolo.",
      AddIngrdient: "Aggiungi gli ingredienti.",
      AddTool: "Aggiungi gli utensili.",
      SelectUnit: "Seleziona l'Unità",
      EnterQuantity: "Inserisci la Quantità",
      CharacterNumber: "Inserisci più di 3 caratteri.",
      DescriptionOrUrl: "Inserisci la descrizione o il sito web. ",
      LocationServiceFeature:
        "Non abbiamo accesso ai servizi di localizzazione sul tuo dispositivo. Vai alle impostazioni e abilita i servizi di localizzazione per utilizzare questa funzione",
      PassWordValidation:
        "La tua password deve contenere almeno 8 caratteri, 1 carattere speciale e 1 numero",
      FdaCirtificate: "Certificazione FDA",
      ConfigureStore: "Configura il tuo negozio online",
      StoreConnect:
        "Compila il modulo per ottenere una risposta più rapida. Il negozio ti contatterà a breve",
      NoProducer: "Nessun produttore trovato",
      PickUpOrDelivery: "Offri da Asporto e/o Consegna",
      MarketPlacePowerfullEng:
        "Il più potente motore Marketplace per il Made in Italy",
      FdaIconText:
        "Il proprietario, l'operatore o l'agente responsabile di una struttura nazionale o estera impegnata nella produzione/trasformazione, confezionamento o stoccaggio di alimenti per il consumo umano o animale negli Stati Uniti, deve registrarsi presso la FDA",
      AlertMsg: "Messaggio di avviso.",
      WantToLogout: "Sei sicuro di voler uscire?",
      Horeca: "Seleziona Horeca",
      PrivateLebel: "Seleziona Private Label.",
      AlyseiBrandLebel: "Seleziona Alysei Brand Label.",
      Term_Condition: "Accetta Termini e Condizioni D'Uso.",
      Delete: "Elimina",
      image_field_required: "L'immagine è richiesta",
      // marketPlace hint -
      ImporterInquiry: "Richiesta",
      Alysei_Marketplace: "Alysei Marketplace",
      Recently_Added_Product: "Prodotti più recenti",
      Newly_Added_Stores: "Negozi online più recenti",
      Quick_search_by_Italian_Regions: "Ricerca Rapida  per Regioni Italiane",
      Top_Rated_Products: "Prodotti più votati",
      Top_Favourite_Products: "I migliori prodotti",
      Product_Title: "Titolo*",
      Product_Type: "Tipo di Prodotto",
      Conservation_Method: "Metodo di Conservazione",
      Product_Properties: "Proprietà del Prodotto",
      Italian_Region: "Regione Italiana",
      Reset_Filter: "Rimuovi i Filtri",
      FDA_Certification: "Certificato FDA",
      Update_Review: "Aggiorna Recensione",
      Edit_review: "Modifica la recensione",
      Edit_your_review_and_ratings: "Modifica la tua recensione",
      Related_Products: "Prodotti correlati",
      Sample_Available: "Campioni disponibili",
      Dispatch_Instructions: "Informazioni sulla spedizione",
      Handling_Instructions: "Informazioni sulla gestione",
      Minimum_Order_Quantity: "Quantità di ordine minimo",
      Brand_Label: "Etichetta del prodotto",
      Quantity_Available: "Quantità disponibile",
      About_Product: "Informazioni sul prodotto",
      Imagetype: "Il formato dell'immagine deve essere JPEG, JPG, PNG",
      ImageSize: "L'immagine non può essere maggiore di 2MB",
      Go_back: "Torna al Marketplace",
      Go_back_Dashboard: "Torna alla dashboard",
      ImageMaxLen:
        "Scegli prima la foto principale del tuo negozio. Aggiungi poi più foto del negozio fino ad un massimo di 8.",
      Min_Order_Quantity: "Quantità minima d'ordine",
      Dispatch_Instruction: "Istruzioni per la spedizione.",
      Available_For_Sample: "Disponibile per Campioni",
      Product_Price: "Prezzo del prodotto",
      Min_Char: "dovrebbe essere un carattere minimo",
      is_required: "è richiesto",
      LessThan: "dovrebbe essere inferiore a",
      MinValue: "dovrebbe essere un valore minimo",
      LessThanOrEq: "dovrebbe essere minore o uguale a",
      LogoImage: "Seleziona il tuo logo",
      GalleryImage: "Seleziona le immagini dalla galleria",
      Crop_image: "Ritaglia l'immagine",
      Config_store: "Configura il tuo negozio online",
      NotAllowed: "Non sei autorizzato ad accedere a questa pagina",
      GoHome: "VAI ALLA HOMEPAGE",
      MyDashboard: "la mia scrivania",
      AddReviewAndRatings: "Aggiungi recensioni",
      EditReviewAndRatings: "Modifica la tua recensione",
      Select_package: "Iscrizione al Marketplace di Alysei",
      Alysei_B2B_Marketplace: "Mercato B2B di Alysei",
      Select_best_package: "Scegli il piano adatto a te",
      Thank_you:
        "Grazie per aver fornito le tue informazioni per la revisione da parte di Alysei",
      will_resp: "Ti risponderemo al più prestot",
      View_profile: "Visualizza profilo",
      Our_gallery: "La nostra galleria",
      About_store: "Il negozio",
      Store_products: "I prodotti del negozio",
      Products: "Prodotti",
      Select_fda_Certificate: "Seleziona",
      Ratings_and_reviews: "Ratings & Reviews",
      Add_review: "Aggiungi una Rating",
      Producer_Store: "Negozio del Produttore",
      View_all: "Vedi tutto",
      Search: "Cerca",
      Select: "Seleziona",
      Reviews: "Ratings",
      Edit_review: "Modifica recensione",
      close: "Chiudi",
      Categories: "Categorie",
      Location: "Posizione",
      stripeMsg:'Per iniziare ad accettare i pagamenti dei clienti tramite Stripe, clicca il pulsante qui sotto per connettere in modo sicuro il tuo account.',
      whatsStripeConnect:"Che cos'è Stripe Connect?",
      connectWithStripe:'Collega a Stripe',
      disconnectWithStripe:'Scollega il tuo account Stripe',
      call: "Chiama",
      noInqueries404:'Nessuna richiesta trovata',
      Website: "Sito Web",
      Disputes:'Controversie',
      RequestPlaceholder:'ID Richiesta',
      DisputesManagement:'Gestione Controversie',
      Direction: "Direction",
      Favourite: "Favoriti",
      Send_Inquiry: "Invia richiesta",
      See_All: "Vedi tutto",
      Accept: "Accetta",
      Decline: "Rifiuta",
      Decline_Request: "Rifiuta la Richiesta",
      Reason_to_Decline: "Motivi del rifiuto",
      Confirm: "Conferma",
      Invitations: "Inviti",
      Store_Name: "Nome del Negozio",
      Phone_number: "Telefono",
      PhoneNumber: "Numero di Telefono",
      Email: "Email",
      Store_region: "Regione del negozio",
      Photos: "Foto",
      Dashboard: "Pannello di controllo",
      Inquiries: "Richieste",
      Total_products: "Prodotti totali",
      Total_category: "Categorie totali",
      Total_reviews: "Recensioni totali",
      Analytics: "Statistica",
      Yearly: "Annuale",
      Monthly: "Mensile",
      Weekly: "Settimanale",
      Yesterday: "Ieri",
      Today: "Oggi",
      Add_new_product: "Aggiungi un nuovo prodotto",
      No_product_found: "Nessun prodotto trovato",
      Update: "Aggiorna",
      Add_gallery: "Aggiungi galleria",
      Total_inquiries: "Richieste totali",
      Add_photos: "Aggiungi foto",
      only_title: "Titolo",
      Keywords: "Parola chiave",
      Product_category: "Categoria Prodotto",
      Select_product_category: "Seleziona la categoria di prodotto",
      yes: "Sì",
      no: "No",
      Edit_prod_desc: "Descrizione del prodotto",
      Select_brandLebel: "Seleziona l'etichetta",
      New: "Nuove",
      Open: "Aperte",
      Closed: "Chiuse",
      opened: "Aperte",
      Description: " Descrizione",
      No_new_inquery: "Nessuna nuova richiesta ",
      No_closed_inquery: "Nessuna richiesta chiusa",
      only_product: "Prodotto",
      Product_listing: "Lista di prodotti",
      Sort_by: "Ordina per",
      AtoZ: "Dalla A alla Z",
      ZtoA: "Dalla Z alla A",
      Most_Rated_Stores: "I negozi più votati",
      Star_Stores: "Star Stores",
      Most_Searched: "Più Cercati",
      No_data_found: "Nessun dato trovato",
      No_review_found: "Nessuna recensione fino ad ora",
      Fast_response:
        "Compila il modulo per ottenere una risposta più rapida. Il Produttore Italiano ti contatterà a breve.",
      Full_name: "Nome completo",
      Message: "Messaggio",
      GO_TO_MARKETPLACE: "VAI AL MARKETPLACE",
      Showing: "Mostra",
      results: "risultati",
      Next: "Avanti",
      View: "Vedi",
      Connect: "Connettiti",
      Connected: "Connesso",
      Cancel: "Cancella",
      Requested: "Richiesto",
      Italian_F_and_B_producers: "Produttore enogastronomico Italiano",
      Importer_And_Distributor: "Importatori e Distributori",
      Importer_And_Distributor_Singular: "Importatore e Distributore",
      Voice_Of_Experts: "Esperti di Settore",
      Voice_Of_Expert: "Esperto di Settore",
      B2b_Voice_Of_Experts: "Esperti di Settori",
      Travel_Agencies: "Agenzie di Viaggio",
      Italian_Restaurants_in_US: "Ristoranti Italiani negli USA",
      Italian_F_and_B_producersB2B: "Produttori enogastronomici Italiani",
      Importer_And_DistributorB2B: "Importatori e Distributori",
      Travel_AgenciesB2B: "Agenzie di Viaggio",
      Italian_Restaurants_in_USB2B: "Ristoranti Italiani negli USA",
      Voyager: "Voyager",
      Hubs: "Hubs",
      Private_Label: "Marchio del distributore",
      Label: "Label",
      Hotel_Restaurant_Café: "Hotel/Ristorante/Café",
      HORECA: "HORECA",
      Products_sold_under_another_companys_brand:
        "Prodotti venduti con il marchio di un'altra azienda",
      Alysei_Brand_Label: "Marchio Alysei",
      Provide_pick_up_and_or_Delivery: "Offri da Asporto e/o Consegna",
      Pick_up: "Asporto",
      Delivery: "Consegna",
      United_State: "Stati Uniti",
      Italy: "Italia",
      Country: "Paese",
      Unsubscribe: "Annulla Iscrizione",
      Subscribe: "Iscriviti",
      Edit: "Modifica",
      HouseAndFlatNo: "No. civico/Piano/Palazzo",
      Enter_email: "Inserisci un'email",
      NoPlace_holder: "999-999-9999",
      Award: "Premio",
      Save: "Salva",
      Add: "Aggiungi",
      Image: "Immagine",
      Name_of_Competition_Event: "Nome della Competizione/Evento",
      Name_of_winning_Product: "PREMIO",
      Name_of_Modal_place_recived: "Medaglia/Podio",
      Select_Medal_Type: "Seleziona la medaglia",
      Select_Medal: "Seleziona la medaglia",
      Bronze: "Bronzo",
      Silver: "Argento",
      Gold: "Oro",
      Please_select_BlogTitle: "Inserisci il titolo del Blog",
      Please_block_Description: "Inserisci la descrizione del Blog.",
      Please_select_blog_status: "Seleziona lo stato del Blog.",
      Please_select_BlogImage: "Seleziona l'immagine del Blog.",
      Blog: "Blog",
      Create: "Crea",
      Status: "Stato",
      Event: "Evento",
      Event_View: "Visualizzazione eventi",
      View_Event: "Visualizza evento",
      name: "Nome",
      Date: "Data",
      Host: "Organizzatore",
      Time: "Ora",
      Type: "Tipo",
      Free: "Gratuito",
      Paid: "A Pagamento",
      buy_invitation: "Su Invito",
      price: "Prezzo",
      PriceType: "Seleziona il tipo di prezzo",
      currencyType: "Seleziona Moneta",
      Duration: "Durata",
      intensity: "Intensità",
      only_country: "Paese",
      Registration: "Registrazione",
      Select_Event: "Seleziona il tipo di evento",
      Currency_type: "Tipo di valuta",
      Adventure_Type: "Tipo di avventura",
      Adventure: "Avventura",
      Select_reg_type: "Seleziona il tipo di registrazione",
      Event_Registration_Type: "Registrazione",
      Public: "Pubblico",
      public: "Pubblico",
      Private: "Privato",
      Trip: "Viaggio",
      Trip_View: "Visualizza Viaggio",
      days: "Giorni",
      day: "Giorno",
      Euro: "Euro",
      USD: "Dollaro Statunitense",
      USA: "USA",
      Filter: "Filtra",
      Restaurant: "Ristorante",
      Restaurants: "Ristoranti",
      Booking_Url: "Sito Web per Prenotare",
      Host_Name: "Nome Organizzatore",
      Please_Enter_Event: "Inserisci un evento",
      PleaseSelectEvent: "Seleziona un evento",
      PleaseEnterHostName: "Inserisci il nome dell'organizzatore",
      Please_Enter_BookingUrl: "Inserisci il sito web per la prenotazione",
      Please_Enter: "Inserisci",
      Please_Select: "Seleziona",
      Trip_Name_Package_Name: "Nome del Viaggio/Pacchetto",
      Select_city_where_You_from_exported:
        "Selected City name where you from exported.",
      Below_Selected_state_where_You_from_exported:
        "Below are the selected state where you from exported City.",
      Selected_Hubs: "Seleziona l'HUB negli",
      Below_are_the_selected_hubs:
        "Di seguito sono riportati gli hub selezionati.",
      Travel_Agency: "Agenzia di Viaggio",
      Select_Country: "Seleziona Paese",
      Discovery: "Discovery",
      Alysei: "Alysei",
      Specialization: "Specializzazione",
      Region: "Regione",
      RegionFrom: "Regione Da",
      RegionTo: "Regione ",
      Read_More: "Leggi di più",
      Remove_all: "Elimina tutto",
      Do_not_recognize_hub: "Non trovi il tuo Hub?",
      Click_Here: "Clicca qui",
      Where_you_want_to_export:
        "Seleziona l'HUB negli USA dove vuoi sviluppare il tuo business",
      Choose_Your_Country: "L'Hub identifica una zona geografica sviluppata intorno ad un'area metropolitana, centro nevralgico di attività di business, network e sviluppo.",
      Login: "Accedi",
      Do_not_have_account: "Non hai ancora un account?",
      Verify_Otp: "Verifica OTP",
      only_password: "Password",
      ValidatePass:
        "Inserisci almeno un simbolo e un numero speciale nella tua password.",
      Facebook: "Facebook ",
      Alysei_Membership_Progress: "Status della Membership Alysei",
      Alysei_Membership_is_important_to_guarantee:
        "L'iscrizione ad Alysei è importante per garantire professionalità, qualità e visibilità",
      Your_membership_progress_is_pending_review:
        "La tua iscrizione è in attesa di revisione. Sarai notificato una volta iniziato il processo di revisione.",
      Alysei_Certified:
        "Benvenuto in Alysei! La tua richiesta è stata approvata. Per diventare un Membro Certificato e accedere alla piattaforma Alysei, completa il tuo profilo.",
      Profile_100_percent: "Congratulazioni! Ora sei un ",
      Certified_Alysei: "certificato Alysei",
      Latest_Notifications: "Ultime Notifiche",
      Your_Progress: "La mia iscrizione",
      only_address: "Indirizzo",
      NO_message: "Non ci sono messaggi.",
      only_Remove: "Rimuovi",
      No_new_chat: "Non ci sono nuove chat.",
      Image_with_text: "Aggiungi foto",
      upload: "Carica",
      Enter_your_text: "Inserisci il tuo testo qui...",
      Connections: "Connessioni",
      Remove_Connection: "rimuovere",
      No_connection_found_right: "Non hai connessioni al momento!",
      No_Invitations_found: "Non hai inviti al momento!",
      No_pending_request: 'Non hai inviti in attesa al momento!"',
      No_pending_request: 'Non hai inviti in attesa al momento!"',
      Following: "Seguiti",
      Followers: "Followers",
      only_Importer: "Importatore",
      only_Distributor: "Distributore",
      Unfollow: "Non seguire",
      Follow: "Seguire",
      Invitations: "Inviti",
      View_Request: "Vedi Richiesta",
      Network: "Network",
      Pending: "In Attesa",
      Block: "Blocca",
      UnBlock: "Sblocca",
      pieces: "no. di pezzi",
      bottles: "no. di bottiglie",
      liters: "Litri",
      kilograms: "kilogrammi",
      grams: "grammi",
      milligrams: "milligrammi",
      add_product: "Aggiungi Prodotto",
      Whats_new: "Cosa c’è di nuovo?",
      only_me: "Solo io",
      only_Post: "Post",
      only_delete: "Elimina",
      only_share: "Condividi",
      Report: "Segnala",
      Reply: "Rispondi",
      when_you_post_see_here: "Quando pubblichi qualcosa, apparirà qui.",
      my_connections: "Connessioni",
      sure_for_delete: "Sei sicuro di voler eliminare questo post?",
      ok: "Ok",
      // Connect: 'Collegare',
      Report_Post: "Segnala un'azione",
      Share_Post: "Condividi post",
      Remove_comment: "Rimuovi commento",
      Comment: "commento",
      write_feedback: "Write your feedback...",
      write_comments: "Scrivi un commento...",
      write_reply: "Rispondi...",
      not_post_yet: "Nessun post al momento!",
      Winning_Product: "Premio",
      Awards: "Premi",
      Trips: "Viaggi",
      NoAward: "Non ci sono premi al momento.",
      are_you_sure: "Sei sicuro?",
      Blogs: "Blogs",
      There_is_no_blogs_at_this_moment: "Non ci sono blogs al momento.",
      There_is_no_events_at_this_moment: "Non ci sono eventi al momento.",
      There_is_no_trips_at_this_moment: "Non ci sono viaggi al momento.",
      More_info: "Più Informazioni",
      Events: "Eventi",
      welcome_to_Alysei_world_you_will_be_notified_for_membership_status_update:
        " Benvenuto nel mondo Alysei! Riceverai una notifica per qualsiasi aggiornamento sullo status della tua Membership..",
      Change_Banner: "CAMBIA FOTO COPERTINA",
      Change_Profile_Photo: "Cambia la foto del profilo",
      Posts: "Post",
      User_Name: "Nome utente",
      User_Type: "Tipologia di utente",
      see_more: "...vedi di più",
      see_less: "Vedi di meno",
      only_about: "Chi sono",
      About_us: "Chi siamo",
      Contact: "Contatti",
      COMPLETE_YOUR_PROFILE_IN_ORDER_TO_START_POSTING:
        "Completa il tuo profilo per iniziare a navigare in Alysei",
      Go_to_profile: "Vai al tuo Profilo",
      there_is_no_photo_at_this_moment: "Non ci sono foto al momento.",
      Add_Award: "Aggiungi Premio",
      Add_Blogs: "Crea Blog",
      Settings: "Impostazioni",
      Add_Feature_Product: "Aggiungi Prodotti in Primo Piano",
      Nothing_added_yet: "Nulla di aggiunto ancora.",
      Update_Profile: "Aggiorna il profilo",
      Trip_Name: "Nome del viaggio",
      Trip_Price: "Prezzo del Viaggio",
      Cancel_Request: "Cancella la richiesta",
      Respond: "Respond",
      Accept_Request: "Accetta la richiesta",
      Delete_Request: "Elimina la richiesta",
      Connection_Request: "Richiesta di connessione",
      sending_request_with: "Invio richiesta di connessione con",
      Reason_to_connect: "Motivi della connessione",
      Send_Request: "Invia richiesta",
      IMAGE: "Immagine",
      TITLE: "Titolo",
      URL: "Sito web o URL",
      Required_Information: "*Informazioni Richieste",
      Sign_up: "Crea un nuovo account",
      already_account_with_us: "Hai già un account con noi?",
      Join__Alysei_Today: "Unisciti ad Alysei",
      Become_an_Alyesi_member_by_signing_up_for_the_Free_Trial_Beta_version:
        "Diventa un membro Alyesi iscrivendoti alla versione Beta in prova gratuita. La tua richiesta di accesso sarà soggetta ad approvazione.",
      Learn_More: "Per saperne di più",
      Blocking: "Blocca",
      Click_to_Unblock: "Clicca per sbloccare",
      Company: "Azienda",
      VAT_No: "Part.IVA",
      FDA_No: "Numero FDA",
      Photo_of_Label: "Foto dell'etichetta",
      Upload_an_image_of_your_product_label:
        "Carica una foto dell'etichetta del tuo prodotto.",
      Upload: "Carica",
      Uploaded: "Caricato",
      Fce_Sid_Certification: "Certificazione FCE-SID",
      Upload_an_image_of_your_FCE_SID_Certification:
        "Carica una foto del tuo certificato FCE-SID.",
      Phytosanitary_Certificate: "Certificato fitosanitario",
      Upload_an_image_of_your_Phytosanitary_Certificate:
        "Carica una foto del tuo certificato fitosanitario.",
      Packaging_For_Usa: "Packaging per gli USA",
      Upload_an_image_or_PDF_of_your_Packaging_For_Usa:
        "Carica una foto della tua confezione per gli USA.",
      Animal_Helath_Asl_Certificate:
        "Certificato di Salute Animale o Certificato ASL",
      Food_Safety_Plan: "Piano di Sicurezza Alimentare",
      Upload_image_or_PDF_of_your_Food_Safety_Plan:
        "Carica una foto del tuo piano di sicurezza alimentare.",
      Upload_image_or_PDF_of_your_your_Animal_Helath_or_ASL_Certificate:
        "Carica una foto del tuo certificato di salute animale o certificato ASL",
      Please_enter_the_Company_Name: "Inserisci il nome aziendale",
      Please_enter_the_Restaurant_Name: "Inserisci il nome del ristorante",
      Please_enter_the_VAT_no: "Please enter the VAT no.",
      It_consists_of_11_numerical_digit: "It consists of 11 numerical digits.",
      Please_select_FDA_certified_field: "Seleziona la tua certificazione FDA.",
      Please_select_product_type: "Seleziona il tipo di prodotto.",
      Please_select_at_least_one_conservation_method:
        "Seleziona almeno un metodo di conservazione.",
      Please_select_at_least_one_Properties: "Seleziona almeno una proprietà.",
      Please_select_at_least_one_speciality:
        "Please select at least one speciality",
      OTP_has_been_send_successfully: "L'OTP è stato inviato con successo.",
      Alysei: "Alysei",
      We_have_sent_you_a_6_digit_verification_code_OTP:
        "Ti abbiamo inviato un codice di verifica a 6 cifre (OTP) a",
      Verify_OTP: "Verifica OTP",
      Didnot_receive_the_OTP: "Non hai ricevuto l'OTP?",
      Resend_OTP: "Invia di nuovo l'OTP",
      Privacy: "Privacy",
      Membership: "Iscrizione",
      Subscription: "Sottoscrizione",
      Billing: "Fatturazioni",
      Your_Data: "I tuoi dati",
      FAQ: "Domande frequenti",
      Privacy_Policy: "Informativa sulla Privacy",
      Terms_and_Condition: "Termini e Condizioni d'Uso",
      Hub_select: "Seleziona l'Hub",
      Changing_your_password_will_log_you_off_any_other_devices:
        "La modifica della password ti disconnetterà da qualsiasi altro dispositivo.",
      Current_Password: "Password attuale",
      New_Password: "Nuova Password",
      validation_of_password:
        "La password deve contenere almeno 8 caratteri, una cifra numerica e un carattere speciale",
      Save_Changes: "Salva",
      WHO_CAN_SEND_YOU_A_PRIVATE_MESSAGE:
        "CHI PUÒ INVIARTI UN MESSAGGIO PRIVATO?",
      WHO_CAN_SEND_YOUR_PROFILE: "Chi può vedere il tuo profilo?",
      WHO_CAN_CONNECT_WITH_YOU: "CHI PUÒ CONNETTERSI CON TE?",
      Anyone: "Tutti",
      Just_me: "Solo io",
      Email_Preferences: "Preferenze Email",
      Private_messages: "Messaggi privati",
      When_someone_request_to_follow: "Quando qualcuno chiede di seguirti",
      Weekly_updates: "Aggiornamenti settimanali",
      General_Account_Settings: "Impostazioni generali dell'account",
      Resaurant: "Ristorante",
      only_Name: "Nome",
      Username: "Nome Utente",
      Language: "Lingua",
      English: "Inglese (US)",
      Italian: "Italiano (IT)",
      Select_option: "Seleziona l'opzione",
      Billing_Detail_comming_soon: "Dettagli di fatturazione in arrivo a breve",
      Billing_Details: "Dettagli di fatturazione",
      Billing: "Fatturazione",
      Orders: "Ordini",
      Orders_comming_soon: "Ordini in arrivo a breve",
      Account_Data_Download: "Scarica i dati del tuo account",
      Nothing_requested_yet: "Nessuna richiesta al momento .",
      Request_Data: "Richiedi i dati",
      All: "Tutti",
      People: "Users",
      Nothing_to_show: "Nulla da mostrare al momento",
      NoPeople: "Nessuna persona trovata",
      Enter_your_reason:
        "Il campo del motivo è obbligatorio, inserisci il motivo.",
      Connect_Request: "Richiesta di connessione",
      Sending_a_request_to: "Invio di una richiesta a",
      connect_with: "Connettiti con",
      Reason_to: "Motivo per",
      Send_Reason: "Invia il motivo",
      Select_Product_Type: "Seleziona il tipo di prodotto",
      SelectProductType:'Seleziona Prodotto',
      Continue: "Continua",
      Copyright: "© Copyright 2024 Alysei Corp | Tutti i diritti riservati |",
      Contact_us: "Contattaci",
      Terms_of_Use: "Condizioni d'uso",
      Instagram: "Instagram",
      Youtube: "Youtube",
      Linkedin: "Linkedin",
      Forget_password: "Hai dimenticato la password?",
      Enter_your_email_recovery:
        "Inserisci la tua email per recuperare la password",
      only_home: "Home",
      Reset_Password: "Reimposta la password",
      Confirm_Password: "Conferma Password",
      only_Submit: "Sottoscrivi",
      only_profile: "Profilo",
      Logout: "Esci",
      B2B: "B2B",
      Recipes: "Ricette",
      Recipe: "Ricetta",
      only_Marketplace: "Marketplace",
      Notifications: "Notifiche",
      Clear_All: "Elimina Tutto",
      no_notifications: "Non ci sono notifiche.",
      Minimun_8: "Minimo 8",
      digit_1: "1 numero",
      special_1: "1 carattere speciale",
      search_components: "componenti di ricerca",
      Loading: "Caricamento...",
      General_Information: "Informazioni Generali",
      only_select: "Seleziona",
      meal: "Pasto",
      Cooking_Skills: "Abilità in Cucina",
      Cuisine: "Cucina",
      Diets: "Dieta",
      Food_Introlerance: "Intolleranza alimentare",
      Prepration_Time: "Tempo di preparazione",
      Hours: "Ore",
      Minutes: "Minuti",
      Serving: "Dosi",
      For_how_much_people_you_are_cooking: "(Dosi) Per quante persone.",
      Select_Region: "Seleziona la Regione",
      Change_Photo: "Cambia Foto",
      Upload_Recipe_Image: "Carica la foto della ricetta",
      click_here_to_upload_recipe_image:
        "Clicca qui per caricare l'immagine della ricetta",
      IMAGES: "Immagine",
      PNG_JPG: "PNG,JPG",
      Resolution: "Risoluzione",
      Upto_1600_1600: "Max 1600x1600",
      Back: "Indietro",
      Quantity: "Quantità",
      Unit: "Unità",
      kg: "kg",
      litre: "Litro",
      only_pieces: "Pezzi",
      dozen: "Dozzina",
      gm: "gr",
      ml: "ml",
      spoon: "Cucchiaino",
      drops: "Gocce",
      Envelope: "Bustina",
      Ingredients_List: "Lista degli ingredienti",
      Ingredients: "Ingredienti",
      Used: "Utilizzati",
      Add_Ingredients_used_in_Recipe:
        "Aggiungi gli ingredienti utilizzati nella ricetta",
      Step: "Step",
      Steps: "Steps",
      Tools_used_in_Step: "Utensili utilizzati in questo step",
      please_Write_Reviews: "Aggiungi Recensione",
      please_Write_Rating: "Aggiungi Recensione",
      only_Rating: "Valutazione",
      Networking: "Networking",
      There_are_no_ingredients: "Non ci sono ingredienti.",
      There_are_no_tools: "There are no selected tools.",
      Please_select_quantity_or_ingredients:
        "Seleziona la quantità o gli ingredienti.",
      Please_select_quantity_or_unit:
        "Seleziona l'unità di misura e la quantità",
      please_write: "Scrivi",
      title_required: "Il titolo è obbligatorio.",
      subject_required: "L'argomento è obbligatorio.",
      message_required: "Il messaggio è obbligatorio.",
      Please_Add_Recipe_Steps: "Aggiungi una Fase",
      Add_Recipe_Steps: "Aggiungi Steps",
      Recipe_Steps: "Passaggi della Ricetta",
      RecipeSteps: "Steps",
      please_select_at_one_cooking_tool:
        "Seleziona almeno un utensile da cucina",
      Add_Utencils_Tools_used_in_recipe:
        "Aggiungi Utensili ed Elettrodomestici",
      Utensils: "Utensili",
      Save_in_draft: "Salvata in Bozze",
      Page_Reload_message: "Changes you made may not be saved.",
      Finish: "Fine",
      Tools_Utencils_Used: "Strumenti e utensili usati",
      Mins: "Minuti",
      Hr: "Ore",
      Likes: "Mi Piace",
      Quick_search_by: "Ricerca Rapida per",
      Trending_Now: "In Voga al momento",
      Quick_Easy: "Facile e Veloce",
      Update_preferences: "Aggiorna le preferenze",
      Add_More: "Aggiungi altro",
      Published: "Pubblicata",
      Draft: "Bozza",
      What_are_you_craving: "Cosa desideri?",
      Personalize_your_experience: "Personalizza la tua esperienza",
      what_is_your_favorite_cuisines: "Qual è la tua cucina preferita?",
      Do_you_have_any_food_allergies: "Hai qualche allergia alimentare?",
      Do_you_follow_these_diets: "Segui una di queste \n diete?",
      Do_not_want_to_see_ingredients: "Quali ingredienti non vuoi vedere?",
      The_Alysei_Recipe_Tool_is_a_world_of_good:
        "L' Alysei Recipe Tool è un mondo di ricette italiane buone e salutari che puoi esplorare, creare e condividere in modo facile e divertente. Goditi l'Italia e i suoi sapori!",
      Explore: "Esplora",
      MyRecipes: "Le Mie Ricette",
      MyPreferences: "Le Mie Preferenze",
      You_may_also_like: "Potrebbe piacerti anche",
      Recipeby: "Ricetta di",
      Start_Cooking: "Inizia la preparazione",
      Suggested: "Suggerito",
      MyFavouriteRecipe: "I Miei Favoriti Ricette",
      Meals: "Pietanza",
      Please_fill_Url_or_Description_field:
        "Si prega di compilare il campo URL o Descrizione.",
      Please_fill_title: "Si prega di compilare il campo del titolo",
      Italian: "Italian",
      Create_Recipe: "Crea la tua Ricetta",
      Edit_Recipe: "Modifica la Ricetta",
      Leave_a_comment: "Lascia un Commento",
      ShareRecipe: "Condividi la Ricetta",
      Go_To_Store: "Vai al mio negozio online",
      Create_You_Store: "Crea il tuo negozio online",
      Please_Enter_email_and_pass: "Inserisci il tuo nome utente e la password",
      Please_select_atleast_one_ingredients: "Seleziona almeno un ingrediente",
      Please_select_atleast_one_tool: "Please select at least one tool",
      Skip: "Salta",
      Suggested_Connections: "Collegamenti suggeriti",
      No_Connection_Found: "Nessuna connessione trovata",
      Please_Select_At_Least_one_field: "Seleziona almeno un campo.",
      Create_Your_Own_modern:
        "Crea il tuo negozio online moderno e professionale",
      Create_Your_Product_List: "Crea il tuo portfolio prodotti",
      Display_Your_Unique_Products_Listing: "Mostra i tuoi prodotti in vetrina",
      Respond_To_Buyers_Inquiry: "Rispondi alle richieste degli acquirenti",
      Interact_With_Your_Future_Buyers:
        "Interagisci con i tuoi possibili acquirenti",
      Favorite_and_Ratings: "Valutazioni",
      Being_Able_To_Be_Reviewed_And_Rated_By_Buryers:
        "Poter essere recensito e valutato dagli acquirenti",
      Comming_Soon: "Prossimamente…",
      Boost_your_listing: "Potenzia le tue iserzioni",
      Boost_your_listing_to_expand_you_and_reach:
        "Potenzia la tua offerta per raggiungere i tuoi acquirenti",
      Sell_online: "Vendi online",
      Sell_Your_Product_online_To_Your_Buyers:
        "Vendi i tuoi prodotti online ai tuoi acquirenti",
      B2C_Grand_Opening: "Apertura del Marketplace ai Consumatori",
      Alysei_Full_Opening_To_Market_Buyers_And_Consumers:
        "Apertura completa al Mercato, agli Acquirenti ed ai Consumatori",
      Free_MemberShip: "Iscrizione gratuita",
      RestaurantType: "Seleziona il tipo di ristorante",
      Restaurant_SearchBar_Type: "Tipologia di ristorante",
      Event_Description: "descrizione dell'eventon",
      View_blog: "Visualizza blog",
      Give_Your_FeedBack: "Dacci il tuo feedback",
      Send_Your_FeedBack_Here: "Invia il tuo feedback qui...",
      Rating_Is_Require: "La valutazione è richiesta",
      Review_Is_Require: "La revisione è obbligatoria",
      Review_Must_Be_Greater_Than_Three_Character:
        "La recensione deve essere maggiore di 3 caratteri",
      Thank_You_For_Your_Review: "Grazie per la tua opinione",
      What_Is_Your_View: "qual è il tuo punto di vista?",
      product_keywords: "Nome del Prodotto",
      MarketPlaceMainHeading:
        "Esplora il più potente mercato online italiano di enogastronomia alla ricerca dei migliori prodotti di alta qualità accuratamente selezionati.I produttori italiani possono creare la propria vetrina online, caricare il proprio portfolio di prodotti, esplorare, ricercare, creare connessioni e rispondere alle richieste di altri membri Alysei. I Voyagers Alysei possono accedere per ricercare prodotti e produttori alla scoperta della storia, cultura e tradizioni della nostra immensa cucina italiana.",
      Search_for_recipes: "Cerca ricette",
      Winner: "Vincitore",
      search_your_address: "Cerca il tuo indirizzo",
      Apply: "Applica",
      write_message: "Scrivi un messaggio...",
      selectCities: "Seleziona le città",
      selectHubs: "Seleziona gli Hubs",
      selectedHubs: "Hubs selezionati",
      selectState: "Seleziona gli stati degli Stati Uniti in cui ti trovi",
      noHubsFound: "Nessun hub trovato",
      onlyOneCity: "Puoi selezionare una sola città",
      onlyOneState: "È possibile selezionare solo uno Stato",
      IngredientsUsed: "Ingredienti usati in questo step ",
      helps_user_to_find_product: "Aiuta l'utente a trovare il tuo prodotto",
      quanity_available:
        "Quanti prodotti sei attualmente disponibile a consegnare?",
      Minimum_Order_Quantity_tooltip:
        "Qual è la quantità minima d'ordine che puoi accettare?",

      deletedSharePostHeading: "Questo contenuto non è disponibile al momento",
      deletedSharePostMessage:
        "quando ciò accade, di solito è perché il proprietario lo ha condiviso solo con un piccolo gruppo di persone, ha cambiato chi può vederlo o è stato eliminato.",
      chooseHubOrStates: "È necessario scegliere hub o stati",
      enterPhoneNo: "Si prega di inserire il numero di telefono",
      numericalDigit:
        "È composto da un minimo di 8 o un massimo di 10 cifre numeriche",
      enterAddress: "Si prega di inserire l'indirizzo",
      enterWebsiteLink: "Si prega di inserire il collegamento al sito web",
      enterEvent: "Si prega di inserire la competizione/evento",
      enterWinningProduct: "Inserisci il prodotto vincente",
      enterMedalName: "Seleziona il nome della medaglia",
      enterImage: "Si prega di selezionare l'immagine",
      imageType: "Il tipo di immagine deve essere JPEG, JPG, PNG",
      selectImageSize:
        "La dimensione dell'immagine non deve essere superiore a 2 MB",
      disputeImageSize: "Image size should not be more than 1 MB",
      selectImageSizeLessThan10:
        "La dimensione dell'immagine deve essere inferiore a 5 MB",
      selectImageSizeLessThan5: "Le immagini devono essere inferiori a 5",
      selectBannerImage: "Seleziona l'immagine del banner",
      selectLogoImage: "Si prega di selezionare l'immagine del logo",
      selectGalleryImage: "Si prega di selezionare le immagini della galleria",
      selectLogoBannerGalleryToChange:
        "Seleziona il logo, il banner o la galleria da modificare",
      addPhotoMax10:
        "Foto 0/10 Scegli prima la foto principale del tuo negozio. Aggiungi più foto del tuo negozio max 10 consentite.",
      enterMessageField:
        "Il campo del messaggio è obbligatorio. Inserisci il tuo messaggio",
      enterTextBeforePost: "Si prega di aggiungere del testo prima del post",
      enterMaxCharacter: "Immettere massimo 300 caratteri",
      enterRecipeName: "Si prega di inserire il nome della ricetta",
      selectRecipeMeal: "Si prega di selezionare la ricetta del pasto.",
      selectRecipeCourse: "Si prega di selezionare il corso ricetta.",
      selectRecipeCooking:
        "Si prega di selezionare le abilità di cucina della ricetta.",
      selectRecipeCuisine: "Si prega di selezionare la cucina della ricetta.",
      selectRecipeServing:
        "Si prega di selezionare la preparazione della ricetta che serve.",
      selectRecipeRegion: "Si prega di selezionare la regione della ricetta.",
      selectRecipeImage: "Si prega di selezionare l'immagine della ricetta.",
      selectOneState: "È possibile selezionare un solo stato",
      provideSampleOfProduct:
        "Siete in grado di fornire campioni dei vostri prodotti su richiesta",
      productSellingPrice:
        "Indica il prezzo di vendita del tuo prodotto ai possibili acquirenti",
      detailAboutSpecificDispatch:"Fornisci istruzioni per la spedizione",
      invalidEmail: "ID e-mail non valido",
      invalidOTP: "Numero OTP non valido",
      discard: "Elimina",
      Add_Featured: "Aggiungi In Primo Piano",
      clove: "spicchio",
      tablespoon: "cucchiaio",
      pinch: "pizzico",
      shot: "Bicchierino",
      asNeeded: "q.b.",
      glass: "Bicchiere",
      cup: "Tazza",
      review: "Rating",
      reviews: "Ratings",
      Likes: "Mi Piace",
      Like: "Mi Piace",
      remobeAllHubsMessageTop:
        "Questa azione eliminerà tutti gli Hubs che hai selezionato",
      remobeAllHubsMessageMiddle:
        "Sei sicuro di voler eliminare tutti gli Hubs?",
      Expertise: "Competenza",
      SelectState: "Seleziona lo Stato",
      Speciality: "Specialità",
      KeywordSearch: "Ricerca Keyword",
      EditPost: "Modifica Post",
      select: "Seleziona",
      search_messages: "Cerca Messaggi",
      properties: "Proprietà",
      add_package: "Aggiungi Pacchetto",
      Add_Menu: "Aggiungi Menù",
      product_title: "Titolo del prodotto*",
      product_price: "Prezzo del prodotto",
      my_store: "Il Mio Negozio",
      closed_inquery: "Chiudi richiesta",
      how_choose_plan: "Scegli come intendi vendere i tuoi prodotti",
      Provide_details_about_your_safe_handling_instructions:
        "Fornisci istruzioni per la gestione",
      Enter_Title_for_Step: "Inserisci il Titolo del step",
      Your_recipe_direction_text_here:
        "Scrivi qui le indicazioni della Ricetta...",
      Search_Ingredients: "Ricerca gli Ingredienti",
      Search_Tools: "Strumenti di ricerca",
      No_Recipe_Found: "Nessuna Ricetta trovata",
      recipe_tool: "Mondo Ricette",
      Store_Image_Heading: "Aggiungi foto Negozio",
      gallery_images: "foto",
      coverImageSuccessMessage: "Immagine di copertina caricata correttamente",
      profileImageSuccessMessage: "Immagine del profilo caricata correttamente",
      add_a: "Aggiungi una",
      Report_Problem: "Seleziona il problema con il post",
      Nudity: "Nudo",
      Violence: "Violenza",
      Harassment: "Molestie",
      Suicide: "Suicidio o Autolesionismo",
      False_Info: "False informazioni",
      Others: "Altro",
      Write_Reason: "Scrivi un motivo",
      One_Reason_Choose: "Scegli almeno un motivo",
      Utencils: "Utensili",
      Recommend_Size_900_900: "Taglia consigliata 900 X 900",
      Singular_Hub: "Hub",
      coverImageWidthInvalidHead: "Please choose a different cover photo",
      coverImageWidthInvalidMessage:
        "This cover photo is too small. Please choose a cover photo with larger width.",
      pallet: "Pallet",
      visibility: "Visibilità",
      select_all: "Select All",
      unselect_all: "Unselect All",
      change_password_text: "Inserisci la tua password attuale per continuare.",
      change_email: "Cambia Email",
      enter_new_email_text: "Inserisci la tua nuova email",
      confirm_your_email: "conferma la tua email",
      confirm_email_mismatched: "Conferma che l'email non corrisponde",
      change_email_text:
        "Inserisci la tua nuova email da associare a questo account.",
      EMAIL_VISIBILITY: "Email Visibile",
      PHONE_NUMBER_VISIBILITY: "Numero Visibile",
      ADDRESS_VISIBILITY: "Indirizzo Visibile",
      Save_Proceed: "Salva e Procedi",
      Please_select_brand_label: "Seleziona l'etichetta del marchio",
      BLOCK_USERS: "Vuoi bloccare questo utente?",
      YOU_WANT_TO_BLOCK_USERS: "Sei sicuro di voler bloccare questo utente?",
      BLOCK_USERS_HINT: " Bloccando questo utente, non sarete più connessi.",
      PLEASE_SELECT: "Selezionare",
      TO_BLOCK_USER: "per bloccare e inviare",
      BLOCK_USERS_HINT_AND_MESSAGES:
        "Bloccando questo utente, non sarai più connesso con lui e non sarai in grado di vedere alcuna attività",
      SPAM_FOLDER: "L' OTP può essere nella cartella spam",
      REALY_WANT_TO_UNBLOCK_USER: "Sei sicuro di voler sbloccare questo utente",
      SUPPORT_ACTION: "Azioni di supporto",

      // new dashboard for producer...
      Recent_orders: "Ultimi Ordini",
      Order_id: "Ordine ID",
      Customer_Name: "Nome Cliente",
      Total: "Totale",
      Date: "Data",
      Action: "Azione",
      // View: "View",
      Search_product_by_name: "Search product  by name or its ID",
      Select_category: "Seleziona la categoria",
      Select_stock: "Stock",
      Active: "Active",
      Inactive: "Inactive",
      Image: "Immagine",
      Order_management: "Gestione Ordini",
      On_hold: "On_hold",
      New_orders: "Nuovi Ordini",
      Ongoing: "In corso",
      Past_orders: "Ordini Precedenti",
      Invoice: "Invoice ",
      Tax_management: "Gestione Fiscale",
      Tax_classes: "Classe Fiscale",
      Tax_class: "Classe Fiscale ",
      Tax_class_data_tip:'Seleziona la classe fiscale da applicare. Per maggiori informazioni, vai alla sezione Gestione Fiscale nel tuo pannello di controllo.',
      
      Tax_rate: "Aliquota Fiscale ",
      Tax_class_title: "Nome Classe Fiscale",
      Edit_tax_class: "Edit tax class",
      Confirm_Delete: "Confirm Delete ",
      Tax_class_notes:
        "Please note: This tax class has been used in 5 product.Are you sure you want to delete this tax class?",
      Taxes_type: "Tipo di Tassa",
      Tax_name: "Nome della Tassa",
      Select_tax_type: "Seleziona il tipo di Tassa",
      Download_invoice: "Download invoice",
      Print: "Print",
      Order_details: "Order details",
      My_order_view: "My order view",
      Order_on: "Order on",
      Billing_address: "Billing Address",
      Shipping_address: "Shipping Address",
      payment_method: "Payment Method",
      Order_summary: "Order summary",
      Grand_total: "Grand total",
      Shipping_charge: "Shipping Charge",
      Tax: "Tax",
      Unit_price: "Unit Price",
      Items_subtotal: "Items subtotal ",
      Product_category: "Categoria Prodotto",
      Sr_no: "N° di Serie",
      Customer_name: "Customer name ",
      Cancellation: "Cancellation",
      In_transit: "In transit",
      Completed: "Completed",
      Processing: "Processing",
      Pending: "Pending",
      On_hold: "On hold",
      Upload_invoice: "Upload invoice  ",
      Transaction_management: "Gestione Transazioni",
      Date_to: "Al",
      selected_date_to:'Seleziona la data',
      selected_date_from:'Seleziona la data',
      Generate_report: "Generate report",
      Transaction_id: "Transaction ID",
      Amount: "Importo Totale",
      Payment_status: "Payment Status",
      Order_status: "Order status",
      Date_from: "Dal",
      Succeeded: "Succeeded",
      Request_for_payment: "Request for payment",
      Requires_payment_method: "Requires payment method",
      Generate_report: "Generate report",
      Date_and_time: "Date and time",
      Transaction_details: "Transaction details",
      Order_completed: "Ordine Concluso",
      last_order_date: "Data Ultimo Ordine",
      Customer_management: "Gestione Clienti",
      Offer_management: "Gestione Offerte",
      Ending_date: "Data Fine",
      Importer_name: "Nome Importatore",
      Offer_title: "Titolo Offerta",
      Offfer_id: "ID Offerta",
      Send_offer: "Invia Offerta",
      Search_for_importer: "Cerca Importatore",
      Add_more: "Aggiungi Altro",
      Includes_shipping_charges: "Spese di spedizione incluse",
      Shipping_price: "Costo Spedizione",
      Important_notes: "Note Importanti",
      Incoterms: "Incoterms",
      Payment: "Pagamento",
      Mode_of_Payment: "Mode of Payment",
      general: "General",
      Paypal: "Paypal",
      Bank_wire_transfer: "Bank wire transfer",
      Account_Holder_Name: "Account Holder Name",
      Swift_Code: "Swift Code",
      Bank_Name: "Bank Name",
      Account_number: "Account number",
      Recommended_retail_price: "Prezzo di Vendita Consigliato ",
      Other: "Other",
      Stock: "Stock ",
      Sort_by_A: "Sort by A-Z ",
      Alysei_marketplace: "Alysei marketplace",
      // new dashboard for importer...
      My_orders: "My Orders",
      Invoice_not_ready_to_download: "Invoice not ready to download",
      Shipping_address: "Shipping Address",
      Billing_address: "Billing Address",
      Add_billing_address: "Add billing address",
      Delete_address_msg: "Are you sure you want to delete this address?",
      Notes_Address_delete:
        "Please note: Deleting this address will not delete any pending orders being shipped to this address. To ensure uninterrupted fullfillment of future orders, please update any wishlists, subscribe and save setting and periodical subscriptions using this Address.",
      Add_shipping_details: "Add shipping details",
      Select_an_address: "Select an address",
      Add_billing_details: "Add billing details",
      More_details: "More details",
      add_to_cart: "Add to cart",
      go_to_cart: "Go To Cart",
      My_wishlist: "My wishlist ",
      View_offers: "View Offers",
      Note: "Note",
      My_offers: "My Offers",
      Empty_cart: "Your cart is empty!",
      Empty_cart_text:
        " Looks like you haven’t added anything to your cart yet",
      Go_to_shop: "Go to shop",
      Select_Payment_Method: "Select Payment Method",
      Checkout: "Checkout",
      Expiry: "expiry",
      Order_total: "Order total",
      View_cart: "View Cart",
      Continue_to_pay: "Continue to pay",
      Payment_success: "Payment Successful!",
      Payment_msg: "Your order has been successfully processed.",
      Page_refresh: "Please do not refresh page",
      Payment_is_in_progress: "payment is in progress.",
      Cart_msg:
        "At a time you can add either a product including offer or a product without offer.Do you want to add this product. Are you sure?",
      Clear_cart: "Clear cart",
      Continue_shopping: "Continue shopping",
      Offer_exp: "Offer has been Expired.",
      Offer_userd: "You have already used this offer.",
      Different_store_product:
        "Currently you have items in your cart from another store.Would you like to clear the cart and add product of",
      Fields_req: "Please fill this field",
      Select_imp: "Seleziona Importatore",
      Saving: "Saving",
      Send: "Invia",
      Select_a_date: "Seleziona data",
      Select_incoterms: "Select Incoterms",
      Incoterm_text: "Destinazione",
      Select_product: "Seleziona Prodotto",
      Per_unit_rice: "Prezzo Unitario",
      Go_back: "Go back",
      N_A: "N/A",
      Offer_name: "Offer Name",
      Online: "Online",
      Online: "Online",
      Item: "Item",
      Vat: "Vat",
      Emails: "Email",
      Subtotal: "Subtotal",
      Customer: "Customer",
      Only_address: "Address",
      Shipment_info: "Shipment Info",
      Shipping_name: "Shipping Name",
      Shipment_id: "Shipment ID",
      Shipment_url: "Shipment URL",
      My_Address: "My Address",
      completed: "completed",
      Taxes_rate: "Aliquota Fiscale",
      Taxes_rate: "Aliquota Fiscale",
      Please_note: "Please note",
      Confirm_msg: "Are you sure you want to delete this",
      onlyName: "Nome",
      Reset_filter: "Reset Filter",
      Requesting: "Requesting",
      Requested: "Requested",
      Not_requested: "Not Requested",
      Pennding: "Pending",
      Category: "Categoria",
      Search_Product_By_Id: "Cerca Prodotto per nome o per ID",
      RRP: "PVC",
      Date_time: "Date & Time",
      In_stock: "Disponibile",
      out_stock: "Non Disponibile",
      Cancelled: "Cancelled",
      Select_hubs_for_restrict_connection:
        "WHO CAN NOT SEND YOU CONNECTION REQUEST BY HUB'S.",
      Enter_location: "Enter location",
      Tel: "Tel",
      My_Address: "My Address",
      Company_name: "Company Name",
      Total_price: "Total Price",
      Set_payment_option: "Set this as default payment option to get payment",
      Paypal_id: "Paypal Id",
      Option_require: "This field is required.",
      Shopping_cart: "Shopping Cart",
      new_Leads:'Nuovi Lead',
      minimum_product_qty: "Minimum order quantity of this product is",
      less_or_equal_product_qty: "Product should be less than or equal",
      num_of_qty: "no of quantity",
      Add_reflected_in_the_bill: "Add you want to be reflected in the bill.",
      Clear: "Clear",
      Confirm_remove: "Confirm Remove",
      Confirm_clear: "Confirm Clear",
      Are_you_sure_to_do: "Are you sure you want to remove this",
      Are_you_sure_to_clear_cart: "Are you sure you want to clear cart",
      Address_delete_msg:
        "Deleting this address will not delete any pending orders being shipped to this address",
      Set_shipping_details: "Set shipping details same as billing details.",
      New_address_added: "Add New Address",
      Payment_warning: "Please do not refresh page payment is in progress.",
      Billing_address_warning: "Please add billing details.",
      Shipping_address_warning: "Please add shipping details.",
      Order_errors: "Something went wrong with this order. Please try later.",
      Tax_class_create_successfully: "Tax class has been deleted successfully.",
      Tax_create_successfully: "Tax has been deleted successfully.",
      Offer_id: "Offer ID",
      Starting_date: "Data Inizio",
      No_offer: "Nessuna offerta trovata",
      Offer: "Offerta",
      Fixed: "Fixed",
      Percentage: "Percentage",
      Customer_details: "Customer Details",
      Customer_contact: "Customer Contact",
      Customer_email: "Customer Email",
      Customer_address: "Customer Address",
      Joining_date: "Joining Date",
      Total_number_of_order_completed: "Total Number of Orders Completed",
      Total_number_of_order_canceled: "Total Number of Orders Cancelled",
      Sort_By_Z_A: "Sort By Z-A",
      Email_id: "Email",
      Support_24: "Supporto online",
      Continuous_support: "Supporto tecnico tramite Alysei Live Chat",
      Manage_your_customers: "Manage your Customers",
      Customize_and_manage_your_customers:
        "Customize and manage your customers.",
      Online_payment_integration:
        "Online payment integration for smooth checkouts.",
      Alysei_will_charge:
        "La commissione Alysei per ogni transazione è pari al 10%.",
      Alysei_commission_info:
        "La commissione del 10% di Alysei non comprende fees di terze parti",
      Active_user: "Active",
      Inactive_user: "Inactive",
      charge_10_percentage:"Alysei trattiene una commissione del 10% sul prezzo indicato",
      You_earned: "Importo Ricevuto",
      You_earned_info:
        "L’importo include la commissione Alysei e le fees di Stripe.",
      RRP_info:
        "Il prezzo al dettaglio consigliato (PVC) è il prezzo suggerito dal produttore al quale il prodotto dovrebbe essere venduto",
      Tax_Class_info:
        "Una classe fiscale è una raccolta di aliquote fiscali. Le classi fiscali determinano le aliquote fiscali applicabili.",
      Tax_Rate_info:
        "Un’aliquota fiscale è la percentuale con cui le tasse vengono pagate a un governo.",
      Order_management_info: "",
      badge_Text_For_Stripe:
        "Per vendere i tuoi prodotti su Alysei e ricevere pagamenti, connetti il tuo account Stripe. Per maggiori informazioni,",
      Tax_Class_Product:
        "Scegli il tipo di tassa da applicare.Per maggiori info vai alla sezione Tax Management sul tuo dashboard.",
       // new landing
       Welcome: "Benvenuti in Alysei",
       A_Bright_future : "Un futuro promettente per l'Export della Gastronomia Italiana",
      Alysei_is_the_premier_B2B: "Alysei è la prima piattaforma B2B e B2C dedicata a potenziare l’internazionalizzazione delle eccellenze italiane nel settore enogastronomico. Attraverso strumenti avanzati e strategie mirate, Alysei accompagna le imprese nella valorizzazione del brand e nell’espansione commerciale sui mercati internazionali",
      About_Alysei: "CHI SIAMO - ALYSEI",
      Set_your_course: "Traccia la tua rotta seguendo le stelle, non le luci delle navi di passaggio",
      Alysei_was_conceived_L: "Alysei ha avuto origine in una piccola caffetteria a Lake Geneva, nel Wisconsin, guidato da una passione condivisa e da un’ambiziosa visione di nuove opportunità di mercato. Da questa intuizione è nata una piattaforma innovativa, oggi una realtà di successo.",
      Alysei_is_the_first_B2B: "Alysei è il primo portale B2B e B2C dedicato alla valorizzazione dei prodotti italiani di alta qualità nel settore enogastronomico. Progettata come una piattaforma social esclusiva, Alysei riunisce un pubblico selezionato di appassionati del Made in Italy, offrendo un ambiente privilegiato per connessioni significative e per la scoperta di eccellenze gastronomiche. Gli utenti hanno l’opportunità di esplorare, condividere esperienze e valorizzare l’autenticità dei prodotti italiani attraverso interazioni mirate e innovative.",
      Our_platform_aims: "La nostra piattaforma punta a raggiungere il mercato statunitense, con un focus particolare su chi coltiva una profonda passione per la cultura e la tradizione culinaria italiana.",
      Qualified_and_Certified: "I Produttori italiani qualificati e certificati che scelgono Alysei avranno accesso a strumenti strategici ideati per facilitare la loro crescita nel mercato americano. Tra i principali vantaggi, Alysei offre la possibilità di connettersi con Importatori e Distributori selezionati, creare negozi personalizzati nel Marketplace B2B, e consolidare la propria presenza sul mercato. I Produttori potranno inoltre promuovere i propri prodotti e attivare campagne marketing e commerciali mirate, progettate per ampliare la visibilità e sostenere il successo nel mercato statunitense.",
      In_addition_to_Italian_Producers: "L’ecosistema di Alysei si estende oltre i Produttori, includendo Membri Certificati quali Ristoranti Italiani, Importatori, Distributori, Esperti di Settore, Chefs e Agenzie di Viaggio specializzate. Questa rete dinamica contribuisce a una comunità che celebra l’eccellenza italiana e promuove la nostra cultura enogastronomica nel mondo.",
      Our_Mission: "MISSIONE",
      You_can_never_cross: "Non potrai mai attraversare l'oceano se non hai il coraggio di lasciare la costa alle spalle",
      Alysei_bridges_the_gap: "Alysei colma il divario tra tradizione e innovazione, passato e futuro, offrendo ai Produttori italiani di alta qualità l’opportunità di espandere le proprie attività nel mercato statunitense. ",
      While_embracing_growth :
        "Favorendo crescita e innovazione, Alysei consente ai Produttori di preservare e valorizzare le loro tradizioni secolari e le identità distintive, coniugando l’eredità del Made in Italy con una visione moderna e orientata al futuro.",
        From_Farm: "Dalla Terra alla Tavola",
        The_Premier_B2B: "Il Motore di Connessioni B2B",
        B2B_Connections: "Marketplace B2B di Riferimento per l’Eccellenza Italiana nel Settore Food & Beverage",
        Elevate_Your_Brand: "Valorizza il Tuo Brand, Espandi il Tuo Business",
        Growth: "SVILUPPO",
        Market_Expansion_Blueprint: "Strategia di Espansione del Mercato",
        Numerous_companies: "Numerose aziende italiane stanno perseguendo strategie di diversificazione ed espansione delle loro operazioni internazionali, esplorando nuove opportunità di sviluppo commerciale nel mercato statunitense.",
        A_recent_study: "Uno studio recente ha evidenziato che tra il 50% e il 70% dei consumatori statunitensi considera l'origine di un prodotto un elemento cruciale nelle loro decisioni d'acquisto, mostrando una propensione a pagare un prezzo premium per garantire qualità e certificazione.",
        It_is_important: "È fondamentale sottolineare che gli Stati Uniti rappresentano la più grande economia del mondo, con un Prodotto Interno Lordo che supera i 29 trilioni di dollari. Inoltre, oltre 17 milioni di italiani vivono in una popolazione di oltre 330 milioni, evidenziando un notevole potenziale di mercato per i prodotti italiani.",
        Origin_significantly: "L'origine del prodotto influenza l’acquisto dei prodotti",
        Strategic_marketing: "Marketing strategico per l'espansione",
        Expand_international:"Ampliare le attività a livello internazionale",
        Consumers_are_willing:"I consumatori sono disposti a pagare per una qualità certificata",
        Investment:"INVESTIMENTO",
        Major_Opportunities :"Grandi opportunità richiedono un investimento significativo",
        With_over :"Con oltre 70,000 aziende nel settore enogastronomico, il fulcro della nostra tradizione Made in Italy è profondamente ancorato a prodotti di alta qualità.Questo genera significative opportunità di sviluppo, incluse strategie per il rafforzamento del marchio, politiche di marketing efficaci, conformità alle certificazioni richieste, identificazione dei canali di importazione e distribuzione ottimali, connessioni con acquirenti, sviluppo della clientela e creazione di alleanze strategiche.",
        Alysei_provides:"Alysei offre una soluzione B2B/B2C efficiente, accessibile e innovativa, concepita per ottimizzare il tuo time to market.",
        Assess_US:"Analizza rischi e opportunità del mercato statunitense",
        Identify_your_target:"Definisci il tuo mercato target",
        Find_suitable:"Individua Importatori idonei",
        Connect_directly:"Stabilisci connessioni dirette con i clienti",
       Lets_Start :"INIZIAMO!",
       Where_to_Begin :"Da dove incominciare?",
       The_first_step :"Il primo passo consiste nell’accesso alla piattaforma Alysei, dove un rigoroso processo di verifica e approvazione garantisce elevati standard di qualità e sicurezza.",
       Here_you_can :"Come Membro Certificato di Alysei, avrai l'opportunità di completare il tuo Profilo Aziendale, una vetrina strategica per il tuo mercato di riferimento. Potrai condividere la storia della tua azienda, mettere in risalto i tuoi prodotti principali e creare contenuti in un ambiente social pensato per facilitare connessioni dirette con i clienti.",
       Alysei_is_a_platform_designed :"Alysei offre accesso esclusivo al suo Marketplace B2B, dove potrai creare e promuovere il tuo negozio online, presentando i tuoi prodotti a potenziali canali di importazione e acquirenti qualificati.Progettata come un Hub per gli appassionati dell’Enogastronomia Italiana, Alysei è una piattaforma che favorisce collaborazione e crescita, offrendo una panoramica unica su un settore ricco di opportunità di sviluppo.",
       Complimentary_Registration :"Registrazione gratuita",
       Thorough_Verification :"Processo di verifica e approvazione rigoroso",
       Achieve_Alysei :"Diventa un Membro Certificato di Alysei",
      Showcase_Your_Brand: "Evidenzia il tuo marchio, i tuoi prodotti e la tua storia aziendale",
        // Members Section
  Members_title: "Membri Alysei",
  Members_quote: "Nella lunga storia dell'umanità, sono stati coloro che hanno saputo collaborare e adattarsi in modo efficace a prevalere",
  Italian_FB_Producers: "Produttori Enogastronomici Italiani",
  Italian_FB_Producers_Description_m: "Esplora, trova e connettiti con Importatori e Distributori Certificati negli Stati Uniti per costruire e rafforzare il tuo marchio. Promuovi i tuoi prodotti e raggiungi nuovi consumatori in modo diretto ed efficace.",
  Importers_Distributors: "Importatori e Distributori",
  Importers_Distributors_Description_m: "Esplora, trova e connettiti con Produttori locali italiani per rafforzare il tuo portfolio prodotti, migliorare la tua competitività, espandere il tuo marchio e l'accesso al mercato.",
  Italian_Restaurants: "Ristoranti Italiani negli USA",
  Italian_Restaurants_Description_m: "Rafforza la collaborazione con Produttori e Importatori, promuovi la tua cucina e i tuoi eventi esclusivi, e attira più clienti ampliando esponenzialmente la tua visibilità.",
  Voice_of_Experts: "Esperti di Settore",
  Voice_of_Experts_Description_m: "Chef, scuole di cucina e specialisti del settore enogastronomico italiano possono utilizzare la piattaforma Alysei per promuovere il proprio nome, marchio, offerte, eventi e blog.",
  Travel_Agencies: "Agenzie di Viaggio",
  Travel_Agencies_Description_m: "Consolida i rapporti con produttori italiani certificati, importatori e distributori negli Stati Uniti, aumenta la tua visibilità e raggiungi i clienti target in modo efficace.",
  Voyagers: "Voyagers",
  Voyagers_Description_m: "Scopri il magico mondo della cucina italiana attraverso prodotti, ristoranti, eventi, viaggi, tour di degustazione, corsi di cucina, ricette, blog e molto altro.",
  Buyers: "Buyers",
  Buyers_Description_m: "Cerca prodotti regionali italiani autentici, riduci i tempi di approvvigionamento e acquista direttamente dai produttori senza commissioni.",
  How_can_we_help: "Come Possiamo Assisterti?",
  Schedule_Demo: "Schedule a Demo",
      Schedule_Demo_Description: "Prenota una demo oggi stesso per esplorare tutte le funzionalità di Alysei. Ti guideremo nella creazione del tuo profilo e nella strategia di promozione e espansione della tua attività. Alysei... Sailing to Success!",
      MarketPlace_title: "MarketPlace",
      MarketPlace_subtitle: "Il Marketplace B2B dell’Enogastronomia Italiana",
      MarketPlace_description: "Alysei è il principale marketplace B2B dedicato alla valorizzazione dell’eccellenza dei prodotti alimentari e vinicoli italiani su scala globale. Che tu sia un rivenditore, un distributore o un ristorante, avrai l’opportunità di scoprire prodotti di alta qualità che celebrano il ricco patrimonio culinario dell’Italia. Connettiti con fornitori selezionati, esplora offerte esclusive e porta la tua attività a un livello superiore, arricchendola con i sapori autentici italiani.",
      MarketPlace_sections: {
        signUp: {
          title: "Iscriviti su Alysei",
          content: "Completa la registrazione in pochi semplici passi e inizia il tuo percorso"
        },
        createStore: {
          title: "Crea il tuo Negozio",
          content: "Mostra i tuoi prodotti in modo semplice, pulito e professionale"
        },
        productList: {
          title: "Crea la tua Lista di Prodotti",
          content: "Carica il tuo portafoglio prodotti con informazioni accurate e complete e foto di alta qualità"
        },
        enquiry: {
          title: "Rispondi alle Richieste dei Buyers",
          content: "Essere reattivi ti aiuterà a costruire fiducia con i Buyers"
        },
        sell: {
          title: "Vendi Online",
          content: "Vendi direttamente agli Importatori"
        },
        support: {
          title: "Assistenza Online",
          content: "Facci sapere se qualcosa non ti sembra corretto"
        }
      },
      Buyers_title: "BUYERS",
      Buyers_subtitle: "Piattaforma B2B Alysei per i Buyers",
      Buyers_description: "Con Alysei, i Buyers possono ottimizzare significativamente i tempi di approvvigionamento per prodotti alimentari e bevande italiane di alta qualità. La nostra piattaforma facilita connessioni dirette con i Produttori più adatti, senza alcun costo, garantendo l'accesso ai migliori prodotti per i tuoi scaffali e un'esperienza di acquisto semplificata. Esplora le offerte autentiche italiane e arricchisci la tua selezione con estrema facilità!",
      Buyers_sections: {
        signUp: {
          title: "Iscriviti su Alysei",
          description: "Completa la registrazione in pochi semplici passi e inizia il tuo percorso"
        },
        createStore: {
          title: "Scopri i Prodotti",
          description: "Cerca i prodotti regionali più autentici che soddisfano le tue esigenze"
        },
        productList: {
          title: "Crea la tua Lista",
          description: "Seleziona e crea la tua lista di prodotti in base ai tuoi criteri specifici"
        },
        enquiry: {
          title: "Invia la tua Richiesta",
          description: "Crea, invia e gestisci la tua richiesta collegandoti direttamente con il Produttore"
        },
        sell: {
          title: "Acquista senza Commissioni",
          description: "Acquista senza commissioni direttamente dal Produttore secondo le tue condizioni"
        },
        support: {
          title: "Ricevi supporto da Alysei",
          description: "Non trovi ciò che cerchi? Contatta Alysei, saremo felici di aiutarti!"
        }
      },
      Videos_title: "ALYSEI ACADEMY",
    Videos_subtitle: "Come utilizzare Alysei: Video tutorial",
    videoList: [
      { id: 1, title: "Introduzione al mondo Alysei", videoUrl: "https://www.youtube.com/embed/meo1n9KUSCM?autoplay=1", image : thumbnail1 },
      { id: 2, title: "Registrazione Produttori Italiani", videoUrl: "https://www.youtube.com/embed/IzWH-jzPaZE?autoplay=1", image : thumbnail6 },
      { id: 3, title: "Creazione Profilo Produttori Italiani", videoUrl: "https://www.youtube.com/embed/ogLM4k0cazg?autoplay=1", image : thumbnail18 },
      { id: 4, title: "I Membri Alysei", videoUrl: "https://www.youtube.com/embed/n8WjTaInr_U?autoplay=1", image : thumbnail17 },
      { id: 5, title: "Motore di Connessione B2B", videoUrl: "https://www.youtube.com/embed/cIZfjv328fk?autoplay=1", image : thumbnail11 },
    ],
      Testimonials_title: "Testimonials",
      Testimonials_subtitle: "Cosa dicono i Membri Alysei!",
      testimonials: [
        {
          id: 1,
          text: "Alysei rappresenta l'opportunità che molti Produttori italiani stanno cercando da tempo. La vera sfida che si incontra nel mercato non è solo trovare prodotti italiani, ma soprattutto ricercare e scoprire quelli che soddisfano i più elevati standard di alta qualità. Alysei è progettato per aiutare i Produttori italiani desiderosi di esportare il loro marchio e il gusto autentico all'estero. Sono orgoglioso di essere Ambasciatore di Alysei per la gastronomia italiana e di contribuire alla selezione e alla promozione della migliore gastronomia per una clientela sempre più attenta e esigente!",
          name: "Carlo Raspollini",
          position: "RAI Author/Television director/Screenwriter/Radio host",
        },
        {
          id: 2,
          text: "Alysei rappresenta la soluzione che il mercato ha cercato a lungo: una rete collaborativa progettata per i produttori di prodotti di alta qualità come noi, per esplorare strategie efficaci di espansione nel mercato statunitense. Questa piattaforma ci consente di limitare gli investimenti in ricerca di mercato, massimizzando l'efficienza e riducendo il time to market. Per un'azienda come la nostra, le cui radici affondano nella cultura, nella qualità e nella tradizione, è difficile, se non impossibile, affrontare il mercato in modo diverso. Un mercato vasto come quello statunitense richiede una strategia di penetrazione mirata che sfrutti i canali giusti per promuovere efficacemente i nostri prodotti. Alysei soddisfa pienamente le nostre aspettative e siamo entusiasti di far parte di questa rete altamente qualificata di imprenditori italiani visionari.",
          name: "Giovanni Melis",
          position: "Sales Director, Cantina Su'entu",
        },
        {
          id: 3,
          text: "Alysei è la soluzione che molti produttori come noi stanno cercando da tempo: una piattaforma che facilita la ricerca e la connessione con potenziali partner, offrendo un accesso diretto a un mercato in rapida espansione. Per molti, la sfida principale consiste nel determinare da dove cominciare e come investire in modo oculato. Per un'azienda come la nostra che basa la propria strategia su un marchio forte e riconosciuto e su prodotti di alta qualità, sfruttare i canali appropriati, accedere ai mercati giusti e connettersi con il pubblico adeguato sono fattori chiave per il successo. Alysei è la piattaforma ideale progettata per soddisfare le nostre aspettative",
          name: "Stefano Alderighi",
          position: "Sales Manager, Alps Coffee",
        },
        {
          id: 4,
          text: "Alysei è la piattaforma per ampliare la propria portata di mercato attraverso l'accesso a una rete più ampia di potenziali acquirenti internazionali, tra cui rivenditori, grossisti e distributori, riducendo al contempo le spese di marketing grazie agli strumenti e alle risorse integrate di Alysei per raggiungere i clienti potenziali. Grazie a Alysei, possiamo connetterci con aziende affini che danno priorità alla qualità, promuovendo partnership che possono portare a nuove opportunità e migliorare la visibilità del nostro marchio, mettendo in mostra i nostri prodotti unici di alta qualità a un pubblico mirato. Unendoci alla piattaforma Alysei, possiamo migliorare strategicamente la nostra presenza sul mercato, costruire partnership preziose e stimolare la crescita aziendale",
          name: "Enrico Lepori",
          position: "Founder, Sardo Sole",
        },
        {
          id: 5,
          text: "Una delle principali sfide di Love to Italy è trovare Produttori italiani che mantengano elevati standard di qualità. Grazie a Alysei, possiamo semplificare il processo di approvvigionamento trovando facilmente fornitori che soddisfano i nostri specifici requisiti di qualità e prodotto, collegandoci direttamente a una rete selezionata di Produttori italiani di alta qualità, garantendo così un acquisto di prodotti autentici e premium. Inoltre, grazie agli strumenti e alle risorse di Alysei che possono aiutare a semplificare i processi logistici e distributivi, possiamo rendere più agevole la gestione della nostra catena di approvvigionamento.Crediamo che la piattaforma Alysei sarà uno strumento prezioso per spingerci verso i prossimi anni della nostra attività. Love to Italy è entusiasta di supportare Alysei nel favorire partnership con Produttori enogastronomici italiani negli Stati Uniti.",
          name: "Alessandro Sofroni",
          position: "Founder & CEO, Love to Italy",
        },
        {
          id: 6,
          text: "Alysei rappresenta una piattaforma per proteggere e promuovere i prodotti italiani autentici attraverso risorse e informazioni che educano i consumatori sull'importanza di scegliere prodotti italiani autentici, inclusi dettagli sugli standard di qualità e sulle certificazioni. Credo che far parte di Alysei per promuovere il patrimonio italiano possa contribuire a creare un fronte unito contro i prodotti ingannevoli. In generale, Alysei svolge un ruolo cruciale nella salvaguardia della reputazione dei prodotti italiani autentici, promuovendo al contempo il loro ricco patrimonio e la loro qualità.",
          name: "Robert Campana",
          position: "Founder, Stop Italian Sounding",
        },
        {
          id: 7,
          text: "L'Italia è un territorio privilegiato da risorse incredibili e da migliaia di piccole e medie imprese che producono prodotti di alta qualità nel settore alimentare e delle bevande, che non hanno eguali. La qualità, i sapori e la varietà sono i valori fondamentali dei nostri prodotti e i beni più importanti che tutti noi possediamo. Aziende come la nostra hanno il desiderio e la necessità di rafforzare il proprio marchio ed espandere la propria attività in mercati con forti opportunità di crescita, come gli Stati Uniti. Crediamo fermamente che Alysei sia la piattaforma giusta per trasformare i nostri sogni e piani in realtà, colmando il divario tra le nostre culture e tradizioni. Sono entusiasta di far parte di questo viaggio!",
          name: "Alberto Servadei",
          position: "President and CEO, Servadei Specialità Biologiche",
        },
        {
          id: 8,
          text: "Una delle nostre principali sfide presso Isola Imports è quella di mettere in relazione Produttori italiani con prodotti e standard di alta qualità che sono in linea con la nostra passione come importatori e distributori di specialità gastronomiche italiane. La piattaforma di Alysei sarà uno strumento prezioso per colmare questa lacuna e aiutarci a progredire nei prossimi 25 anni. Isola Imports è entusiasta di supportare Alysei nel portare partner di specialità gastronomiche italiane al loro hub di Chicago",
          name: "Michael Nitti",
          position: "Operations Manager, Isola Imports",
        },
        {
          id: 9,
          text: "Alysei riflette l'interesse, la passione e la volontà di tutti i ristoranti italiani che hanno dedicato la loro vita a portare la tradizione e i sapori della cucina locale italiana sulla vostra tavola. L'opportunità di far parte di un'iniziativa così potente, volta a connettere le persone e guadagnare visibilità, è straordinaria. Nella maggior parte delle aziende è necessario uscire e cercare nuovi clienti, mantenendo al contempo soddisfatti quelli che già si hanno; nei ristoranti, invece, le persone vengono da te perché hai qualcosa di buono e diverso da offrire. Alysei sarà il collegamento tra le persone e ristoranti di qualità come il nostro, pronti a dimostrare che possono trovare il made in Italy proprio qui e ora; ancor di più, faremo in modo che si sentano a casa!",
          name: "Benny Siddu",
          position: "Owner & Founder, Volare Ristorante",
        },
        {
          id: 10,
          text: "Alysei è specializzata nel collegare il settore gastronomico italiano con attori chiave a livello globale. La piattaforma offre una rete di ristoratori, distributori, chef, acquirenti e appassionati di gastronomia che sono specificamente interessati alla cucina italiana, garantendo che le connessioni siano altamente pertinenti.",
          name: "Marco Baccichetto",
          position: "Owner, Casa Baccichetto",
        },
        {
          id: 11,
          text: "Alysei facilita le connessioni con fornitori, distributori e altri professionisti del settore. Una pizzeria come la nostra può beneficiare di nuove partnership, accesso a ingredienti di alta qualità e potenziali collaborazioni con fornitori di prodotti italiani",
          name: "Elio Bartolotta",
          position: "Owner & Founder, Elio Pizza on Fire",
        },
        {
          id: 12,
          text: "Crediamo fermamente che la partnership strategica tra Ormesani srl e Alysei possa fornire competenze complementari alle operazioni di Ormesani, creando sinergie che aumentano il potenziale di crescita di entrambe le aziende. Questo permetterebbe a Ormesani di concentrarsi sul proprio core business, beneficiando al contempo del supporto strategico di Alysei per accelerare questo ambizioso percorso di internazionalizzazione.",
          name: "Martino Giuseppe Ormesani",
          position: "President of the Ormesani America Corp.",
        }
      ],
      About_Alysei: "Chi Siamo",
    Set_your_course: `"Traccia la tua rotta seguendo le stelle, non le luci delle navi di passaggio"`,
      Alysei_was_conceived: "Alysei ha avuto origine in una piccola caffetteria a Lake Geneva, nel Wisconsin, guidato da una passione condivisa e da un’ambiziosa visione di nuove opportunità di mercato. Da questa intuizione è nata una piattaforma innovativa, oggi una realtà di successo",
      Team_Alysei: "TEAM ALYSEI",
      Founders: "Fondatori",
      Andrea: "Andrea",
      Andrea_Description: "Con oltre 30 anni di esperienza nel marketing internazionale e nello sviluppo aziendale, ha avuto l'opportunità di viaggiare in tutto il mondo, esplorando culture e regioni diverse, il che ha arricchito la sua prospettiva e la sua comprensione delle dinamiche globali. I suoi progetti ambiziosi sono guidati da un profondo amore e passione per l'Italia, una nazione che considera non solo una patria, ma anche una fonte d'ispirazione costante. È orgoglioso di condividere la ricca eredità della storia e delle tradizioni italiane, impegnandosi attivamente in conversazioni che riflettono il suo forte legame con il patrimonio culturale del paese e la sua determinazione a preservarlo e valorizzarlo nel contesto contemporaneo.",
      Simona: "Simona",
      Simona_Description: "Il suo background nel settore medico le ha insegnato l'importanza della cura degli altri e la necessità di sensibilizzare riguardo a una dieta sana e bilanciata. Grazie alla sua formazione e alle esperienze dirette, ha sviluppato una profonda comprensione di come le scelte alimentari influenzino la salute e il benessere. Questa passione si riflette nel progetto Alysei, dove si dedica a promuovere non solo prodotti di alta qualità, ma anche un'autentica cultura culinaria. Motivata dal desiderio di arricchire l'esperienza delle persone, la invita a esplorare e apprezzare la ricchezza della cucina italiana, creando connessioni significative tra produttori e consumatori e sottolineando il valore della tradizione gastronomica italiana per la qualità della vita.",
      Our_Presence: "DOVE SIAMO",
      Alysei_Around_The_World: "Alysei nel Mondo",
      Join_Alysei: "UNISCITI AD ALYSEI",
    Sign_Up_Description: "Registrati gratuitamente su Alysei. Ti informiamo che tutte le richieste di accesso saranno sottoposte a un processo di approvazione.",
      Join_Now: "Unisciti ora",
      Contact_Us: "CONTATTACI",
      Lets_Get_In_Touch: "Mettiamoci in contatto!",
      Complete_The_Form: "Compila il modulo e il nostro team ti contatterà al più presto.",
      First_Name: "Nome",
      Last_Name: "Cognome",
      Company_Name: "Nome Azienda",
      Email: "Email",
      Message: "Messaggio",
      Write_Here: "Scrivi qui...",
      Send_Message: "Invia Messaggio",
      //benefits
      Italian_FB_Producers_Title: "PRODUTTORI ENOGASTRONOMICI ITALIANI",
      Italian_FB_Producers_Description: "Esplora, scopri e connettiti con Importatori e Distributori Certificati negli Stati Uniti. Costruisci e consolida il tuo marchio, promuovi i tuoi prodotti e interagisci direttamente con il tuo pubblico target",
      Italian_FB_Producers_Investment_Heading: "Investimento",
      Italian_FB_Producers_Investment_Subheading: "Grandi opportunità richiedono un investimento significativo",
      Italian_FB_Producers_Investment_Desc: "Si stima che circa 70,000 aziende operino nel settore enogastronomico, rappresentando il fulcro della nostra tradizione del Made in Italy e il nostro impegno verso prodotti di alta qualità. Questo panorama offre significative opportunità di sviluppo, tra cui strategie per il rafforzamento del marchio e la crescita, politiche di marketing efficaci, conformità alle certificazioni necessarie, identificazione dei canali di importazione e distribuzione più idonei, connessione con buyers, costruzione di una clientela e sviluppo di alleanze strategiche. Alysei offre una soluzione B2B/B2C efficace, accessibile e innovativa, progettata per ottimizzare il tuo time to market.",
      Italian_FB_Producers_Investment_BadgeInfo: [
          "Analizza i rischi e le opportunità del mercato",
          "Impara a identificare il tuo mercato di riferimento",
          "Seleziona gli importatori più adatti alle tue esigenze",
          "Scopri come interagire direttamente con i clienti"
      ],
      Italian_FB_Producers_Growth_Heading: "SVILUPPO",
      Italian_FB_Producers_Growth_Subheading: "Strategia di espansione del mercato",
      Italian_FB_Producers_Growth_Desc: "Numerose aziende italiane stanno perseguendo strategie di diversificazione ed espansione delle loro operazioni internazionali, esplorando nuove opportunità di sviluppo commerciale nel mercato statunitense. Uno studio recente ha evidenziato che tra il 50% e il 70% dei consumatori statunitensi considera l'origine di un prodotto un elemento cruciale nelle loro decisioni d'acquisto, mostrando una propensione a pagare un prezzo premium per garantire qualità e certificazione. È fondamentale sottolineare che gli Stati Uniti rappresentano la più grande economia del mondo, con un Prodotto Interno Lordo che supera i 29 trilioni di dollari. Inoltre, oltre 17 milioni di italiani vivono in una popolazione di oltre 330 milioni, evidenziando un notevole potenziale di mercato per i prodotti italiani.",
      Italian_FB_Producers_Growth_BadgeInfo: [
          "L'origine del prodotto influenza l’acquisto dei prodotti",
          "Marketing strategico per l'espansione",
          "Ampliare le attività a livello internazionale",
          "I consumatori sono disposti a pagare per una qualità certificata"
      ],
      Italian_FB_Producers_Future_Heading: "GUARDANDO AL FUTURO",
      Italian_FB_Producers_Future_Subheading: "Affidarsi unicamente a un importatore non è sufficiente per sviluppare in modo efficace un mercato",
      Italian_FB_Producers_Future_Desc: "Attualmente, oltre 10,000 aziende italiane esportano prodotti alimentari e bevande negli Stati Uniti, mirando a consolidare i propri marchi, ampliare il proprio pubblico e incrementare le vendite e la quota di mercato. Tuttavia, una sfida significativa è rappresentata dall'importante investimento necessario per ottenere le certificazioni richieste, costruire consapevolezza nel mercato, identificare Importatori e Distributori adeguati, individuare i mercati target e sviluppare i propri marchi, oltre a posizionare efficacemente le offerte di prodotto presso i consumatori giusti.",
      Italian_FB_Producers_Future_BadgeInfo: [
          "Rafforzare la brand identity",
          "Costruire consapevolezza nel mercato",
          "Espandere il proprio pubblico",
          "Aumentare le vendite"
      ],
      Italian_FB_Producers_Services_Title: "ESPLORA LE NOSTRE FUNZIONALITÀ",
      Italian_FB_Producers_Services_Desc: "Non potrai mai attraversare l'oceano se non hai il coraggio di lasciare la costa alle spalle",
    Italian_FB_Producers_Services_Content1: "Completa il profilo della tua azienda per migliorare la visibilità",
    Italian_FB_Producers_Services_Content2: "Promuovi il tuo marchio e le tue offerte di prodotto",
    Italian_FB_Producers_Services_Content3: "Crea il tuo negozio nel Marketplace B2B di Alysei",
    Italian_FB_Producers_Services_Content4: "Individua e stabilisci connessioni con Importatori e Distributori negli Stati Uniti",
    Italian_FB_Producers_Services_Content5: "Individua e connettiti con Ristoranti Italiani negli Stati Uniti",
      Italian_FB_Producers_Services_Content6: "Stabilisci connessioni con Buyers a livello globale",
      Italian_FB_videoTitle : "LE NOSTRE RISORSE",
      Italian_FB_videoDesc : "Video Introduttivo per i Produttori",
      Italian_FB_videoSub : "In questo video, scoprirai la missione di Alysei e i vantaggi che offre ai Produttori. Ti guideremo anche attraverso alcuni semplici passaggi per accedere alla piattaforma e unirti a questa entusiasmante iniziativa. ",
      Importers_Distributors_Title: "IMPORTATORI e DISTRIBUTORI",
      Importers_Distributors_Description: "Importatori e Distributori sono costantemente alla ricerca di nuovi prodotti di alta qualità per diversificare la loro offerta e potenziare la competitività sul mercato",
      Importers_Distributors_ContentImg_Heading: "CONNESSIONE B2B",
      Importers_Distributors_ContentImg_Subheading: "Amplia la tua rete, aumenta la tua visibilità e raggiungi nuovi mercati",
      Importers_Distributors_ContentImg_Desc: "Alysei è concepito per ottimizzare le connessioni tra le aziende in base alle loro esigenze e requisiti specifici. Questo approccio semplifica notevolmente il processo di ricerca e collegamento con partner qualificati, riducendo sia i costi che i tempi necessari. Grazie a questa soluzione, Alysei consente alle aziende di identificare il percorso più efficace per l'espansione nei loro mercati di riferimento.",
      Importers_Distributors_ContentImg_BadgeInfo: [
        "Ottimizzare le risorse, riducendo tempo e costi",
        "Diversificare l'offerta di prodotti",
        "Introdurre prodotti di alta qualità",
        "Rafforzare la competitività sul mercato"
      ],
      Importers_Distributors_Services_Desc: "Nella lunga storia dell'umanità, sono stati coloro che hanno saputo collaborare e adattarsi in modo efficace a prevalere",
      Importers_Distributors_Services_Content1: "Completa il profilo della tua azienda per massimizzare la tua visibilità",
      Importers_Distributors_Services_Content2: "Promuovi il tuo marchio e le tue offerte di prodotto",
      Importers_Distributors_Services_Content3: "Accedi al Marketplace B2B di Alysei",
      Importers_Distributors_Services_Content4: "Scopri e stabilisci connessioni con i Produttori Enogastronomici Italiani ",
      Importers_Distributors_Services_Content5: "Esplora e stabilisci collegamenti con tutti i Membri Alysei",
      Importers_Distributors_Services_Content6: "Avvia campagne promozionali per i tuoi eventi al fine di consolidare e potenziare la tua attività",
      Importers_Distributors_videoDesc: "Video Introduttivo per Importatori e Distributori",
      Importers_Distributors_videoSub : "In questo video, esplorerai la missione di Alysei e i vantaggi che offre agli Importatori e Distributori. Ti guideremo anche attraverso alcuni semplici passaggi per accedere ad Alysei e unirti a questa entusiasmante iniziativa.",
      Italian_Restaurants_Title: "RISTORANTI ITALIANI NEGLI STATI UNITI ",
      Italian_Restaurants_Description: "Ogni città degli Stati Uniti ospita un numero considerevole di ristoranti italiani, tutti potenziali Membri della rete Alysei",
      Italian_Restaurants_ContentImg_Heading: "MANTENERE UN VANTAGGIO COMPETITIVO",
      Italian_Restaurants_ContentImg_Subheading: "Espandi il tuo portafoglio clienti",
      Italian_Restaurants_ContentImg_Desc: "I Ristoranti devono costantemente investire per mantenere la competitività nel mercato, fidelizzare la clientela e potenzialmente ampliarla, sorprendendo i clienti con idee innovative. È fondamentale assicurarsi che il pubblico sia informato sulle offerte attuali e sui progetti futuri. Il passaparola, riferito a ciò che le persone dicono riguardo alla tua attività, alla qualità del servizio, alla cura riservata ai clienti, oltre ad aspetti come qualità, prezzi e posizione, può influenzare in modo significativo il tuo successo.",
      Italian_Restaurants_ContentImg_BadgeInfo: [
        "Assicurare la continuità operativa",
        "Espandere la tua clientela",
        "Superare le aspettative dei clienti",
        "Soluzioni innovative per segmenti di clientela diversificati"
      ],
      Italian_Restaurants_Services_Desc: "Nella lunga storia dell'umanità, sono stati coloro che hanno saputo collaborare e adattarsi in modo efficace a prevalere",
      Italian_Restaurants_Services_Content1: "Completa il profilo del tuo ristorante per massimizzare la tua visibilità",
      Italian_Restaurants_Services_Content2: "Accedi ad Alysei Social per valorizzare il tuo ristorante e mettere in risalto le tue specialità culinarie",
      Italian_Restaurants_Services_Content3: "Esplora il Marketplace B2B di Alysei per scoprire prodotti di alta qualità",
      Italian_Restaurants_Services_Content4: "Identifica e stabilisci connessioni con i Produttori Enogastronomici Italiani",
      Italian_Restaurants_Services_Content5: "Identifica e stabilisci connessioni con Importatori e Distributori negli Stati Uniti",
      Italian_Restaurants_Services_Content6: "Organizza eventi strategici per attrarre nuovi clienti e ampliare la tua portata commerciale",
      Voice_of_Experts_Title: "ESPERTI DI SETTORE",
      Voice_of_Experts_Description: "L'ampia rete di Esperti del settore è altamente qualificata e motivata a potenziare la propria visibilità in questo mercato in rapida espansione",
      Voice_of_Experts_ContentImg_Heading: "COMPETENZA",
      Voice_of_Experts_ContentImg_Subheading: "Rappresentanza della Comunità di Esperti",
      Voice_of_Experts_ContentImg_Desc: "La Comunità degli Esperti comprende Chefs, Scuole di Cucina, Chefs di Ristoranti Rinomati, Blogger Culinari, Associazioni di Chefs, Specialisti in cibo e bevande italiane, Programmi Televisivi, Riviste e Autori. Gli Esperti di Settore Alysei possono utilizzare la piattaforma Alysei per promuovere il proprio nome, marchio, iniziative, eventi, blog e pubblicazioni.",
      Voice_of_Experts_ContentImg_BadgeInfo: [
        "Rafforza la tua reputazione",
        "Specialisti in enogastronomia",
"Aumenta e sviluppa la tua visibilità",
"Coinvolgi un pubblico più ampio",
      ],
      Voice_of_Experts_Services_Desc: "Nella lunga storia dell'umanità, sono stati coloro che hanno saputo collaborare e adattarsi in modo efficace a prevalere",
      Voice_of_Experts_Services_Content1: "Completa il tuo profilo per massimizza la tua visibilità",
      Voice_of_Experts_Services_Content2: "Accedi ad Alysei Social per valorizzare i tuoi servizi",
      Voice_of_Experts_Services_Content3: "Esplora il Marketplace B2B di Alysei per scoprire prodotti di elevata qualità",
      Voice_of_Experts_Services_Content4: "Identifica e stabilisci connessioni con i Produttori Enogastronomici Italiani",
      Voice_of_Experts_Services_Content5: "Identifica e stabilisci connessioni con Importatori e Distributori negli Stati Uniti",
      Voice_of_Experts_Services_Content6: "Organizza e promuovi il tuo evento enogastronomico, il tuo blog e i tuoi corsi per coinvolgere un pubblico più vasto",
      Traveler_Agencies_Title: "AGENZIE DI VIAGGIO ",
      Traveler_Agencies_Description: "Il modo più efficace per scoprire l'Italia è immergersi nelle sue ricche e variegate regioni",
      Traveler_Agencies_ContentImg_Heading: "OPPORTUNITÀ DI MERCATO",
      Traveler_Agencies_ContentImg_Subheading: "Rafforza la collaborazione con i Produttori italiani",
      Traveler_Agencies_ContentImg_Desc: "Le Agenzie di Viaggio specializzate stanno sempre più esplorando opportunità B2B per connettersi con i Produttori Enogastronomici Italiani. Personalizzando gli itinerari, mirano a fornire ai loro clienti esperienze indimenticabili e uniche.",
      Traveler_Agencies_ContentImg_BadgeInfo: [
        "Crea connessioni strategiche",
        "Esperienza unica e memorabile",
        "Clienti target",
        "Turismo Enogastronomico"
      ],
      Traveler_Agencies_Services_Desc: "Nella lunga storia dell'umanità, sono stati coloro che hanno saputo collaborare e adattarsi in modo efficace a prevalere",
      Traveler_Agencies_Services_Content1: "Completa il profilo della tua agenzia per massimizzare la tua visibilità",
      Traveler_Agencies_Services_Content2: "Accedi ad Alysei Social per valorizzare e promuovere i tuoi servizi",
      Traveler_Agencies_Services_Content3: "Esplora il B2B di Alysei per ottimizzare le tue collaborazioni",
      Traveler_Agencies_Services_Content4: "Identifica e stabilisci connessioni con i Produttori Enogastronomici Italiani ",
      Traveler_Agencies_Services_Content5: "Identifica e stabilisci collegamenti con Importatori e Distributori negli Stati Uniti",
      Traveler_Agencies_Services_Content6: "Promuovi i tuoi itinerari Enogastronomici esclusivi ",
  
      Voyagers_Title: "VOYAGER",
      Voyagers_Description: "La comunità degli utenti è ampia e diversificata, includendo un consistente gruppo negli Stati Uniti che nutre una profonda passione per la cultura e le tradizioni culinarie italiane",
      Voyagers_ContentImg_Heading: "PERCHÉ ALYSEI",
      Voyagers_ContentImg_Subheading: "Ragioni per aderire ad Alysei",
      Voyagers_ContentImg_Desc: "Unirsi a una piattaforma di social media dedicata alla gastronomia italiana offre un'esperienza ricca e appagante. Scopri ricette autentiche, suggerimenti culinari e interpretazioni creative di piatti classici, immergendoti nella tradizione gastronomica italiana. Connettiti con altri appassionati di cibo, condividi le tue esperienze e scambia idee all'interno di una comunità vivace. Goditi fotografie di piatti straordinari che ispirano la tua cucina e la tua presentazione, rimani aggiornato sui festival gastronomici italiani e sulle tendenze culinarie globali, e accedi a preziosi approfondimenti da chef ed esperti del settore. Che tu stia cercando ispirazione culinaria o pianificando un'avventura gastronomica in Italia, questa piattaforma rappresenta la tua porta d'accesso a un mondo di eccellenza culinaria.",
      Voyagers_ContentImg_BadgeInfo: [
        "Esperienza culinaria autentica",
"Comunità e connessione",
"Ispirazione visiva",
"Aggiornamenti e approfondimenti",

      ],
      Voyagers_Services_Desc: "Se stai cercando la prossima grande novità e guardi dove guardano tutti gli altri, stai guardando nel posto sbagliato",
      Voyagers_Services_Content1: "Crea il tuo profilo su Alysei per evidenziare le tue passioni e ricevere suggerimenti personalizzati",
      Voyagers_Services_Content2: "Esplora prodotti, ristoranti, eventi, corsi di cucina, blog e opportunità di viaggio in Italia",
      Voyagers_Services_Content3: "Cerca ricette, crea le tue, condividile e ricevi feedback",
      Voyagers_Services_Content4: "Interazione sociale, condivisione di informazioni, valutazioni di prodotti, recensioni di ristoranti e altro ancora",
      Voyagers_Services_Content5: "Esplora il Marketplace di Alysei, dove puoi scoprire prodotti e accedere a contenuti esclusivi",
      Voyagers_Services_Content6: "Ottimizza la tua iscrizione per sbloccare vantaggi esclusivi",
  
      Buyers_Title: "BUYERS",
      Buyers_Description: "Scopri i prodotti regionali italiani più autentici, ottimizza i tempi di approvvigionamento e acquista direttamente dai produttori senza commissioni, secondo le tue condizioni",
      Buyers_ContentImg_Heading: "OPPORTUNITÀ DI MERCATO",
      Buyers_ContentImg_Subheading: "Porta prodotti di alta qualità sugli scaffali in tempi rapidi e a costi contenuti",
      Buyers_ContentImg_Desc: "La domanda di prodotti italiani nel mercato globale è in costante aumento. Gli alimenti e le bevande Made in Italy godono di un forte appeal tra i consumatori, con i supermercati che costituiscono il canale di acquisto preferito per le specialità alimentari, rappresentando il 69% delle vendite totali. Alysei è progettato per ottimizzare le connessioni tra acquirenti globali e le aziende più adatte, in base alle loro esigenze e requisiti specifici. Questo approccio riduce significativamente il lungo e costoso processo di ricerca e collegamento con Produttori Italiani qualificati al momento opportuno. Risparmiando tempo e denaro, Alysei ti supporta nell'identificazione del percorso più efficace per l'espansione commerciale nel tuo territorio.",
      Buyers_ContentImg_BadgeInfo: [
        "Riduzione del 60% nei tempi di approvvigionamento",
"Ottimizza tempo e costi",
"Ottimizza i tuoi margini",
"Acquista senza costi aggiuntivi",
      ],
      Buyers_Services_Desc: "Apprendimento e innovazione vanno di pari passo. L'arroganza del successo è pensare che ciò che hai fatto ieri sarà sufficiente per domani",
      Buyers_Services_Content1: "Riduci i tempi di approvvigionamento del 60% e introduci nuovi prodotti sugli scaffali in tempi record",
      Buyers_Services_Content2: "Cerca, seleziona, invia richieste di prodotto e interagisci con Produttori Certificati",
      Buyers_Services_Content3: "Seleziona e interagisci con Produttori attivi e verificati di Alysei per ottenere risparmi sui costi mirati",
      Buyers_Services_Content4: "Acquista direttamente dal Produttore senza commissioni e secondo le tue condizioni",
      Buyers_Services_Content5: "Seleziona e compila la tua lista di prodotti in base a criteri specifici",
      Buyers_Services_Content6: "Crea, invia e gestisci le tue richieste, connettendoti direttamente con i Produttori",
      Download_Title: "Scarica l'App Alysei",
      Download_Description: "Scarica l'App Alysei per esplorare un mondo di autentica gastronomia italiana: connettiti con prodotti italiani certificati, crea collaborazioni e amplia il tuo mercato. Dove i sapori locali incontrano i palati internazionali!",
      Newsletter_Title: "Iscriviti alla Nostra Newsletter",
    Newsletter_Description: "Per ricevere le ultime novità e promozioni",
    Newsletter_Placeholder: "Inserisci la tua email",
    Newsletter_Button: "Iscriviti",
      faq: "Domande Frequenti",
      faqItems: [
      {
        title: "Che cos'è Alysei?",
        description: "Alysei è una piattaforma B2B/B2C specializzata, pensata per facilitare l'internazionalizzazione dei prodotti enogastronomici italiani di alta qualità.",
      },
      {
        title: "Quali sono i principali vantaggi di unirsi ad Alysei? ",
        description: "Alysei funge da connettore strategico tra i Produttori italiani enogastronomici, gli Importatori e Distributori negli Stati Uniti, i Ristoranti italiani negli Stati Uniti, gli Esperti di Settore e i Buyers globali. Questa piattaforma facilita partnership e collaborazioni, permettendo ai Produttori italiani di accedere a nuovi mercati, aumentare la visibilità tra i potenziali Buyers e rafforzare la loro presenza a livello globale.",
      },
      {
        title: "In che modo Alysei può supportare i produttori italiani?",
        description: "La piattaforma offre una serie di strumenti e risorse per aiutare le aziende a orientarsi nelle complessità del commercio internazionale, inclusi logistica e conformità normativa. Inoltre, Alysei incrementa la visibilità del marchio attraverso iniziative di marketing mirato e la partecipazione a eventi di settore.",
      },
      {
        title: "Cos'è la sezione B2B?",
        description: "La sezione B2B (Business to Business) di Alysei è focalizzata sul favorire la collaborazione e lo sviluppo di relazioni commerciali tra i suoi membri, con l'obiettivo di creare opportunità di crescita e di business.",
      },
      {
        title: "Cosa si intende per B2C? ",
        description: "La sezione B2C (Business to Customer) di Alysei è progettata per consentire ai produttori di entrare in contatto con i loro potenziali consumatori.",
      },
    ],
      see_more : "Scopri di più",
      see_less: "Vedi di meno",
      faqItems_Page: [
     {
        title: "Che cos'è Alysei?",
        description: "Alysei è una piattaforma B2B/B2C specializzata, pensata per facilitare l'internazionalizzazione dei prodotti enogastronomici italiani di alta qualità.",
      },
      {
        title: "Quali sono i principali vantaggi di unirsi ad Alysei? ",
        description: "Alysei funge da connettore strategico tra i Produttori italiani enogastronomici, gli Importatori e Distributori negli Stati Uniti, i Ristoranti italiani negli Stati Uniti, gli Esperti di Settore e i Buyers globali. Questa piattaforma facilita partnership e collaborazioni, permettendo ai Produttori italiani di accedere a nuovi mercati, aumentare la visibilità tra i potenziali Buyers e rafforzare la loro presenza a livello globale.",
      },
      {
        title: "In che modo Alysei può supportare i produttori italiani?",
        description: "La piattaforma offre una serie di strumenti e risorse per aiutare le aziende a orientarsi nelle complessità del commercio internazionale, inclusi logistica e conformità normativa. Inoltre, Alysei incrementa la visibilità del marchio attraverso iniziative di marketing mirato e la partecipazione a eventi di settore.",
      },
      {
        title: "Cos'è la sezione B2B?",
        description: "La sezione B2B (Business to Business) di Alysei è focalizzata sul favorire la collaborazione e lo sviluppo di relazioni commerciali tra i suoi membri, con l'obiettivo di creare opportunità di crescita e di business.",
      },
      {
        title: "Cosa si intende per B2C? ",
        description: "La sezione B2C (Business to Customer) di Alysei è progettata per consentire ai produttori di entrare in contatto con i loro potenziali consumatori.",
      },
      {
        title: "Cos'è il Marketplace B2B di Alysei?",
        description: "Il Marketplace per i Produttori italiani funge da centro d'affari dove è possibile creare il proprio negozio online, esporre i propri prodotti e connettersi con potenziali Importatori, Ristoranti ed Esperti del Settore. Le transazioni per la vendita dei propri prodotti agli Importatori sono facilitate all'interno di questo ambiente. In futuro, il Marketplace si espanderà per includere i Consumatori, permettendo la vendita diretta dei prodotti ai clienti finali (B2C)."
      },
      {
        title: "Chi sono i Buyers globali?",
        description: "Con il termine ''compratori globali'' ci riferiamo agli acquirenti del settore FMCG (Fast-Moving Consumer Goods), responsabili della gestione degli approvvigionamenti per i rivenditori e i grossisti.Gli acquirenti FMCG (Fast-Moving Consumer Goods) sono professionisti coinvolti nell'approvvigionamento di beni di consumo a rapida rotazione, ossia prodotti che si vendono rapidamente a costi relativamente bassi. Questi acquirenti sono generalmente responsabili della selezione e dell'approvvigionamento di tali prodotti per rivenditori e grossisti, assicurandosi che il loro inventario soddisfi la domanda dei consumatori."
      },
      {
        title: "È previsto un costo per registrarsi su Alysei?",
        description: "La registrazione su Alysei è completamente gratuita."
      },
      {
        title: "Sono disponibili diverse opzioni di Membership per i Produttori italiani?",
        description: "Sì, sono disponibili quattro differenti piani di abbonamento, tra cui un'opzione di abbonamento gratuito. Ogni piano offre una serie di vantaggi e benefici."
      },
      {
        title: "Qual è il processo per registrare la mia azienda su Alysei?",
        description: 'Dovresti cliccare su "Unisciti ora" (or Join Alysei) e selezionare il tuo ruolo.'
      },
      {
        title: "Chi sono i Voyagers?",
        description: "I Voyagers sono utenti di Alysei, individui che interagiscono con la piattaforma per esplorare il mondo dell'enogastronomia italiana, nonché i prodotti autentici e di alta qualità del Made in Italy. Hanno l'opportunità di vivere l'essenza dell'Italia, immergendosi in un'esperienza unica."
      },
      {
        title: "Cos'è un Hub?",
        description: "L'Hub si riferisce a un'area geografica centrata su un distretto metropolitano, che funge da punto di riferimento per attività commerciali, di networking e di sviluppo."
        },
       {
        title: "Cosa significa essere un membro di un Hub?",
        description: "L'Hub offre visibilità all'interno della piattaforma Alysei, un aspetto fondamentale per favorire collaborazioni commerciali e garantire un accesso facilitato al tuo mercato di riferimento."
        },
        {
        title: "Chi sono i membri di Alysei nella sezione B2B?",
        description: "I Produttori italiani Enogastronomici, gli Importatori e Distributori negli Stati Uniti, i Ristoranti italiani negli Stati Uniti, gli Esperti di Settore, le Agenzie di Viaggio e i Buyers globali."
      }
      ],
      login_img_title : "Un Futuro Promettente per l'Export della Gastronomia Italiana",
      login_img_subtitle: "Alysei è la principale piattaforma B2B e B2C concepita per supportare l’internazionalizzazione delle aziende italiane nel settore Enogastronomico, aiutandole a far crescere il proprio marchio e a sviluppare il business nei mercati esteri.",
      Log_InAlysei: "Accedi ad Alysei",
      enter_details : "Inserisci i tuoi dati ",
      new_Account: "Non hai ancora un account?",
      create_Now: "Registrati ora",
      Sign_Up_to_Alysei: "Iscriviti ad Alysei",
      Become_an_Alysei_member: "Diventa un membro Alysei iscrivendoti alla versione Beta di prova gratuita. La tua richiesta di accesso sarà soggetta ad approvazione.",
      thank_you: "Grazie per averci contattato",
         thakyou_subscribe : "Grazie per esserti iscritto!",
         Full_name_required : "Il nome è obbligatorio",
        Last_name_required : "Il cognome è obbligatorio",
        Email_required : "L'email è obbligatoria",
         Enter_your_message : "Scrivi il tuo messaggio",
         valid_email_address : "Inserisci un indirizzo email valido",
     
      unsubscribe_We_sorry: "Ci dispiace che tu abbia deciso di annullare l'iscrizione!",
      unsubscribe: "Hai annullato con successo l'iscrizione alle nostre email. Speriamo di rivederti presto.",
      unsubscribe_more: "Se cambi idea, puoi sempre iscriverti nuovamente in qualsiasi momento.",
      shipping_selection_No:`You have selected "NO" for shipping, which means no shipping services will be provided for this order. For further information or assistance, please contact the Producer directly.`,
      Pending_name:'In Attesa',
      Processing_name:'In Elaborazione',
      Hold_name:'In Sospeso',
      Cancel_name:'Annulla',
      InTransit_name:'In Transito',
      Completed_name:'Completato'
    };
    
    
    if (lang && lang.length && lang === "it") {
     
      const ENV = IT;
      return ENV;
    } else {
      const ENV = EN;
      return ENV;
    }
  },
};
