import React, { useEffect, useState } from "react";
import SettingLeftNavigation from "./SettingLeftNavigation";
import language from "../../../helpers/language";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFreeSubscription,
  getSubscription,
  subscriptionStatically,
} from "../module/UserSetting";
import REACT_APP_API from "../../../config/environment";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import moment from "moment";

export default function SubscriptionModal() {
  const dispatch = useDispatch();
  const { subscriptionData, subscriptionPaymentStatus, userSettingSpinner } =
    useSelector((state) => state.userSettingState);
  const { get_Subscription_Status_Data } = useSelector(
    (state) => state.activityFeedStatus
  );
  const { planExpireDate } = useSelector((state) => state.auth);

  const [subsModal, setSubsModal] = useState(true);
  const [moreInfo, setMoreInfo] = useState(false);
  const [lang] = useState(language.getLang());
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [subscriptionBenefit, setSubscriptionBenefit] = useState([]);
  const [moreInfoIndex, setMoreInfoIndex] = useState();
  // const [planType, setPlanType] = useState();
  const [modalOpen, setmodalOpen] = useState(false);
  const [redirectUrl, setRedirecturl] = useState();
  const [upgradeStatus, setUpgradestatus] = useState();
  const currentPlainPrice =
    get_Subscription_Status_Data?.subscription?.subscription_plan?.price;
  const [plan, setPlan] = useState();
  const [plan_id, setPlan_id] = useState();
  const [isThank, setIsThank] = useState(false);
  const [cancel, setCancel] = useState();
  const [planName, setPlanName] = useState();
  const [slugType,setSlugType]=useState()

  const membershipTitles = {
    free: "Free Membership",
    monthly_glod: "Gold Membership",
    monthly_platinum: "Platinum Membership",
    monthly_diamond: "Diamond Membership",
    yearly_glod: "Gold Membership",
    yearly_platinum: "Platinum Membership",
    yearly_diamond: "Diamond Membership"
  };

  console.log(membershipTitles[slugType],slugType,"slygtypre")
 
  const handleCloseModal = () => {
    setmodalOpen(false);
  };

  const handleThankYouModal = (bool, data = false) => {
    if (bool) {
      setCancel(data);
      setIsThank(true);
    } else {
      setCancel(data);
      setIsThank(false);
    }
  };

  const handleOpenModal = (currentPlain) => {
    if(currentPlain!=='Current'){

      setmodalOpen(true);
    }
    // setRedirecturl(payment_url);
  };

  useEffect(() => {
    dispatch(getSubscription());
    subscriptionBenefitFunction();
  }, []);

  const subscriptionBenefitFunction = async () => {
    let result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/membership/subscription/plan/benefits`,
      {
        method: "GET",
      }
    );
    let response = await result.json();
    setSubscriptionBenefit(response.benefits);
  };

  const handlerToggle = () => {
    setIsSwitchOn((prevState) => !prevState);
  };

  const freeSubscription = async () => {
    dispatch(getFreeSubscription());
    if (userSettingSpinner == false) {
      setmodalOpen(false);
    }
    // setTimeout(()=>{
    //     setmodalOpen(false)
    // },1000)
  };

  const handleSubscriptionStatically = async () => {
    let data = { plan_type: plan, subscription_id: plan_id };
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    let staically_Data = await dispatch(subscriptionStatically(formData));
   
    if (staically_Data) {
      setmodalOpen(false);
      handleThankYouModal(true);
    }
  };

  return (
    <>
      <div className='home__page b2b hubMember'>
        <Modal
          className='fullWidthModal'
          ariaHideApp={false}
          isOpen={subsModal}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "4px",
              padding: "0",
              // outline: 'none',
            },
          }}
        >
          <div className='popup_header flex justify-between items-center'>
            {/* <div className='bgBlueText'>
                            <p>Thankyou for sign in with us, you have been awarded with Gold Membership till Feb 2024, You can upgrade or downgrade at any time.</p>
                        </div> */}
            {/* Enable this code for showing warning message */}
            {/* <div className='bgBlueText bgRed'>
                            <p>Hey, if you downgrade your plan you will lose all features from Gold Plan</p>
                        </div> */}
            <div></div>
            <Link to='/subscription' className='close_btn--modal'>
              {lang?.close}
            </Link>
          </div>
          <div className='modal-body'>
            <div className='card card--block modal_box modalLessGap paymentView'>
              <h2 className='pageTitle'>Subscription</h2>
              <div className='topBar'>
                <div className='flexBox'>
                  <p>Choose Your Plan</p>
                  <div className='taoggle_switch flexBox'>
                    <label>Monthly</label>
                    <div className='gridColRight text-right'>
                      <label className='switch'>
                        <input
                          type='checkbox'
                          onClick={handlerToggle}
                          checked={isSwitchOn}
                        />
                        <span className='slider round'></span>
                      </label>
                    </div>
                    <label>Yearly</label>
                  </div>
                </div>
              </div>
              <div className='memberList'>
                <div className='cardList grid5'>
                  <div className='subscriptionCard card1'>
                    {/* <div className='whiteGap pricingTableRow'></div> */}
                    <div className='hideText'>
                      <p>Free Membership</p>
                      <h4>FREE</h4>
                      <div className='memberButton pricingTableRow'>
                        <button type='button' className='btn btn1'>
                          Downgrade
                        </button>
                        <div>
                          <Link to='#'>More Info</Link>
                        </div>
                      </div>
                    </div>
                    <div className='pricingTable'>
                      {subscriptionBenefit?.map((data, i) => (
                        <div className='pricingTableRow' key={i}>
                          <div className='pricingData'>{data.name}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {!isSwitchOn &&
                    subscriptionData?.monthly_plans?.map((data, i) => {

                      let currentPlain=data.name ==
                      get_Subscription_Status_Data
                        ?.subscription?.subscription_plan
                        ?.name
                      && "Current"

                      if (data?.subscription_plan_id == 1) {
                        data.name = "Free";
                      } else if (data?.subscription_plan_id == 2) {
                        data.name = "Gold";
                      } else if (data?.subscription_plan_id == 3) {
                        data.name = "Platinum";
                      } else if (data?.subscription_plan_id == 4) {
                        data.name = "Diamond";
                      }
                      return (
                        <div className='subscriptionCard'>
                          <p>{data.name}
                            {/* {(data.subscription_plan_id == 1 && "Free") ||
                              (data.subscription_plan_id == 2 && "Gold") ||
                              (data.subscription_plan_id == 3 && "Platinum") ||
                              (data.subscription_plan_id == 4 && "Diamond")} */}
                            Membership
                          </p>
                          <h4>
                            ${parseFloat(data.price).toFixed(2)}/
                            {data.plan.slice(0, 2)}
                          </h4>

                          <div className='memberButton pricingTableRow'>
                            {
                              // i==0?
                              // <button type='button' className={`btn btn1`}>Downgrade</button>
                              // :(
                              data.price ==
                              get_Subscription_Status_Data?.subscription
                                ?.subscription_plan?.price ? (
                                <Link to='/subscription'>
                                  <button
                                    onClick={() => {
                                      setPlan_id(data?.subscription_plan_id);
                                      setPlan(data?.plan);
                                      setPlanName(data?.name);
                                    }}
                                    type='button'
                                    className={`btn btn2 ${
                                      data.price ==
                                        get_Subscription_Status_Data
                                          ?.subscription?.subscription_plan
                                          ?.price && "btn3"
                                    } `}
                                  >
                                    {data.name ==
                                    get_Subscription_Status_Data?.subscription
                                      ?.subscription_plan?.name ? (
                                      userSettingSpinner ? (
                                        <FormSpinner />
                                      ) : (
                                        "Current"
                                      )
                                    ) : currentPlainPrice ? (
                                      data.price >
                                      get_Subscription_Status_Data?.subscription
                                        ?.subscription_plan?.price ? (
                                        "Upgrade"
                                      ) : (
                                        "Downgrade"
                                      )
                                    ) : (
                                      "Upgrade"
                                    )}
                                  </button>
                                </Link>
                              ) : data.name == "Free" ? (
                                <div
                                  onClick={() => {
                                    handleOpenModal(currentPlain);
                                    setPlan_id(data?.subscription_plan_id);
                                    setPlan(data?.plan);
                                    setPlanName(data?.name);
                                    data.price > currentPlainPrice
                                      ? setUpgradestatus("Upgrade")
                                      : setUpgradestatus("Downgrade");
                                  }}
                                >
                                  <button
                                    type='button'
                                    className={`btn btn2 ${
                                      data.name ==
                                        get_Subscription_Status_Data
                                          ?.subscription?.subscription_plan
                                          ?.name && "btn3"
                                    } `}
                                  >
                                    {currentPlainPrice
                                      ? data.name ==
                                        get_Subscription_Status_Data
                                          ?.subscription?.subscription_plan
                                          ?.name
                                        ? "Current"
                                        : data.price >
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.price
                                        ? "Upgrade"
                                        : "Downgrade"
                                      : "Upgrade"}
                                  </button>
                                </div>
                              ) : (
                                // <a href={data.payment_link}>

                                <>
                                  <div
                                    onClick={() => {
                                      handleOpenModal(currentPlain);
                                      setPlan_id(data?.subscription_plan_id);
                                      setPlan(data?.plan);
                                      setPlanName(data?.name);
                                      data.price > currentPlainPrice
                                        ? setUpgradestatus("Upgrade")
                                        : setUpgradestatus("Downgrade");
                                    }}
                                  >
                                    <button
                                      type='button'
                                      className={`btn btn2 ${
                                        data.name ==
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.name && "btn3"
                                      } `}
                                    >
                                      {currentPlainPrice
                                        ? data.name ==
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.name
                                          ? "Current"
                                          : data.price >
                                            get_Subscription_Status_Data
                                              ?.subscription?.subscription_plan
                                              ?.price
                                          ? "Upgrade"
                                          : "Downgrade"
                                        : "Upgrade"}
                                    </button>
                                  </div>
                                </>
                                // </a>
                              )

                              // )
                            }
                            <div>
                              <button
                                className='moreInfo'
                                onClick={() => {
                                  setMoreInfo(!moreInfo);
                                  setMoreInfoIndex(i);
                                  // setPlanType(data.name);
                                  setSlugType(data.slug);
                                }}
                              >
                                More Info
                              </button>
                            </div>
                          </div>

                          {i == 0 && (
                            <div className='pricingTable'>
                              {subscriptionBenefit?.map((data, i) =>
                                data.free == true ? (
                                  <div className='pricingTableRow'>
                                    <div className='pricingData'>
                                      <i
                                        className='fa fa-check'
                                        aria-hidden='true'
                                      ></i>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='pricingTableRow'>
                                    <div className='pricingData'>
                                      <strong>{data.free}</strong>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                          {i == 1 && (
                            <div className='pricingTable'>
                              {subscriptionBenefit?.map((data, i) =>
                                data.gold == true ? (
                                  <div className='pricingTableRow'>
                                    <div className='pricingData'>
                                      <i
                                        className='fa fa-check'
                                        aria-hidden='true'
                                      ></i>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='pricingTableRow'>
                                    <div className='pricingData'>
                                      <strong>{data.gold}</strong>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}

                          {i == 2 && (
                            <div className='pricingTable'>
                              {subscriptionBenefit?.map((data, i) =>
                                data.gold == true ? (
                                  <div className='pricingTableRow'>
                                    <div className='pricingData'>
                                      <i
                                        className='fa fa-check'
                                        aria-hidden='true'
                                      ></i>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='pricingTableRow'>
                                    <div className='pricingData'>
                                      <strong>{data.platinum}</strong>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
  
                          {i == 3 && (
                            <div className='pricingTable'>
                              {subscriptionBenefit?.map((data, i) =>
                                data.gold == true ? (
                                  <div className='pricingTableRow'>
                                    <div className='pricingData'>
                                      <i
                                        className='fa fa-check'
                                        aria-hidden='true'
                                      ></i>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='pricingTableRow'>
                                    <div className='pricingData'>
                                      <strong>{data.diamond}</strong>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}

                  {isSwitchOn &&
                    subscriptionData?.yearly_plans?.map((data, i) => {
                      let currentPlain=data.name ==
                      get_Subscription_Status_Data
                        ?.subscription?.subscription_plan
                        ?.name
                      && "Current"
                      return (
                        <div className='subscriptionCard'>
               

                          <p>{data.name} Membership</p>
                          <h4>
                            ${parseFloat(data.price).toFixed(2)}/
                            {data.plan.slice(0, 2)}
                          </h4>
                          <div className='memberButton pricingTableRow'>
                            {data.price ==
                            get_Subscription_Status_Data?.subscription
                              ?.subscription_plan?.price ? (
                              
                                <button
                                  onClick={() => {
                                    setPlan_id(data?.subscription_plan_id);
                                    setPlan(data?.plan);
                                    setPlanName(data?.name);
                                  }}
                                  type='button'
                                  className={`btn btn2 ${
                                    data.name ==
                                      get_Subscription_Status_Data?.subscription
                                        ?.subscription_plan?.name && "btn3"
                                  } `}
                                >
                                  {data.name ==
                                  get_Subscription_Status_Data?.subscription
                                    ?.subscription_plan?.name
                                    ? "Current"
                                    : data.price >
                                      get_Subscription_Status_Data?.subscription
                                        ?.subscription_plan?.price
                                    ? "Upgrade"
                                    : "Downgrade"}
                                </button>
                            
                            ) : data.name == "Free" ? (
                              <div
                                onClick={() => {
                                  handleOpenModal(currentPlain);
                                  setPlan_id(data?.subscription_plan_id);
                                  setPlan(data?.plan);
                                  setPlanName(data?.name);
                                  data.price > currentPlainPrice
                                    ? setUpgradestatus("Upgrade")
                                    : setUpgradestatus("Downgrade");
                                }}
                              >
                                <button
                                  type='button'
                                  className={`btn btn2 ${
                                    data.name ==
                                      get_Subscription_Status_Data?.subscription
                                        ?.subscription_plan?.name && "btn3"
                                  } `}
                                >
                                  {currentPlainPrice
                                    ? data.name ==
                                      get_Subscription_Status_Data?.subscription
                                        ?.subscription_plan?.name
                                      ? "Current"
                                      : data.price >
                                        get_Subscription_Status_Data
                                          ?.subscription?.subscription_plan
                                          ?.price
                                      ? "Upgrade"
                                      : "Downgrade"
                                    : "Upgrade"}
                                </button>
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  handleOpenModal(currentPlain);
                                  setPlan_id(data?.subscription_plan_id);
                                  setPlan(data?.plan);
                                  setPlanName(data?.name);
                                  data.price > currentPlainPrice
                                    ? setUpgradestatus("Upgrade")
                                    : setUpgradestatus("Downgrade");
                                }}
                              >
                                <button
                                  type='button'
                                  className={`btn btn2 ${
                                    data.name ==
                                      get_Subscription_Status_Data?.subscription
                                        ?.subscription_plan?.name && "btn3"
                                  } `}
                                >
                                  {currentPlainPrice
                                    ? data.name ==
                                      get_Subscription_Status_Data?.subscription
                                        ?.subscription_plan?.name
                                      ? "Current"
                                      : data.price >
                                        get_Subscription_Status_Data
                                          ?.subscription?.subscription_plan
                                          ?.price
                                      ? "Upgrade"
                                      : "Downgrade"
                                    : "Upgrade"}
                                </button>
                              </div>
                            )}
                            <div>
                              <button
                                className='moreInfo'
                                onClick={() => {
                                  setMoreInfo(!moreInfo);
                                  setMoreInfoIndex(data.price);
                                  // setPlanType(data.name);
                                  setSlugType(data.slug)

                                }}
                              >
                                More Info
                              </button>
                            </div>
                          </div>
                          <div className='pricingTable'>
                            {i == 0 && (
                              <div className='pricingTable'>
                                {subscriptionBenefit?.map((data, i) =>
                                  data.free == true ? (
                                    <div className='pricingTableRow'>
                                      <div className='pricingData'>
                                        <i
                                          className='fa fa-check'
                                          aria-hidden='true'
                                        ></i>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='pricingTableRow'>
                                      <div className='pricingData'>
                                        <strong>{data.free}</strong>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                            {i == 1 && (
                              <div className='pricingTable'>
                                {subscriptionBenefit?.map((data, i) =>
                                  data.gold == true ? (
                                    <div className='pricingTableRow'>
                                      <div className='pricingData'>
                                        <i
                                          className='fa fa-check'
                                          aria-hidden='true'
                                        ></i>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='pricingTableRow'>
                                      <div className='pricingData'>
                                        <strong>{data.gold}</strong>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {i == 2 && (
                              <div className='pricingTable'>
                                {subscriptionBenefit?.map((data, i) =>
                                  data.gold == true ? (
                                    <div className='pricingTableRow'>
                                      <div className='pricingData'>
                                        <i
                                          className='fa fa-check'
                                          aria-hidden='true'
                                        ></i>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='pricingTableRow'>
                                      <div className='pricingData'>
                                        <strong>{data.platinum}</strong>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {i == 3 && (
                              <div className='pricingTable'>
                                {subscriptionBenefit?.map((data, i) =>
                                  data.gold == true ? (
                                    <div className='pricingTableRow'>
                                      <div className='pricingData'>
                                        <i
                                          className='fa fa-check'
                                          aria-hidden='true'
                                        ></i>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='pricingTableRow'>
                                      <div className='pricingData'>
                                        <strong>{data.diamond}</strong>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Free mebership modal start */}

        <Modal
          className='fullWidthModal'
          ariaHideApp={false}
          isOpen={moreInfo}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "4px",
              padding: "0",
              // outline: 'none',
            },
          }}
        >
          <div className='popup_header flex justify-between items-center'>
            <h4 className='subs-modal-title'>
              {membershipTitles[slugType]}
            {/* {slugType == "free" && "Free Membership"}
              {slugType == "monthly_glod" && "Gold Membership"}
              {slugType == "monthly_platinum" && "Platinum Membership"}
              {slugType == "monthly_diamond" && "Diamond Membership"}
            
              {slugType == "yearly_glod" && "Gold Membership"}
              {slugType == "yearly_platinum" && "Platinum Membership"}
              {slugType == "yearly_diamond" && "Diamond Membership"} */}
            </h4>
            <button className='close_btn--modal' onClick={() => setMoreInfo()}>
              {lang?.close}
            </button>
          </div>
          <div className='modal-body'>
            <div className='card card--block modal_box modalLessGap paymentView'>
              {subscriptionData?.monthly_plans?.map((data, i) => {
                console.log(data,"data")
                
                return (
                  moreInfoIndex == i && (
                    <div className='pageRow'>
                      <div className='leftBlock'>
                        <div
                          className={`roundedCard ${
                            (data?.name == "Free" && "free") ||
                            (data?.name == "Gold" && "gold") ||
                            (data?.name == "Platinum" && "platinum") ||
                            (data?.name == "Diamond" && "diamond")
                          }`}
                        >
                          <h4
                            className={` ${
                              (data?.name == "Platinum" && "text-green-500") ||
                              (data?.name == "Diamond" && "text-green-500")
                            }`}
                          >
                            {(data?.name == "Free" &&
                              "Your free access to US!") ||
                              (data.name == "Gold" && "Time to Market!") ||
                              (data.name == "Platinum" && "Land and Expand!") ||
                              (data.name == "Diamond" && "Exponential growth!")}
                          </h4>
                          <div className='btn btnBlueSecondary'>
                            {(data.name == "Free" && "Free Membership") ||
                              (data.name == "Gold" &&
                                `Price ${data.price}$ /${data.plan.slice(
                                  0,
                                  5
                                )}`) ||
                              (data.name == "Platinum" &&
                                `Price ${data.price}$ /${data.plan.slice(
                                  0,
                                  5
                                )}`) ||
                              (data.name == "Diamond" &&
                                `Price ${data.price}$ /${data.plan.slice(
                                  0,
                                  5
                                )}`)}
                          </div>
                          <p className='subsDescription'>
                            {(data.name == "Free" &&
                              `By registering before December 31st 2024, you will get all Diamond features included until June 30th 2025.`) ||
                              (data.name == "Gold" &&
                                `Pay as little as $${parseFloat(
                                  subscriptionData?.yearly_plans?.[1].price / 12
                                ).toFixed(2)}
                                * / month when billed annually. Cancel anytime. We'll remind you 7 days before your trial ends.`) ||
                              (data.name == "Platinum" &&
                                `Pay as little as $${parseFloat(
                                  subscriptionData?.yearly_plans?.[2].price / 12
                                ).toFixed(
                                  2
                                )}* / month when billed annually. Cancel anytime. We'll remind you 7 days before your trial ends.`) ||
                              (data.name == "Diamond" &&
                                `Pay as little as $${parseFloat(
                                  subscriptionData?.yearly_plans?.[3].price / 12
                                ).toFixed(
                                  2
                                )}* / month when billed annually. Cancel anytime. We'll remind you 7 days before your trial ends.`)}{" "}
                          </p>
                          {/* <p className='subsDescription'>{data.name=="Free"&&`By registering before ${get_Subscription_Status_Data?.subscription?.start_date}, you will get all Gold features included until ${get_Subscription_Status_Data?.subscription?.end_date}.`||data.name=="Gold"&&`Pay as little as $${parseFloat(data.price).toFixed(2)}* / month when billed annually. Cancel anytime. We'll remind you 7 days before your trial ends.`||data.name=="Platinum"&&`Pay as little as $${parseFloat(data.price).toFixed(2)}* / month when billed annually. Cancel anytime. We'll remind you 7 days before your trial ends.`||data.name=="Diamond"&& `Pay as little as $${parseFloat(data.price).toFixed(2)}* / month when billed annually. Cancel anytime. We'll remind you 7 days before your trial ends.`} </p> */}
                          <div className='cardBottom'>
                            <div className='secureCheckout font-18 text-black'>
                              <span>
                                <i
                                  className='fa fa-lock'
                                  aria-hidden='true'
                                ></i>
                              </span>
                              Secure checkout
                            </div>
                            <p className='fs-18'>
                              Your security is important to us. When you proceed
                              to checkout, we'll encrypt your personal
                              information when sending it over the internet or
                              storing it.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='rightBlock'>
                        <div className='pplanList'>
                          <div className='listTitle'>
                            <div className='repeatList'>
                              <h6>
                                {data?.name == "Free" &&
                                  "Company Profile and On-Line Store"}
                                {data.name !== "Free" &&
                                  `${
                                    data.buyer_sourcing_engine == -1
                                      ? ""
                                      : "Up to"
                                  } ${
                                    data.buyer_sourcing_engine == -1
                                      ? "Unlimited"
                                      : data.buyer_sourcing_engine
                                  } Buyer Sourcing Engine Leads ${
                                    data.buyer_sourcing_engine == -1
                                      ? ""
                                      : "per month"
                                  } `}
                              </h6>
                              <h6></h6>
                              <ul>
                                {data.name == "Free" && (
                                  <>
                                    <li>
                                      Create a professional profile that best
                                      represents your Company, providing correct
                                      and detailed information. Furthermore,
                                      since Alysei is also a B2C social
                                      platform, a complete company profile will
                                      receive more attention and positive
                                      reviews from users.
                                    </li>
                                    <li>
                                      The Marketplace for Italian Producers is
                                      your place of business, an environment
                                      where you can create your online store,
                                      exhibit your products in the shop window,
                                      be contacted by possible Importers,
                                      Distributors, Restaurants, Voice of
                                      Experts.
                                    </li>
                                  </>
                                )}
                                {data.name == "Gold" && (
                                  <li>
                                    By signing up this membership, you will be
                                    able to be discovered by Buyers around the
                                    world in search of the most authentic
                                    Regional Italian Products. This membership
                                    will allow you to manage up to{" "}
                                    {data.buyer_sourcing_engine == -1
                                      ? "unlimited"
                                      : data?.buyer_sourcing_engine}{" "}
                                    new Leads every month.
                                  </li>
                                )}
                                {data.name == "Platinum" && (
                                  <li>
                                    By signing up this membership, you will be
                                    able to be discovered by Buyers around the
                                    world in search of the most authentic
                                    Regional Italian Products. This membership
                                    will allow you to manage up to{" "}
                                    {data.buyer_sourcing_engine == -1
                                      ? "unlimited"
                                      : data?.buyer_sourcing_engine}{" "}
                                    new Leads every month.
                                  </li>
                                )}
                                {data.name == "Diamond" && (
                                  <li>
                                    By signing up this membership, you will be
                                    able to be discovered by Buyers around the
                                    world in search of the most authentic
                                    Regional Italian Products. This membership
                                    will allow you{" "}
                                    {data.buyer_sourcing_engine == -1
                                      ? "unlimited"
                                      : data?.buyer_sourcing_engine}{" "}
                                    new Leads from Buyers.
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div className='repeatList'>
                              {data.name == "Diamond" && (
                                <>
                                  <h6>
                                    Access to Members contact information{" "}
                                  </h6>
                                  <ul>
                                    <li>
                                      The Diamond Membership will allow Italian
                                      Producers to access other Members contact
                                      information through their Company Profile
                                      home page in Alysei under the “Contact”
                                      section.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>
                            <div className='repeatList'>
                              {data.name !== "Diamond" && (
                                <>
                                  <h6>
                                    Subscription up to{" "}
                                    {data.hubs_subscription == -1
                                      ? "Unlimited"
                                      : data.hubs_subscription}{" "}
                                    Hubs{" "}
                                  </h6>
                                  <ul>
                                    <li>
                                      The Hub identifies a geographical area
                                      developed around a metropolitan district,
                                      the nerve center of business, network, and
                                      development activities. The Hub offers you
                                      visibility within the Alysei platform,
                                      which is essential for creating business
                                      collaborations and for being easily
                                      accessible by your target market.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>
                            <div className='repeatList'>
                              {(data.name = "Free") && (
                                <>
                                  <h6>
                                    {data.b2b_connection_importer == -1
                                      ? ""
                                      : "Up to "}{" "}
                                    {data.b2b_connection_importer == -1
                                      ? "Unlimited"
                                      : data.b2b_connection_importer}{" "}
                                    connections with Importers and Distributors
                                  </h6>
                                  <ul>
                                    <li>
                                      Alysei B2B engine is designed to
                                      drastically reduce time to market cost
                                      providing a direct connection bridge with
                                      qualified Alysei Importers and
                                      Distributors. The Free Membership will
                                      allow you to search and connect with{" "}
                                      {data.b2b_connection_importer == -1
                                        ? ""
                                        : "up to "}{" "}
                                      {data.b2b_connection_importer == -1
                                        ? "Unlimited"
                                        : data.b2b_connection_importer}{" "}
                                      Alysei Importers and Distributors every
                                      month.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>

                            <div className='repeatList'>
                              {data.name == "Free" ||
                                (data.name == "Gold" && (
                                  <>
                                    <h6>
                                      {data.b2b_connection_importer == -1
                                        ? ""
                                        : "Up to "}{" "}
                                      {data.b2b_connection_importer == -1
                                        ? "Unlimited"
                                        : data.b2b_connection_importer}{" "}
                                      {data.name == "Free"
                                        ? "connections with Importers and Distributors"
                                        : "connections per month with Importers and Distributors"}
                                    </h6>
                                    <ul>
                                      <li>
                                        {data.name == "Free"
                                          ? `Alysei B2B engine is designed to drastically reduce time to market cost providing a direct connection bridge
                                                            with qualified Alysei Importers and Distributors. The Free Membership will allow you to search and connect
                                                            with up to ${
                                                              data.b2b_connection_importer ==
                                                              -1
                                                                ? "Unlimited"
                                                                : data.b2b_connection_importer
                                                            } Alysei Importers and Distributors every month.`
                                          : ` Alysei B2B engine is designed to drastically reduce time to market and cost providing a direct connection
                                                            bridge with qualified Alysei Importers and Distributors. The Gold Membership will allow you to search and
                                                            connect with up to ${
                                                              data.b2b_connection_importer ==
                                                              -1
                                                                ? "Unlimited"
                                                                : data.b2b_connection_importer
                                                            } Alysei Importers and Distributors every month.`}
                                      </li>
                                    </ul>
                                  </>
                                ))}
                            </div>

                            <div className='repeatList'>
                              <h6>
                                Unlimited connection with all{" "}
                                {data.name == "Platinum" ||
                                data.name == "Diamond"
                                  ? ""
                                  : "other Alysei"}{" "}
                                Members
                              </h6>
                              <ul>
                                <li>
                                  {data.name == "Platinum" ||
                                  data.name == "Diamond"
                                    ? `Alysei B2B engine is designed to drastically reduce time to market cost providing a direct connection bridge with
                                                            qualified Alysei Importers and Distributors. The Platinum Membership will offer you unlimited connection with
                                                            all Alysei Certified Members: Importers and Distributors, Italian Restaurants in US, Voice of Experts and Travel
                                                            Agencies.`
                                    : `B2B (Business to Business) is that section of Alysei dedicated to the collaboration and development of
                                                                commercial relations between the various Alysei Members to create development and business opportunities.
                                                                The Free Membership will allow you to search and connect with all Alysei Members with no restriction.
                                                                `}
                                </li>
                              </ul>
                            </div>
                            <div className='repeatList'>
                              <h6>
                                {data.product_limit == -1 ? "" : "Up to "}{" "}
                                {data.product_limit == -1
                                  ? "Unlimited"
                                  : data.product_limit}{" "}
                                Products in the B2B Marketplace
                              </h6>
                              <ul>
                                <li>
                                  {data.name == "Platinum" ||
                                  data.name == "Diamond"
                                    ? ` Through your Alysei on-line store, you will be able to create and display an ${
                                        data.product_limit == -1
                                          ? "Unlimited"
                                          : data.product_limit
                                      } numbers of products.`
                                    : `Through your Alysei on-line store, you will be able to create and display up to ${
                                        data.product_limit == -1
                                          ? "Unlimited"
                                          : data.product_limit
                                      } products.`}
                                </li>
                              </ul>
                            </div>

                            {/* <div className='repeatList'>
                                                {
                                                    data.name=="Diamond"&&
                                                    <>
                                                    <h6>{data.name=="Diamond"&&"10"}  Alysei branding promotion per month</h6>
                                                        <ul>
                                                            <li>
                                                            By signing up this membership, you will authorize Alysei to create up to {data.name=="Diamond"&&"10"} {data.name=="Gold"&&"2"}{data.name=="Platinum"&&"10"} posts shared in Alysei App and
                                                            through all active Alysei channels (Facebook, Instagram and Linkedin) to promote your Company, Products
                                                            and Brand.
                                                            </li>
                                                        </ul>
                                                    </>
                                                }
                                                
                                            </div> */}

                            <div className='repeatList'>
                              {(data.name == "Platinum" ||
                                data.name == "Diamond") && (
                                <>
                                  <h6>
                                    {data.name == "Platinum" &&
                                      data?.demo_per_month}{" "}
                                    {data.name == "Diamond" &&
                                      data?.demo_per_month}{" "}
                                    Alysei Academy Demo per month
                                  </h6>
                                  <ul>
                                    <li>
                                      By signing up this membership, you will
                                      authorize Alysei to promote your products{" "}
                                      {data.name == "Platinum" &&
                                        data?.demo_per_month_text}{" "}
                                      {data.name == "Diamond" &&
                                        data?.demo_per_month_text}{" "}
                                      a month during the Alysei Academy Demo
                                      live sessions. Product sample will have to
                                      be shipped to our Alysei headquarter in
                                      advance along with the specific guidelines
                                      to properly promote your products.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>

                            <div className='repeatList'>
                              {data.name !== "Free" && data.name !== "Gold" && (
                                <>
                                  <h6>
                                    Feature Product through Alysei Channel
                                  </h6>
                                  <ul>
                                    <li>
                                      By signing up this membership, you will
                                      authorize Alysei to feature your highest
                                      ranked product once a month through Alysei
                                      App and all our channels.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>

                            <div className='repeatList'>
                              {data.name !== "Free" && data.name !== "Gold" && (
                                <>
                                  <h6>
                                    {data.name == "Platinum" &&
                                      `${data.sailing_to_success_discount}%`}{" "}
                                    {data.name == "Diamond" &&
                                      `${data.sailing_to_success_discount}%`}{" "}
                                    discount on any “Sailing to Success” B2B
                                    Alysei event
                                  </h6>
                                  <ul>
                                    <li>
                                      For any Alysei future “Sailing to Success”
                                      B2B event, Alysei will provide a{" "}
                                      {data.name == "Platinum" &&
                                        `${data.sailing_to_success_discount}%`}{" "}
                                      {data.name == "Diamond" &&
                                        `${data.sailing_to_success_discount}%`}{" "}
                                      discount from the official price list.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>

                            <div className='repeatList'>
                              {data.name == "Free" && (
                                <>
                                  <h6>Unlimited number of posts</h6>
                                  <ul>
                                    <li>
                                      B2C (Business to Customer) is the Alysei
                                      dedicated section where Producers can
                                      reach their future Consumers. You will
                                      have the opportunity to create posts
                                      promoting your company, brand, products,
                                      initiatives to expand your reach.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
              {subscriptionData?.yearly_plans?.map((data, i) => {
                return (
                  data.price !== 0 &&
                  data.price == moreInfoIndex && (
                    <div className='pageRow'>
                      <div className='leftBlock'>
                        <div
                          className={`roundedCard ${
                            (data?.name == "Free" && "free") ||
                            (data.name == "Gold" && "gold") ||
                            (data.name == "Platinum" && "platinum") ||
                            (data.name == "Diamond" && "diamond")
                          }`}
                        >
                          <h4>
                            {(data.name == "Free" &&
                              "Your free access to US!") ||
                              (data.name == "Gold" && "Time to Market!") ||
                              (data.name == "Platinum" && "Land and Expand!") ||
                              (data.name == "Diamond" && "Exponential growth!")}
                          </h4>
                          <div className='btn btnBlueSecondary'>
                            {(data.name == "Free" && "Free Membership") ||
                              (data.name == "Gold" &&
                                `Price ${data.price}$ /${data.plan.slice(
                                  0,
                                  4
                                )}`) ||
                              (data.name == "Platinum" &&
                                `Price ${data.price}$ /${data.plan.slice(
                                  0,
                                  4
                                )}`) ||
                              (data.name == "Diamond" &&
                                `Price ${data.price}$ /${data.plan.slice(
                                  0,
                                  4
                                )}`)}
                          </div>
                          <p className='subsDescription'>
                            {(data.name == "Free" &&
                              `By registering before ${get_Subscription_Status_Data?.subscription?.start_date}, you will get all Gold features included until ${get_Subscription_Status_Data?.subscription?.start_date}.`) ||
                              (data.name == "Gold" &&
                                `Pay as little as $${parseFloat(
                                  data.price / 12
                                ).toFixed(
                                  2
                                )}* / month when billed annually. Cancel anytime. We'll remind you 7 days before your trial ends.`) ||
                              (data.name == "Platinum" &&
                                `Pay as little as $${parseFloat(
                                  data.price / 12
                                ).toFixed(
                                  2
                                )}* / month when billed annually. Cancel anytime. We'll remind you 7 days before your trial ends.`) ||
                              (data.name == "Diamond" &&
                                `Pay as little as $${parseFloat(
                                  data.price / 12
                                ).toFixed(
                                  2
                                )}* / month when billed annually. Cancel anytime. We'll remind you 7 days before your trial ends.`)}{" "}
                          </p>
                          <div className='cardBottom'>
                            <div className='secureCheckout font-18 text-black'>
                              <span>
                                <i
                                  className='fa fa-lock'
                                  aria-hidden='true'
                                ></i>
                              </span>
                              Secure checkout
                            </div>
                            <p className='fs-18'>
                              Your security is important to us. When you X
                              proceed to checkout, we'll encrypt your personal
                              information when sending it over the internet or
                              storing it.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='rightBlock'>
                        <div className='pplanList'>
                          <div className='listTitle'>
                            <div className='repeatList'>
                              <h6>
                                {data?.name == "Free" &&
                                  "Company Profile and On-Line Store"}
                                {data.name !== "Free" &&
                                  `${
                                    data.buyer_sourcing_engine == -1
                                      ? ""
                                      : "Up to"
                                  } ${
                                    data.buyer_sourcing_engine == -1
                                      ? "Unlimited"
                                      : data.buyer_sourcing_engine
                                  } Buyer Sourcing Engine Leads ${
                                    data.buyer_sourcing_engine == -1
                                      ? ""
                                      : "per month"
                                  } `}
                              </h6>
                              <h6></h6>
                              <ul>
                                {data.name == "Free" && (
                                  <>
                                    <li>
                                      Create a professional profile that best
                                      represents your Company, providing correct
                                      and detailed information. Furthermore,
                                      since Alysei is also a B2C social
                                      platform, a complete company profile will
                                      receive more attention and positive
                                      reviews from users.
                                    </li>
                                    <li>
                                      The Marketplace for Italian Producers is
                                      your place of business, an environment
                                      where you can create your online store,
                                      exhibit your products in the shop window,
                                      be contacted by possible Importers,
                                      Distributors, Restaurants, Voice of
                                      Experts.
                                    </li>
                                  </>
                                )}
                                {data.name == "Gold" && (
                                  <li>
                                    By signing up this membership, you will be
                                    able to be discovered by Buyers around the
                                    world in search of the most authentic
                                    Regional Italian Products. This membership
                                    will allow you to manage up to{" "}
                                    {data.buyer_sourcing_engine == -1
                                      ? "unlimited"
                                      : data?.buyer_sourcing_engine}{" "}
                                    new Leads every month.
                                  </li>
                                )}
                                {data.name == "Platinum" && (
                                  <li>
                                    By signing up this membership, you will be
                                    able to be discovered by Buyers around the
                                    world in search of the most authentic
                                    Regional Italian Products. This membership
                                    will allow you to manage up to{" "}
                                    {data.buyer_sourcing_engine == -1
                                      ? "unlimited"
                                      : data?.buyer_sourcing_engine}{" "}
                                    new Leads every month.
                                  </li>
                                )}
                                {data.name == "Diamond" && (
                                  <li>
                                    By signing up this membership, you will be
                                    able to be discovered by Buyers around the
                                    world in search of the most authentic
                                    Regional Italian Products. This membership
                                    will allow you{" "}
                                    {data.buyer_sourcing_engine == -1
                                      ? "unlimited"
                                      : data?.buyer_sourcing_engine}{" "}
                                    new Leads from Buyers.
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div className='repeatList'>
                              {data.name == "Diamond" && (
                                <>
                                  <h6>
                                    Access to Members contact information{" "}
                                  </h6>
                                  <ul>
                                    <li>
                                      The Diamond Membership will allow Italian
                                      Producers to access other Members contact
                                      information through their Company Profile
                                      home page in Alysei under the “Contact”
                                      section.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>
                            <div className='repeatList'>
                              {data.name !== "Diamond" && (
                                <>
                                  <h6>
                                    Subscription up to{" "}
                                    {data.hubs_subscription == -1
                                      ? "Unlimited"
                                      : data.hubs_subscription}{" "}
                                    Hubs{" "}
                                  </h6>
                                  <ul>
                                    <li>
                                      The Hub identifies a geographical area
                                      developed around a metropolitan district,
                                      the nerve center of business, network, and
                                      development activities. The Hub offers you
                                      visibility within the Alysei platform,
                                      which is essential for creating business
                                      collaborations and for being easily
                                      accessible by your target market.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>

                            <div className='repeatList'>
                              {data.name !== "Diamond" && (
                                <>
                                  <h6>
                                    Subscription up to{" "}
                                    {data.hubs_subscription == -1
                                      ? "Unlimited"
                                      : data.hubs_subscription}{" "}
                                    Hubs{" "}
                                  </h6>
                                  <ul>
                                    <li>
                                      The Hub identifies a geographical area
                                      developed around a metropolitan district,
                                      the nerve center of business, network, and
                                      development activities. The Hub offers you
                                      visibility within the Alysei platform,
                                      which is essential for creating business
                                      collaborations and for being easily
                                      accessible by your target market.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>
                            <div className='repeatList'>
                              {data.name == "Free" && (
                                <>
                                  <h6>
                                    {data.b2b_connection_importer == -1
                                      ? ""
                                      : "Up to "}{" "}
                                    {data.b2b_connection_importer == -1
                                      ? "Unlimited"
                                      : data.b2b_connection_importer}{" "}
                                    connections with Importers and Distributors
                                  </h6>
                                  <ul>
                                    <li>
                                      Alysei B2B engine is designed to
                                      drastically reduce time to market cost
                                      providing a direct connection bridge with
                                      qualified Alysei Importers and
                                      Distributors. The Free Membership will
                                      allow you to search and connect with{" "}
                                      {data.b2b_connection_importer == -1
                                        ? ""
                                        : "up to "}{" "}
                                      {data.b2b_connection_importer == -1
                                        ? "Unlimited"
                                        : data.b2b_connection_importer}{" "}
                                      Alysei Importers and Distributors every
                                      month.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>

                            <div className='repeatList'>
                              {data.name == "Free" ||
                                (data.name == "Gold" && (
                                  <>
                                    <h6>
                                      {data.b2b_connection_importer == -1
                                        ? ""
                                        : "Up to "}{" "}
                                      {data.b2b_connection_importer == -1
                                        ? "Unlimited"
                                        : data.b2b_connection_importer}{" "}
                                      {data.name == "Free"
                                        ? "connections with Importers and Distributors"
                                        : "connections per month with Importers and Distributors"}
                                    </h6>
                                    <ul>
                                      <li>
                                        {data.name == "Free"
                                          ? `Alysei B2B engine is designed to drastically reduce time to market cost providing a direct connection bridge
                                                            with qualified Alysei Importers and Distributors. The Free Membership will allow you to search and connect
                                                            with up to ${
                                                              data.b2b_connection_importer ==
                                                              -1
                                                                ? "Unlimited"
                                                                : data.b2b_connection_importer
                                                            } Alysei Importers and Distributors every month.`
                                          : ` Alysei B2B engine is designed to drastically reduce time to market and cost providing a direct connection
                                                            bridge with qualified Alysei Importers and Distributors. The Gold Membership will allow you to search and
                                                            connect with up to ${
                                                              data.b2b_connection_importer ==
                                                              -1
                                                                ? "Unlimited"
                                                                : data.b2b_connection_importer
                                                            } Alysei Importers and Distributors every month.`}
                                      </li>
                                    </ul>
                                  </>
                                ))}
                            </div>

                            <div className='repeatList'>
                              <h6>
                                Unlimited connection with all{" "}
                                {data.name == "Platinum" ||
                                data.name == "Diamond"
                                  ? ""
                                  : "other Alysei"}{" "}
                                Members
                              </h6>
                              <ul>
                                <li>
                                  {data.name == "Platinum" ||
                                  data.name == "Diamond"
                                    ? `Alysei B2B engine is designed to drastically reduce time to market cost providing a direct connection bridge with
                                                            qualified Alysei Importers and Distributors. The Platinum Membership will offer you unlimited connection with
                                                            all Alysei Certified Members: Importers and Distributors, Italian Restaurants in US, Voice of Experts and Travel
                                                            Agencies.`
                                    : `B2B (Business to Business) is that section of Alysei dedicated to the collaboration and development of
                                                                commercial relations between the various Alysei Members to create development and business opportunities.
                                                                The Free Membership will allow you to search and connect with all Alysei Members with no restriction.
                                                                `}
                                </li>
                              </ul>
                            </div>
                            <div className='repeatList'>
                              <h6>
                                {data.product_limit == -1 ? "" : "Up to "}{" "}
                                {data.product_limit == -1
                                  ? "Unlimited"
                                  : data.product_limit}{" "}
                                Products in the B2B Marketplace
                              </h6>
                              <ul>
                                <li>
                                  {data.name == "Platinum" ||
                                  data.name == "Diamond"
                                    ? ` Through your Alysei on-line store, you will be able to create and display an ${
                                        data.product_limit == -1
                                          ? "Unlimited"
                                          : data.product_limit
                                      } numbers of products.`
                                    : `Through your Alysei on-line store, you will be able to create and display up to ${
                                        data.product_limit == -1
                                          ? "Unlimited"
                                          : data.product_limit
                                      } products.`}
                                </li>
                              </ul>
                            </div>
                            {/* <div className='repeatList'>
                                                {
                                                    data.name=="Diamond"&&
                                                    <>
                                                    <h6>{data.name=="Diamond"&&"10"} Alysei branding promotion per month</h6>
                                                        <ul>
                                                            <li>
                                                            By signing up this membership, you will authorize Alysei to create up to {data.name=="Diamond"&&"10"} {data.name=="Gold"&&"2"}{data.name=="Platinum"&&"10"} posts shared in Alysei App and
                                                            through all active Alysei channels (Facebook, Instagram and Linkedin) to promote your Company, Products
                                                            and Brand.
                                                            </li>
                                                        </ul>
                                                    </>
                                                }
                                                
                                            </div> */}

                            <div className='repeatList'>
                              {(data.name == "Diamond" ||
                                data.name == "Platinum") && (
                                <>
                                  <h6>
                                    {data.name == "Platinum" &&
                                      data?.demo_per_month}{" "}
                                    {data.name == "Diamond" &&
                                      data?.demo_per_month}{" "}
                                    Alysei Academy Demo per month
                                  </h6>
                                  <ul>
                                    <li>
                                      By signing up this membership, you will
                                      authorize Alysei to promote your products{" "}
                                      {data.name == "Platinum" &&
                                        data?.demo_per_month_text}{" "}
                                      {data.name == "Diamond" &&
                                        data?.demo_per_month_text}{" "}
                                      a month during the Alysei Academy Demo
                                      live sessions. Product sample will have to
                                      be shipped to our Alysei headquarter in
                                      advance along with the specific guidelines
                                      to properly promote your products.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>

                            <div className='repeatList'>
                              {data.name !== "Free" && data.name !== "Gold" && (
                                <>
                                  <h6>
                                    Feature Product through Alysei Channel
                                  </h6>
                                  <ul>
                                    <li>
                                      By signing up this membership, you will
                                      authorize Alysei to feature your highest
                                      ranked product once a month through Alysei
                                      App and all our channels.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>

                            <div className='repeatList'>
                              {data.name !== "Free" && data.name !== "Gold" && (
                                <>
                                  <h6>
                                    {data.name == "Platinum" &&
                                      `${data.sailing_to_success_discount}%`}{" "}
                                    {data.name == "Diamond" &&
                                      `${data.sailing_to_success_discount}%`}{" "}
                                    discount on any “Sailing to Success” B2B
                                    Alysei event
                                  </h6>
                                  <ul>
                                    <li>
                                      For any Alysei future “Sailing to Success”
                                      B2B event, Alysei will provide a{" "}
                                      {data.name == "Platinum" &&
                                        `${data.sailing_to_success_discount}%`}{" "}
                                      {data.name == "Diamond" &&
                                        `${data.sailing_to_success_discount}%`}{" "}
                                      discount from the official price list.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>

                            <div className='repeatList'>
                              {data.name == "Free" && (
                                <>
                                  <h6>Unlimited number of posts</h6>
                                  <ul>
                                    <li>
                                      B2C (Business to Customer) is the Alysei
                                      dedicated section where Producers can
                                      reach their future Consumers. You will
                                      have the opportunity to create posts
                                      promoting your company, brand, products,
                                      initiatives to expand your reach.
                                    </li>
                                  </ul>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modalOpen}
          // onRequestClose={handleCloseModal}
          contentLabel='Upgrade Modal'
          className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg text-black text-center'
          overlayClassName='fixed inset-0 bg-black bg-opacity-50'
        >
          <span
            className='absolute top-2 right-2 text-base'
            onClick={() => handleCloseModal()}
          >
            <i className='fa fa-times cursor-pointer '></i>
          </span>
          <div className='w-16 m-auto mb-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              enable-background='new 0 0 47.5 47.5'
              viewBox='0 0 47.5 47.5'
              id='diamond'
            >
              <path
                fill='#bdddf4'
                d='M0 0h-6l-7-9h10l3 9z'
                transform='matrix(1.25 0 0 -1.25 17.5 5)'
              ></path>
              <path
                fill='#5dadec'
                d='m0 0-7 9h-6l3-9H0z'
                transform='matrix(1.25 0 0 -1.25 46.25 16.25)'
              ></path>
              <path
                fill='#4289c1'
                d='M0 0h10L-8-21 0 0Z'
                transform='matrix(1.25 0 0 -1.25 33.75 16.25)'
              ></path>
              <path
                fill='#8ccaf7'
                d='M0 0h-10L8-21 0 0Z'
                transform='matrix(1.25 0 0 -1.25 13.75 16.25)'
              ></path>
              <path
                fill='#8ccaf7'
                d='m0 0-3-9h16l-3 9H0Z'
                transform='matrix(1.25 0 0 -1.25 17.5 5)'
              ></path>
              <path
                fill='#5dadec'
                d='m0 0-8 21H8L0 0Z'
                transform='matrix(1.25 0 0 -1.25 23.75 42.5)'
              ></path>
            </svg>
          </div>
          <h2 className='text-2xl font-bold mb-4  '>
            {currentPlainPrice ? upgradeStatus : "Upgrade"} Your Plan
          </h2>

          <p>
            <p>
              Are you sure you want to {cancel ? "cancel" : "Downgrade"} your
              Subscription?
            </p>
            <br />
            <span className=' text-black'>
              {/* Are you sure you want to {currentPlainPrice ? upgradeStatus : "Upgrade"} your Current Plan? */}
              {cancel
                ? `

                  Your subscription will be cancelled upon expiration of the current Plan on ${
                    planExpireDate && planExpireDate
                      ? moment(planExpireDate).format("MMM Do YYYY")
                      : "June 30th 2025"
                  }. 
                  Until that date, you will be able to take advantage of the benefits of the current Plan.`
                : `
                 
                  
                  The new Membership will be activated upon expiration of the current Plan on ${
                    planExpireDate && planExpireDate
                      ? moment(planExpireDate).format("MMM Do YYYY")
                      : "June 30th 2025"
                  }. 
                  Until that date, you will be able to take advantage of the benefits of the current Plan.`}
            </span>
          </p>
          {redirectUrl == "free" ? (
            <div
              onClick={() => {
                handleSubscriptionStatically();
              }}
            >
              <button className='btn btn-blue stripe text-white font-bold mt-6'>
                {/* {currentPlainPrice ? upgradeStatus : "Upgrade"}{" "} */}
                {userSettingSpinner ? <FormSpinner /> : "Ok"}
              </button>
            </div>
          ) : (
            // <div onClick={() => freeSubscription()}>
            //   <button className="btn btn-blue stripe text-white font-bold mt-6">
            //     {currentPlainPrice?upgradeStatus:"Upgrade"}{" "}
            //   </button>
            // </div>
            <div >
              <button className='btn btn-blue stripe text-white font-bold mt-6' onClick={() => handleSubscriptionStatically()}>
                {/* {currentPlainPrice ? upgradeStatus : "Upgrade"}{" "} */}
                {userSettingSpinner ? <FormSpinner /> : "Ok"}
              </button>
            </div>
            // <a href={redirectUrl}>
            //   <button className="btn btn-blue stripe text-white font-bold mt-6">
            //     {currentPlainPrice?upgradeStatus:"Upgrade"}{" "}
            //   </button>
            // </a>
          )}
        </Modal>
        {/* Free mebership modal end */}

        <Modal
          isOpen={isThank}
          // onRequestClose={handleThankYouModal(false)}
          contentLabel='Confirm Modal'
          className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg text-black text-center'
          overlayClassName='fixed inset-0 bg-black bg-opacity-50'
        >
          <div className='w-25 m-auto mb-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              width='96'
              height='96'
              viewBox='0 0 48 48'
            >
              <linearGradient
                id='IMoH7gpu5un5Dx2vID39Ra_pIPl8tqh3igN_gr1'
                x1='9.858'
                x2='38.142'
                y1='9.858'
                y2='38.142'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stop-color='#9dffce'></stop>
                <stop offset='1' stop-color='#50d18d'></stop>
              </linearGradient>
              <path
                fill='url(#IMoH7gpu5un5Dx2vID39Ra_pIPl8tqh3igN_gr1)'
                d='M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z'
              ></path>
              <linearGradient
                id='IMoH7gpu5un5Dx2vID39Rb_pIPl8tqh3igN_gr2'
                x1='13'
                x2='36'
                y1='24.793'
                y2='24.793'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='.824' stop-color='#135d36'></stop>
                <stop offset='.931' stop-color='#125933'></stop>
                <stop offset='1' stop-color='#11522f'></stop>
              </linearGradient>
              <path
                fill='url(#IMoH7gpu5un5Dx2vID39Rb_pIPl8tqh3igN_gr2)'
                d='M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414 c0.391-0.391,1.024-0.391,1.414,0L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414  c0.391,0.391,0.391,1.024,0,1.414l-13,13C22.317,33.098,21.683,33.098,21.293,32.707z'
              ></path>
            </svg>
          </div>
          <h2 className='text-2xl font-bold mb-4  '>{/* ThankYou  */}</h2>

          <p>
            <span className=' text-black'>
              Your Request has been accepted successfully.
              <br />
              {cancel
                ? ` You have requested to cancel your Plan which will be cancel upon expiration of you current Plan"`
                : `You have requested to downgrade your Plan to ${planName} which will be activated upon expiration of your current Plan.`}
            </span>
          </p>
          <button
            className='btn btn-blue stripe text-white font-bold mt-6'
            onClick={() => handleThankYouModal(false)}
          >
            Close
          </button>
        </Modal>
      </div>
    </>
  );
}
