import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import language from "../../../helpers/language";
import NewsStatus from "../../discover/components/NewsStatus";
import fdaBg from '../../../assets/images/fda-bg.svg'
import fdaBg2 from '../../../assets/images/cibecco-bg2.svg'
import fdalogo from '../../../assets/images/fda-logo.svg'
import cibeccologo from '../../../assets/images/cibecco-logo-new.svg'
import cibecco from '../../../assets/images/cibecco.png'


export default function HomeRightBlock() {
  const [lang] = useState(language.getLang());
  const [openNewsModal, setOpenNewsModal] = useState(false);
  const [FDAcirtificate, setFDAcirtificate] = useState(false);
  const [Cibeccocirtificate, setCibeccocirtificate] = useState(false);
  const { discover_alysei, getActivitySpinner } = useSelector((state) => state.activityFeedStatus);

  useEffect(() => {
    (FDAcirtificate || Cibeccocirtificate) ? document.body.classList.add("modalClose") : document.body.classList.remove("modalClose")
    return (() => document.body.classList.remove("modalClose"))
  }, [FDAcirtificate, Cibeccocirtificate]);

   return (
    <div className="card card--block">
      <h4 className="page__title">{lang?.Discovery} {lang?.Alysei}</h4>
      <div className="ingredients__List">
        {/* @@ List of the items */}
        <ul>
          {getActivitySpinner && discover_alysei?.length === 0 ? <FormSpinner /> : discover_alysei?.map((item, index) => {
            return (
              <li className={`recipe__detail ${item?.new_update ? "active_circle" : ''}`} key={index}>
                {item.name === 'fda' ?
                  <>
                    <div className='ingredient_img' onClick={() => setFDAcirtificate(!FDAcirtificate)}>
                      <img
                        src={item && item.attachment ? `${item.attachment.base_url}${item.attachment.attachment_url}`
                          : "https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg"
                        } alt="discover alysei" />
                    </div>
                    <h6 className="ingredient_name">{item.title}</h6>
                  </> :
                  item.name === 'cibecco' ?
                    <>
                      <div className="ingredient_img" onClick={() => setCibeccocirtificate(!Cibeccocirtificate)}>
                        <img
                          src={cibecco} alt="discover alysei" />
                      </div>
                      <h6 className="ingredient_name">Cibecco</h6>
                    </>

                    :
                    item?.category != null ?
                      <>
                        <Link to={`/discovery-alysei/${item?.category?.slug}`} >
                          <div className="ingredient_img">
                            <img
                              src={item && item.attachment ? `${item.attachment.base_url}${item.attachment.attachment_url}`
                                : "https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg"
                              } alt="discover alysei" />
                          </div>
                          <h6 className="ingredient_name">{item?.title}</h6>
                        </Link>
                      </> :
                      <Link to={(item.name !== 'whats-new') ? `/discover/${item.name?.replace(/\s+/g, '-')}` : "#"}>
                        <div className="ingredient_img " onClick={() => item.name === 'whats-new' && setOpenNewsModal(!openNewsModal)}>
                          <img
                            src={item && item.attachment ? `${item.attachment.base_url}${item.attachment.attachment_url}`
                              : "https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg"
                            } alt="discover alysei" />
                        </div>
                        <h6 className="ingredient_name">{item.title}</h6>
                      </Link>

                }
              </li>

            );
          })}

        </ul>
      </div>
      {/* @@ Modal for */}
      {
        openNewsModal && (
          <div>
            <Modal className="storyView"
              ariaHideApp={false}
              isOpen={openNewsModal}
              style={{
                overlay: { position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.75)", },
                content: {
                  position: "absolute", top: "0", left: "0%", right: "0%", bottom: "200px", background: "#fff", overflow: "auto", WebkitOverflowScrolling: "touch", borderRadius: "4px", padding: "0",
                  // outline: 'none',
                },
              }}>
              {/* <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">Alysei News</h4>
              <button className="close_btn--modal" onClick={() => setOpenNewsModal(!openNewsModal)} >close </button>
            </div> */}
              <div className="card card--block modal_box">
                <NewsStatus setOpenNewsModal={setOpenNewsModal} />
              </div>
            </Modal>
          </div>
        )
      }
      <div className="overlay">
        <div className="overlayContent">
          <div className={`${FDAcirtificate ? "fdaArea active" : "fdaArea"}`}>
            <div className="closeBtnBlock">
              <div className="menu closeFda" onClick={() => { setFDAcirtificate(!FDAcirtificate) }}>
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
            <div className={`${FDAcirtificate ? "fdaBlock active" : "fdaBlock"}`} >
              <div className="flexBox">
                <div className="menu">
                  <div className="fdaLogo">
                    <div className="logoImg">
                      <a href="https://www.exportusa.eu/" target="_blank">
                        <img src={fdalogo} alt="Logo" />
                      </a>
                    </div>
                    {/* <a href="https://www.exportusa.eu/" target="_blank">
                    <img src={exportusalogo} alt="Logo" />
                  </a> */}
                  </div>
                  <p>
                    Per come mettersi a norma con le Certificazioni FDA, contattate il nostro Partner:
                  </p>
                  <div className="contactDetail">
                    <h4 className="details">
                      Email: <span><a href="mailto:claudio@exportusa.us">claudio@exportusa.us</a></span>
                    </h4>
                  </div>
                </div>
                <div className="bgImage">
                  <img src={fdaBg} alt="Back image" />
                </div>
              </div>
              <div className="detailAlign">
                <h4 className="details">
                  Tel: <span><a href="tel:+39 0541 709073">+39 0541 709073</a></span>
                </h4>
                <h4 className="details">
                  Cell: <span><a href="tel:+39 339 7944974">+39 339 7944974</a></span>
                </h4>
              </div>
            </div>
          </div>
          {/* Cibeccocirtificate Data  */}
          <div className={`${Cibeccocirtificate ? "fdaArea active" : "fdaArea"}`}>
            <div className="closeBtnBlock">
              <div className="menu closeFda" onClick={() => { setCibeccocirtificate(!Cibeccocirtificate) }}>
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
            <div className={`${Cibeccocirtificate ? "fdaBlock active" : "fdaBlock"}`} >
              <div className="flexBox">
                <div className="menu">
                  <div className="fdaLogo cibbeco">
                    <div className="logoImg">
                      <a href="https://www.cibecco.com/" target="_blank">
                        <img src={cibeccologo} alt="Logo" />
                      </a>
                    </div>
                  </div>
                  <p>
                    Cibecco, Partner Alysei per l'Italia, sostiene lo sviluppo delle economie locali aiutando i piccoli produttori enogastronomici nella vendita dei loro prodotti direttamente al consumatore.
                  </p>
                  <div className="contactDetail">
                    <h4 className="details">
                      Email: <span><a href="mailto:info@cibecco.com">info@cibecco.com</a></span>
                    </h4>
                  </div>
                </div>
                <div className="bgImage newDesign">
                  <img src={fdaBg2} alt="Back image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
}
