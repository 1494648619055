import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import logoSm from '../../assets/landing-page/logo-sm.png';
import howToHelpImg from '../../assets/landing-page/how-to-help.png';
import fnbProducerImg from '../../assets/landing-page/f&b-producer.png';
import importersImg from '../../assets/landing-page/importers.png';
import italianRestaurantImg from '../../assets/landing-page/italian-restaurent.png';
import expertVoiceImg from '../../assets/landing-page/expert-voice.png';
import travelAgencyImg from '../../assets/landing-page/travel-agency.png';
import voyagerImg from '../../assets/landing-page/voyager.png';
import buyerImg from '../../assets/landing-page/buyer.png';
import loginAlysei from '../../assets/landing-page/login-to-alysei.png';
import language from "../../helpers/language";
const SignUp = () => {
  const history = useHistory();
  const lang = language.getLang();
  const handleCardClick = (serviceIndex) => {
    history.push(`/services/${serviceIndex}`);
  };

  return (
    <div className='md:px-8 px-4 pt-24'>
      <p className='font-bold text-3xl mb-3'>{lang.Sign_Up_to_Alysei}</p>
      <p className='text-dim font-medium text-sm mb-6'>
       {lang.Become_an_Alysei_member}
      </p>
      <div className='grid md:grid-cols-4 gap-4'>
        {[
           {
            img: fnbProducerImg,
            title: lang.Italian_FB_Producers,
            description: lang.Italian_FB_Producers_Description_m,
            registerIndex: 3,
          },
         
          {
            img: importersImg,
            title: lang.Importers_Distributors,
            description: lang.Importers_Distributors_Description_m,
            registerIndex: 6,
          },
          {
            img: buyerImg,
            title: lang.Buyers,
            description: lang.Buyers_Description_m,
            registerIndex: 11,
          },
          {
            img: italianRestaurantImg,
            title: lang.Italian_Restaurants,
            description: lang.Italian_Restaurants_Description_m,
            registerIndex: 9,
          },
          {
            img: expertVoiceImg,
            title: lang.Voice_of_Experts,
            description: lang.Voice_of_Experts_Description_m,
            registerIndex: 7,
          },
          {
            img: travelAgencyImg,
            title: lang.Travel_Agencies,
            description: lang.Travel_Agencies_Description_m,
            registerIndex: 8,
          },
          {
            img: voyagerImg,
            title: lang.Voyagers,
            description: lang.Voyagers_Description_m,
            registerIndex: 10,
          },
         
        ].map(({ img, title, description, registerIndex }, idx) => (
          <Link to={`/register/${registerIndex}`}
           
            key={idx} 
            className='rounded-md border border-gray-300 cursor-pointer'
          >
            <img src={img} alt={title} />
            <div className='p-3'>
              <p className='font-bold text-royal text-base mb-1'>{title}</p>
              <p className='text-dim font-medium text-sm'>{description}</p>
            </div>
          </Link>
        ))}
        <Link to="/signIn" className='rounded-md border border-gray-300 relative'>
          <img src={loginAlysei} />
        </Link>
      </div>
    </div>
  );
}

export default SignUp;
