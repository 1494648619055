import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import Modal from "react-modal";
import ReactImageVideoLightbox from 'react-image-video-lightbox'

import {
  fetchNewInquiry,
  fetchInquiryMessages,
  sendNewMessage,
} from '../modules/marketplace';
import default_Image from '../../../assets/images/default_image.jpg';
import language from '../../../helpers/language';
import CustomUseEffect from '../../../components/constants';

export default function ImporterInquiryForm() {
  const [lang] = useState(language.getLang());
  const dispatch = useDispatch();
  const location = useLocation();
  const [userId, setUserId] = useState('');
  const [productInfo, setProductInfo] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [currentInquery, setCurrentInquery] = useState([]);
  const [activeTab, setActiveTab] = useState('open');
  const [selectedFile, setSelectedFile] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedUrl, setSelectedUrl] = useState('');
  const [isMobileView, setIsMobileView] = useState(true);

  const [isOpenImage, setIsOpenImage] = useState(false);
  const [imageData, setImageData] = useState('');

  CustomUseEffect(async () => {
    if (newInquiries) {
      setUserId(newInquiries.current_user_id);
    }
    if (location?.state) {
      await fetchNewEnquery(location?.state)
    } else {
      await fetchNewEnquery('open');
    }

  }, []);

  let { newInquiries, inquiryMessages, currentUserId } = useSelector(
    (state) => state.marketPlaceReducerData
  );

  useEffect(() => {
    if (currentUserId) {
      // console.log(currentUserId);
      setUserId(currentUserId);
    }
  }, [currentUserId]);

  CustomUseEffect(async () => {
    setProductInfo(newInquiries?.data?.data?.[0]?.product ?? []);
    setCurrentInquery(newInquiries?.data?.data?.[0]);
  }, [newInquiries]);

  const fetchNewEnquery = async (status) => {
    setActiveTab(status);
    setProductInfo([]);
    setCurrentInquery([]);
    await dispatch(fetchNewInquiry(status));
  };
  const fetchMessage = async (item) => {
    const senderId = item.receiver.user_id;
    const productId = item.product_id;
    setProductInfo(item.product);
    setCurrentInquery(item);
    await dispatch(fetchInquiryMessages(senderId, productId));
  };

  const writeMessage = async (text) => {
    setMessageInput(text);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    const productId = productInfo && productInfo?.marketplace_product_id;
    const message = messageInput;
    const receiverId =
      currentInquery.producer_id === currentUserId ? currentUserId : currentInquery?.receiver?.user_id;
    let formData = new FormData();
    formData.append('product_id', productId);
    formData.append('receiver_id', receiverId);
    formData.append('message', message);
    if (selectedImage != '') {
      formData.append('image', selectedImage)
    }
    await dispatch(sendNewMessage(formData)).then(async (res) => {
      if (res?.success === 200) {
        setMessageInput('');
        setSelectedUrl('')
        setSelectedFile(false)
        setSelectedImage('')
        console.log('Enquiry closed successfully');
      } else {
        console.log(res, '...error');
      }
    });
  };

  const uploadImage = async (e) => {
    if (e.target.files[0]) {
      setSelectedImage(e.target.files[0])
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => setSelectedUrl(reader.result);
      setSelectedFile(true)
    }
  }

  const onKeyPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey) {
      e.preventDefault();
      let start = e.target.selectionStart,
        end = e.target.selectionEnd;
      writeMessage(messageInput.substring(0, start)
        + '\n' +
        messageInput.substring(end), () => {
          this.input.selectionStart = this.input.selectionEnd = start + 1
        })
    } else if (e.keyCode === 13) { // block enter
      e.preventDefault();
      sendMessage(e)
    }

  };

  return (
    <div className="sendInquiry servics_blocks middle-long__section-block middleBlockBg">
      {/* Inquiry New UI Start*/}
      <div className="product-title">
        <h4>Inquiries</h4>
      </div>
      <div className="mrktInquiry">
        <div className="inquiryLeftBlock">
          <div className="leftBlockHeader">
            <ul>
              <li>
                <Link
                  to="#"
                  className={activeTab === 'open' ? 'activeList' : ''}
                  onClick={() => { fetchNewEnquery('open') }}
                >
                  Opened
                  {newInquiries?.total_open_count > 0 &&
                    <div className="countNo">
                      {newInquiries?.total_open_count}
                    </div>
                  }
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={activeTab === 'close' ? 'activeList' : ''}
                  onClick={() => fetchNewEnquery('close')}
                >
                  Closed
                  {newInquiries?.total_close_count > 0 &&
                    <div className="countNo">
                      {newInquiries?.total_close_count}
                    </div>
                  }
                </Link>
              </li>
            </ul>
          </div>
          {/* left block  */}
          <div className="inquiryUsersList">
            <ul className="chatListHeader">
              {newInquiries?.data?.data?.length > 0 ? (
                newInquiries?.data?.data?.map((item, i) => {
                  return (
                    <li>
                      <div className="feed-user-info">
                        <div className="user__pic">
                          <img
                            src={`${item?.receiver?.profile_img?.base_url}${item?.receiver?.profile_img?.attachment_url}`}
                            alt="img"
                          />
                        </div>
                        <div className="user__detail-data">
                          <p className="user__name font-18 text-black">
                            <Link to="#" onClick={() => fetchMessage(item)}>
                              {/* {item.receiver.name} */}
                              {item?.receiver?.first_name !== null && item?.receiver?.last_name !== null &&
                                `${item?.receiver?.first_name} ${item?.receiver?.last_name}` || item?.receiver?.company_name || item?.receiver?.restaurant_name
                              }
                            </Link>
                            <span className="font-15 text-gray">{item?.message}</span>
                          </p>
                          {/* <div className="flex">
                              <span><i className="fa fa-picture-o" aria-hidden="true"></i> Photo</span>
                            </div> */}
                        </div>
                        {item?.unread_count > 0 &&
                          <div className="countNo">{item?.unread_count}</div>
                        }
                        <div className="about_profile ml-4">
                          <h4 className="font-14 text-black">
                            {moment(item.updated_at).format('MMM DD,yyyy hh:mm a')}
                          </h4>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li>No Inqueries Found</li>
              )}
            </ul>
          </div>
        </div>

        {/* right block */}
        <div className="inquiryRightBlock">
          <div className="inquiryBody">
            <div className="inquiryBodyHeader">
              <div className="topHeader">
                <div className="headerLeft">
                  {/* <div className="closeIcon">
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div> */}
                  {productInfo?.product_store && (<h4 className="storeNm">{productInfo.product_store.name} </h4>)}
                </div>
                {/* <div className="headerRight">
                  <div className="aboutStore">
                    <Link to="#">
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div> */}
              </div>
              {productInfo && productInfo.galleries && (
                <div className="prdctHead">
                  <div className="prdtImg">
                    <img
                      src={`${productInfo.galleries.base_url}${productInfo.galleries.attachment_url}`}
                      alt="img"
                    />
                  </div>
                  <div className="prdtDes">
                    <p>{productInfo && productInfo.title}</p>
                  </div>
                </div>
              )}
            </div>
            {userId && inquiryMessages &&
              <div className="inquiryChatBody">
                {userId && inquiryMessages?.map((item, i) => {
                  let classname = item.sender.user_id === userId ? 'msgReciver' : 'msgSender';
                  return (
                    <div className={classname} key={i}>
                      {item?.image_id &&
                        <img src={`${item?.image_id?.base_url}${item?.image_id?.attachment_url}`} alt='img'
                          onClick={() => { setIsOpenImage(true); setImageData(`${item?.image_id?.base_url}${item?.image_id?.attachment_url}`) }}
                        />}
                      {i === 0 &&
                        <Fragment>
                          <p className="importertitle">
                            {item?.sender?.first_name !== null && item?.sender?.last_name !== null &&
                              `${item?.sender?.first_name} ${item?.sender?.last_name}` || item?.sender?.company_name || item?.sender?.restaurant_name
                            }</p>
                          <p className="importertitle">{item?.sender?.email}</p>
                          <p className="importertitle">{item?.message}</p>
                        </Fragment>
                      }
                      {i > 0 && <p className="importertitle">{item?.message}</p>}
                      {/* <div className="msgDelete"> <i className="fa fa-ellipsis-v" aria-hidden="true"></i> </div> */}
                      <div className="chatTime">
                        <p className="chatDate">
                          {moment(item.created_at).format('MMM DD,yyyy hh:mm a')}
                        </p>
                        {/* <p className="chatStatus">Sent</p> */}
                      </div>
                    </div>
                  );
                })}

              </div>
            }
            {activeTab !== 'close' && (
              <div className="chatFooter">
                <form className="cmnt-form replay_cmnt replayBlock flex justify-between items-center" onSubmit={(e) => sendMessage(e)}>
                  <textarea
                    className="cmnt-write relative relative relative"
                    placeholder={lang?.write_message}
                    value={messageInput}
                    onChange={(e) => writeMessage(e.target.value)}
                    onKeyDown={onKeyPress}
                  />
                  {/* <div className="post-icons-option iconLeft">
                  <a href="/message">
                    <i className="far fa-smile" aria-hidden="true"></i>
                  </a>
                </div> */}
                  <div className="post-icons-option rightIcon flex">
                    <div className="fileAttach">
                      <label className="hide-input">
                        <span>
                          <i className="fa fa-camera" aria-hidden="true"></i>
                        </span>
                        <input type="file" name="file" id="media" onChange={(e) => uploadImage(e)} />
                      </label>
                    </div>
                  </div>
                  <div className="post-icons-option sendBtn">
                    <Link to="#" className="btn" onClick={(e) => sendMessage(e)} >  <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Inquiry UI For Mobile View */}

      <div className="mrktInquiry mobileView importerView">
        {isMobileView ?
          <div className="inquiryLeftBlock">
            <div className="leftBlockHeader">
              <ul>
                <li>
                  <Link
                    to="#"
                    className={activeTab === 'open' ? 'activeList' : ''}
                    onClick={() => { fetchNewEnquery('open') }}
                  >
                    Opened
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={activeTab === 'close' ? 'activeList' : ''}
                    onClick={() => fetchNewEnquery('close')}
                  >
                    Closed
                  </Link>
                </li>
              </ul>
            </div>
            <div className="inquiryUsersList">
              <ul className="chatListHeader">
                {newInquiries?.data?.data?.length > 0 ? (
                  newInquiries?.data?.data?.map((item, i) => {
                    return (
                      <li>
                        <div className="feed-user-info">
                          <div className="user__pic">
                            <img
                              src={`${item?.receiver?.profile_img?.base_url}${item?.receiver?.profile_img?.attachment_url}`}
                              alt="img"
                            />
                          </div>
                          <div className="user__detail-data">
                            <p className="user__name font-18 text-black">
                              <Link to="#" onClick={() => { fetchMessage(item); setIsMobileView(false) }}>
                                {/* {item.receiver.name} */}
                                {item?.receiver?.first_name !== null && item?.receiver?.last_name !== null &&
                                  `${item?.receiver?.first_name} ${item?.receiver?.last_name}` || item?.receiver?.company_name || item?.receiver?.restaurant_name
                                }
                              </Link>
                              <span className="font-15 text-gray">{item?.message}</span>
                            </p>
                            {/* <div className="flex">
                              <span><i className="fa fa-picture-o" aria-hidden="true"></i> Photo</span>
                            </div> */}
                          </div>
                          {item?.unread_count > 0 &&
                            <div className="countNo">{item?.unread_count}</div>
                          }
                          <div className="about_profile ml-4">
                            <h4 className="font-14 text-black">
                              {moment(item.updated_at).format('LT')}
                            </h4>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li>No Inqueries Found</li>
                )}
              </ul>
            </div>
          </div>
          :
          <div className="inquiryRightBlock">
            <div className="inquiryBody">
              <div className="inquiryBodyHeader">
                <div className="topHeader">
                  <div className="headerLeft">
                    {/* <div className="closeIcon">
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div> */}
                    <h6 className="back-btn-small">
                      <Link to="#"
                        onClick={() => setIsMobileView(true)}>
                        <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;
                      </Link>
                    </h6>
                    {productInfo?.product_store && (<h4 className="storeNm">{productInfo.product_store.name} </h4>)}
                  </div>
                  {/* <div className="headerRight">
                  <div className="aboutStore">
                    <Link to="#">
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div> */}
                </div>
                {productInfo && productInfo.galleries && (
                  <div className="prdctHead">
                    <div className="prdtImg">
                      <img
                        src={`${productInfo.galleries.base_url}${productInfo.galleries.attachment_url}`}
                        alt="img"
                      />
                    </div>
                    <div className="prdtDes">
                      <p>{productInfo && productInfo.title}</p>
                    </div>
                  </div>
                )}
              </div>
              {
                inquiryMessages && userId && inquiryMessages &&
                <div className="inquiryChatBody">
                  {inquiryMessages && userId && inquiryMessages.map((item, i) => {
                    let classname =
                      item.sender.user_id === userId ? 'msgReciver' : 'msgSender';
                    return (

                      <div className={classname} key={i}>
                        {item?.image_id &&
                          <img src={`${item?.image_id?.base_url}${item?.image_id?.attachment_url}`} alt='img'
                            onClick={() => { setIsOpenImage(true); setImageData(`${item?.image_id?.base_url}${item?.image_id?.attachment_url}`) }}
                          />}
                        {i === 0 &&
                          <Fragment>
                            <p className="importertitle">
                              {item?.sender?.first_name !== null && item?.sender?.last_name !== null &&
                                `${item?.sender?.first_name} ${item?.sender?.last_name}` || item?.sender?.company_name || item?.sender?.restaurant_name
                              }</p>
                            <p className="importertitle">{item?.sender?.email}</p>
                            <p className="importertitle">{item?.message}</p>
                          </Fragment>
                        }
                        {i > 0 && <p className="importertitle">{item?.message}</p>}
                        {/* <div className="msgDelete"> <i className="fa fa-ellipsis-v" aria-hidden="true"></i> </div> */}
                        <div className="chatTime">
                          <p className="chatDate">
                            {moment(item.created_at).format('LT')}
                          </p>
                          {/* <p className="chatStatus">Sent</p> */}
                        </div>
                      </div>
                    );
                  })}

                </div>
              }
            </div>
            {activeTab !== 'close' && (
              <div className="chatFooter">
                <form className="cmnt-form replay_cmnt replayBlock flex justify-between items-center" onSubmit={(e) => sendMessage(e)}>
                  <textarea
                    className="cmnt-write relative relative relative"
                    placeholder={lang?.write_message}
                    value={messageInput}
                    onChange={(e) => writeMessage(e.target.value)}
                    onKeyDown={onKeyPress}
                  />
                  {/* <div className="post-icons-option iconLeft">
                  <a href="/message">
                    <i className="far fa-smile" aria-hidden="true"></i>
                  </a>
                </div> */}
                  <div className="post-icons-option rightIcon flex">
                    <div className="fileAttach">
                      <label className="hide-input">
                        <span>
                          <i className="fa fa-camera" aria-hidden="true"></i>
                        </span>
                        <input type="file" name="file" id="media" onChange={(e) => uploadImage(e)} />
                      </label>
                    </div>
                  </div>
                  <div className="post-icons-option sendBtn">
                    <Link to="#" className="btn" onClick={(e) => sendMessage(e)} >  <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>
                  </div>
                </form>
              </div>
            )}
          </div>
        }
      </div>


      {/* upload image modal */}
      {selectedFile &&
        <Modal
          isOpen={selectedFile}
          ariaHideApp={false}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "4px",
              padding: "0",
              // outline: 'none',
            },
          }}
        >
          <div className="popup_header flex justify-between items-center">
            <h4 className="page__title">{lang?.Image_with_text}</h4>
            <button className="close_btn--modal" onClick={() => setSelectedFile(false)} > {lang?.close} </button>
          </div>
          <div className="card card--block modal_box msgViewPopup">
            <form onSubmit={(e) => sendMessage(e)}>
              {selectedUrl != '' && <img src={selectedUrl} alt="img" />}
              <div >
                <input
                  type="text"
                  placeholder={lang?.Enter_your_text}
                  value={messageInput}
                  onChange={(e) => writeMessage(e.target.value)}
                />
              </div>
            </form>
            <div className="view__hub__btn">
              <button type="button" className="btn btnTeal" onClick={(e) => sendMessage(e)} >{lang?.upload}</button>
            </div>
          </div>
          <div>
          </div>
        </Modal>
      }

      {/* image gallery */}
      {isOpenImage &&
        <div className='image--light--pop'>
          <ReactImageVideoLightbox
            data={[{ url: imageData, type: "photo", altTag: "image", }]}
            startIndex={0}
            // showResourceCount={true}
            onCloseCallback={() => { setIsOpenImage(false); setImageData('') }}
          // onNavigationCallback={(currentIndex) =>
          //     console.log(`Current index: ${currentIndex}`)
          // }
          />
        </div>
      }

    </div>
  );
}
