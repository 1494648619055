import React from "react";
import { Link } from "react-router-dom";
import Banner from "./component/Banner";
import FloatingBtns from "./component/FloatingBtns";
import Brands from "./component/Brands";
import About from "./component/About";
import OurMission from "./component/OurMission";
import Members from "./component/Members";
import MarketPlace from "./component/MarketPlace";
import Buyers from "./component/Buyers";
import Videos from "./component/Videos";
import Testimonial from "./component/Testimonial";
import Blogs from "./component/Blogs";
import Download from "./component/Download";
import Faq from "./component/Faq";
import NewsLetter from "./component/NewsLetter";
import AnimateFromBottom from "./component/AnimateFromBottom";
const Landing = () => {
  return (
    <>
    
      <Banner />
  <Brands />
  <About />
      <OurMission />
      <Members />
      <MarketPlace />
      <Buyers />
      <Videos />
      <Testimonial />
      {/* <Blogs /> */}
      <Download />
      <Faq />
      <NewsLetter />
     
       <FloatingBtns/>
    </>
   
  );
};

export default Landing;
